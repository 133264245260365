.sec-home {
	.content {
		background: $black;
		margin-top: 0;
	}
	.mnu-home {
		a {
			font-weight: 800;
		}
	}
}

.sec-gral-servicios {
	img {
		height: 440px;
		object-fit: cover;
	}

	h5 {
		@include font-custom-gral($gray1, 21px, 700, 0, 22px);
		margin: 0 0 3.4rem;
		text-align: center;
		text-transform: uppercase;
		z-index: 5;
		pointer-events: none;
	}

	p {
		@include font-custom-gral($black, 14px, 300, 0, 24px);
		margin: 0;

		b {
			margin-bottom: 1rem !important;
			color: $red;
			display: block;
			font-weight: 800;
		}

		&:last-of-type {
			margin: 0;
			min-height: 96px;
			z-index: 5;
		}
	}

	.item {
		strong {
			font-weight: bold;
		}
	}

	.sec-title {
		margin-bottom: 2.8rem;
	}

	.item a {
		font-size: 0;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 5;
	}
}

.no-border {
	.container-fluid {
		border-bottom: 0 !important;
		margin: 3rem auto;
	}
}

.sec-home-servicios {
	overflow: hidden;
	padding-top: 4rem;
	margin-bottom: 8rem;

	.row {
		position: relative;
	}

	.item {
		@media all and (min-width: map-get($grid-breakpoints, "sm") + 1) {
			//max-width: 341px;
		}
	}
}

.sec-home-work {
	@include container-p0();

	p {
		margin: 0;
	}

	.sec-title {
		color: $black;
		padding: 4.4rem 0;
	}

	.item {
		position: relative;

		/*&::before {
			background: $black;
			content: "stamp";
			font-size: 0;
			height: 100%;
			left: 0;
			width: 100%;
			position: absolute;
			z-index: 5;
			@include transition-bs-service(0s);
		}*/

		&.active {
			&::before {
				background: $bg-item-service2;
				@include transition-bs-service(0s);
			}
		}

		&:nth-child(2n) {
			&::before {
				@include transition-bs-service(0.4s);
			}
		}

		&:nth-child(3n) {
			&::before {
				@include transition-bs-service(0.8s);
			}
		}

		&:nth-child(4n) {
			&::before {
				@include transition-bs-service(1.2s);
			}
		}

		&:nth-child(5n) {
			&::before {
				@include transition-bs-service(1.6s);
			}
		}

		&:nth-child(6n) {
			&::before {
				@include transition-bs-service(2s);
			}
		}

		&:nth-child(7n) {
			&::before {
				@include transition-bs-service(2.4s);
			}
		}

		&:nth-child(8n) {
			&::before {
				@include transition-bs-service(2.8s);
			}
		}

		&:nth-child(9n) {
			&::before {
				@include transition-bs-service(3.2s);
			}
		}

		&:nth-child(10n) {
			&::before {
				@include transition-bs-service(3.6s);
			}
		}

		&:nth-child(11n) {
			&::before {
				@include transition-bs-service(4s);
			}
		}

		&:nth-child(12n) {
			&::before {
				@include transition-bs-service(4.4s);
			}
		}
	}

	img {
		display: block;
		object-fit: cover;
		width: 100%;
		// height: auto;
		height: 384px;
	}
}

.bx-right-arrow-alt {
	width: 50px !important;
	&:before {
		content: "" !important;
		background: url(#{$_image-path}/bkg/red-arrow.svg) center top repeat;
		width: 44px;
		height: 40px;
		display: block;
	}
}

.sec-carusel {
	.container-fluid {
		position: relative;
	}
	.carusel-header {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 3rem;
		margin-top: 3rem;
		a {
			color: $red;
			text-decoration: underline;
		}
	}
	.carusel-floating {
		position: absolute;
		left: -100px;
		transform: rotate(-90deg);
		bottom: 20rem;
		h2 {
			color: $red;
			font-size: 16px;
			font-weight: 600;
			width: 200px;
		}
	}
}

.sec-home-work {
	margin-bottom: 4rem;
	.container-fluid {
		position: relative;
	}
	.carusel-header {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 3rem;
		margin-top: 3rem;
		a {
			color: $red;
			text-decoration: underline;
		}
	}
	.carusel-floating {
		position: absolute;
		left: -100px;
		transform: rotate(-90deg);
		bottom: 20rem;
		h2 {
			color: $red;
			font-size: 16px;
			font-weight: 600;
			width: 200px;
		}
	}
}

#home-work {
	.product .cart-top {
		height: 53px;
		position: absolute;
		right: 1rem;
		top: 0;
		width: 53px;
	}
}

.opc:hover {
	opacity: 0.7;
}

.wrapper-items.wrapper-items-inhome {
	grid-template-columns: 2fr 1fr 1fr;
	padding-top: 0;
}
.sec-carusel {
	.wrapper-items .add-tocart-btn {
		right: 10px;
	}
	.title-product-prev {
		text-align: left;
	}
}

#home-carrusel,
#home-carrusel-1,
#home-carrusel-2,
#home-carrusel-3 {
	.swiper-slide {
		.home-slider-slide {
			margin-top: 0;
			position: relative;
		}
		.container {
			padding: 0;
		}
		.col-lg-4 {
			max-width: 100%;
			width: 100%;
			flex: 100%;
			position: initial !important;
			p {
				margin: 0;
			}
			&.content-banner {
				margin-right: 0;
				margin-bottom: 0;
				bottom: 0;
				padding: initial !important;
				.ctnt-info {
					display: grid;
					grid-template-columns: 2fr 1fr;
					grid-template-rows: repeat(2fr, 1fr);
					text-align: left !important;
					p {
						margin: 0;
					}
					.p-title {
						color: $red;
						font-size: 14px;
						font-weight: 400;
						line-height: 32px;
						margin: 0.5rem 0;
						text-transform: uppercase;
						a {
							color: $red;
						}
					}
					.p-price {
						color: #252233;
						font-size: 16px;
						font-weight: 700;
						line-height: 32px;
						margin: 0.5rem 0;
						text-align: right;
					}
					.p-desc {
						color: #74788a;
						font-size: 16px;
						font-weight: 400;
					}
					.p-oldprice {
						color: #74788a;
						font-size: 14px;
						text-align: right;
						text-decoration: line-through;
					}
				}
				.cart-top {
					background-color: $red;
					height: 53px;
					position: absolute;
					right: 0;
					top: 0;
					width: 53px;
				}
			}
			.button.btn-alt.firstbtn {
				display: none;
			}
		}
		img {
			display: block;
			object-fit: cover;
			width: 100%;
			// height: auto;
			height: 384px;
		}
	}
}

#blog-carrusel {
	.swiper-slide {
		.home-slider-slide {
			margin-top: 0;
			position: relative;
		}
		.container {
			padding: 0;
			align-items: flex-start;
		}
		.col-lg-4 {
			max-width: 100%;
			width: 100%;
			flex: 100%;
			position: initial !important;
			p {
				margin: 0;
			}
			&.content-banner {
				margin-right: 0;
				margin-bottom: 0;
				bottom: 0;
				padding: initial !important;
				.ctnt-info {
					text-align: left !important;
					p {
						margin: 0;
					}
					.p-title {
						color: $red;
						font-size: 14px;
						font-weight: 400;
						line-height: 32px;
						margin: 0.5rem 0;
						text-transform: uppercase;
						a {
							color: $red;
						}
					}
					.p-desc {
						color: #74788a;
						font-size: 16px;
						font-weight: 400;
					}
				}
			}
		}
		img {
			display: block;
			object-fit: cover;
			width: 100%;
			height: auto;
		}
	}
	.button-slide .button {
		color: $red;
		margin-top: 3rem;
	}
}

.page-section.sec-contacto.home-contacto {
	order: 1;
	padding: 0 !important;
	background: url(#{$_image-path}/bkg/subs-bkg.png) no-repeat center;
	background-size: 100%;
	height: 433px;
	display: flex;
	align-items: center;
	justify-content: center;
	//background: rgb(234, 235, 240);
	//background: linear-gradient(180deg, rgba(234, 235, 240, 1) 50%, rgba(60, 67, 82, 1) 50%);

	.sectitle-h1 {
		font-size: 0;
		&::before {
			content: "SUSCRIBETE A NUESTRO NEWSLETTER";
			font-weight: 100;
			text-transform: uppercase;
			color: #252233;
			font-size: 30px;
		}
	}

	.content-form {
		width: 100%;
	}
	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		border-bottom: none !important;
		height: 2rem;
		margin: 0 2rem;
		width: auto;
		width: 80%;
		color: $red;
		padding: 0.5rem;
		&::placeholder {
			color: $red !important;
			opacity: 100 !important;
			font-size: 16px;
		}

		&:-ms-input-placeholder {
			color: $red !important;
			opacity: 100 !important;
			font-size: 16px;
		}

		&::-ms-input-placeholder {
			color: $red !important;
			opacity: 100 !important;
			font-size: 16px;
		}
	}
	input::placeholder {
		color: $red !important;
		opacity: 100 !important;
		font-size: 16px;
	}
	.form-field {
		margin: 0;
		padding: 0;
		.form-content {
			border-right: none !important;
			border-color: $black !important;
			padding: 1rem 0;
		}
	}
	.buttons {
		padding: 1rem 4rem 1rem 0;
		border: 1px solid $black;
		border-left: none;
		width: 16%;
		.button,
		button {
			margin-bottom: 0;
			padding: 0 5rem;
		}
	}
	.container-fluid {
		background: transparent;
	}
	.sectitle-h1 {
		margin-bottom: 2rem;
		text-transform: uppercase;
		font-weight: 100;
	}
	#contactstamp,
	#contactstamp-c {
		padding: 2rem 3rem;
		background: #fff;
	}

	#contactstamp-c {
		display: flex;
		width: 100%;
		margin: auto;
		.form-field {
			width: 41.66667%;
			max-width: 41.66667%;
			&.col-md-12 {
				width: 100%;
				max-width: 100%;
			}
			&.field-subject,
			&.field-phone {
				display: none;
			}
			.form-content {
				border: 1px solid $black;
				padding: 1.1rem 0;
			}
		}
		.buttons {
			margin-bottom: 0;
		}
	}
}

.home-container {
	.content {
		display: flex;
		flex-direction: column;
	}
}

.sec-home-intro1 {
	.sec-description {
		p:first-of-type {
			@include font-custom-gral($gray4, 16px, 100, 0, 16px);
			margin-bottom: 10px;

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				@include font-custom-gral($gray4, 14px, 100, 0, 24px);
			}
		}
	}
}

.sec-home-intro2 {
	margin-top: 4.7rem;

	.button-white {
		margin-top: 3rem;
		min-width: 16.4rem;

		&:hover {
			border: solid 2px $white;
		}
	}
}

.sec-page-banner {
	h1,
	h2 {
		opacity: 0;

		&.active {
			opacity: 1;
		}
	}
}

.sec-home-banner {
	.button {
		padding-top: 8px;
	}
}

// *****
// Slider
// ***

.sec-slider {
	// margin-top: 7rem;
	overflow: hidden;
	position: relative;

	.bgw {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		//background-color: rgba(255, 255, 255, 0.4);
		z-index: 5;
	}
	&.sec-carusel {
		.container-fluid {
			max-width: 1248px;
			padding-right: 15px;
			padding-left: 15px;
		}
	}
	.container-fluid {
		max-width: 100%;
		padding: 0;
		width: 100%;
	}

	.container {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		right: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;

		.home-slider-slide {
			margin-top: auto;
			padding-bottom: 1.7rem;
			width: 100%;

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				width: 90%;
			}
		}

		.button-slide {
			margin-left: 0;
			min-height: 50px;
			position: absolute;
			right: 0;

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				min-height: 150px;
			}
			@media (max-width: 580px) {
				min-height: 150px;
				width: 100%;
			}
		}
	}
}

#home-slider .swiper-container {
	height: 70vh;
	max-width: 100%;
}

#product-slider-h.swiper-container,
#product-slider-h-1.swiper-container,
#product-slider-h-2.swiper-container,
#product-slider-h-3.swiper-container {
	height: 500px;
	max-width: 100%;
}

.swiper-slide {
	background-repeat: no-repeat;
	background-size: cover;

	&:first-of-type {
		.erdbtn {
			display: none;
		}
		.orthbtn {
			display: none;
		}
	}

	&:nth-child(1n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 80% center;
		}

		.spoty {
			display: none;
		}
		.lastbtn {
			display: none;
		}
	}

	&:nth-child(2n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 80% center;
		}

		.erdbtn {
			display: none;
		}
		.lastbtn {
			display: none;
		}
		.orthbtn {
			display: none;
		}

		.spoty {
			display: inline-flex;
			height: 73px;
			width: 170px;
		}
		.nospoty {
			display: none;
		}

		.content-banner {
			display: block !important;

			.button:last-of-type {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&:nth-child(3n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 80% center;
		}

		.firstbtn {
			display: none;
		}
		.erdbtn {
			display: none;
		}
		.orthbtn {
			display: none;
		}

		.spoty {
			display: none;
		}
	}

	&:nth-child(4n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 85% center;
		}

		.firstbtn {
			display: none;
		}
		.erdbtn {
			display: none;
		}
		.lastbtn {
			display: none;
		}
		.orthbtn {
			display: inline-flex;
		}

		.spoty {
			display: none;
		}
	}

	&:nth-child(5n) {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: 80% center;
		}
		.firstbtn {
			display: none;
		}
		.erdbtn {
			display: none;
		}
		.orthbtn {
			display: none;
		}

		.spoty {
			display: none;
		}
	}

	&.center-slde-home {
		.content-banner {
			display: block !important;
			h2 {
				margin-left: 0;
				margin-right: 0;
				text-align: left;
			}
		}
	}
}

.swiper-pagination {
	pointer-events: fill;
}

.button {
	padding: 0;
	pointer-events: fill;
}

.content-banner h2 {
	text-align: left;
}

.col-lg-4.content-banner {
	//  content: "#";
	background: transparent !important;
	// background: rgba(255, 255, 255, 0.15) !important;
	// backdrop-filter: blur(9.4px) !important;
	bottom: 5em;
	// border-radius: 16px !important;
	// box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
	display: block !important;
	margin-right: 7em;
	padding: 1.5em !important;
	position: absolute;
	right: 0;
	width: 39em;

	h2,
	h3,
	p {
		color: $white;
		text-align: left;
	}

	h2 {
		font-size: 30px;
		font-weight: 100;
		line-height: 37px;
		text-transform: uppercase;
	}

	h3,
	p {
		font-size: 16px;
		line-height: 19px;
		font-weight: 100;
	}

	.button-slide {
		.button {
			background: transparent;
			border: 1px solid $red;
			text-decoration: none;
			text-transform: capitalize;
			font-size: 16px;
			font-family: $font-family-sans-serif;
			font-weight: 100;
		}
	}
}

#topSectionBlog {
	.button {
		background: transparent;
		border: 1px solid $red;
		text-decoration: none;
		text-transform: capitalize;
		font-size: 16px;
		font-family: $font-family-sans-serif;
		font-weight: 100;
	}
}

#home-slider {
	.swiper-wrapper {
		.swiper-slide-active {
			&:before {
				content: "";
				position: absolute;
				left: 0;
				width: 120px;
				background-color: $red;
				height: 100%;
				z-index: 6;
			}
		}
	}
	.swiper-button-next {
		left: 0;
		right: auto;
		bottom: 0;
		top: auto;
		&:before {
			background: url(#{$_image-path}/bkg/next.svg) no-repeat center;
		}
	}
	.swiper-button-prev {
		bottom: 4rem;
		top: auto;
		&:before {
			background: url(#{$_image-path}/bkg/prev.svg) no-repeat center;
		}
	}
	.swiper-button-next,
	.swiper-button-prev {
		display: block;
		height: 43px;
		width: 43px;
		cursor: pointer;
		left: 3rem;
		&:before {
			content: "";
			width: 44px;
			height: 44px;
			display: block;
		}
	}
}

.wrapper-ctbanner {
	position: absolute;
	z-index: 8;
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
}

.iconos-pasos {
	display: flex;
	margin-left: 0;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: auto;
	justify-content: space-between;
	margin-top: 20px;

	.items {
		display: flex;
		flex-direction: column;
		margin-right: 5px;
	}

	img {
		margin: auto 3rem auto 0;
	}
}

.items.itm3 img {
	position: relative;
	top: 5px;
}

.txt-vert {
	transform: rotate(180deg);
	writing-mode: vertical-rl;
}

.ctnt-link {
	display: flex;
	justify-content: flex-end;
	padding: 3em 0 2em;

	.link-ref {
		color: $blue-disable-calendar;
		margin-right: 0.5em;
	}
}

.product {
	.cart-top {
		height: 53px;
		right: 15px;
		top: 0;
		width: 53px;
	}

	.ctnt-info {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-template-rows: repeat(2, 1fr);

		.p-title {
			color: $red;
			font-size: 14px;
			font-weight: 400;
			line-height: 32px;
			margin: 0.5rem 0;
			text-transform: uppercase;
		}

		.p-price {
			color: $gray1;
			font-size: 16px;
			font-weight: bold;
			line-height: 32px;
			margin: 0.5rem 0;
			text-align: right;
		}

		.p-desc {
			color: $blue-disable-calendar;
			font-size: 16px;
			font-weight: 400;
		}

		.p-oldprice {
			color: $blue-disable-calendar;
			font-size: 14px;
			text-align: right;
			text-decoration: line-through;
		}
	}
}

.call-to-action {
	.action {
		background-color: $white3;
		padding: 2.5em 0 1.5em;
	}

	.ctnt-action {
		position: relative;
		padding: 0 2rem;
	}

	.a-title {
		font-size: 14px;
		color: $black;
		font-weight: 800;
	}

	.a-txt {
		font-size: 14px;
		color: $black;
	}

	.btn-red {
		margin-left: auto;
		display: block;
		padding: 1rem 2rem;
	}
}

/*
 *
 * Se usan # para la manipulación de ciertos contenedores
 *
*/

#categoriesHome {
	padding: 3rem 0;
	order: 2;
	.col-6 {
		text-align: center;
	}
	.cat-titles {
		margin-bottom: 2rem;
		p {
			margin: 0 !important;
			color: $black;
		}
	}
}

.sec-home-intro1 {
	.sec-description {
		h1 {
			text-transform: uppercase;
			font-weight: 100;
		}
		p {
			line-height: 1.3 !important;
		}
	}
}

.sec-home-categories {
	a {
		color: $red;
		text-decoration: underline;
	}
	.item {
		p {
			color: $black;
		}
	}
}

#bannerHome {
	order: 3;
}
.sec-home-intro2 {
	margin-top: 6rem;
	margin-bottom: 6rem;
	.item {
		p {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
.sec-home-intro3 {
	margin-bottom: 4rem;
}

#serviciosHome {
	order: 4;
}

#callToAction {
	order: 5;

	// .container-fluid {
	// 	background: $gray2;
	// 	padding: 2.5em 0 1.5em;
	// }
}
.sec-home.call-to-action {
	background: #f6f7fc;
}
