.pg-form-gral {
	.content {

		.row {

			@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
				justify-content: center;
			}
		}

		.txt {
			margin-right: auto;

			&.in-il {
				input { border: 0; color: $black; outline: 0; padding-left: 8px; }
			}

		 // input {
			//  border: 1px solid $gray-lightest; min-height: 48px;
		 //
			//  &:focus { border-bottom-width: 1px; }
		 // }
		}

		.textarea {
			textarea {
				border: 1px solid $gray-lightest; min-height: 136px; outline: 0; padding-top: 13px;

				&:focus { border-bottom-width: 1px; }
			}
		}
	}

	// .drop {
	// 	background: $white;
	// 	backdrop-filter: blur(10px);
	// 	border-radius: 10px;
	// 	border-left: 1px solid $white;
	// 	border-top: 1px solid $white;
	// 	box-shadow: 10px 10px 60px -8px $shadow-glass;
	// 	position: absolute;
	// 	transition: all 0.2s ease;
		
	// 	&-1 {
	// 		height: 80px;
	// 		width: 80px;
	// 		top: -20px;
	// 		left: -40px;
	// 		z-index: -1;
	// 	}
		
	// 	&-2 {
	// 		height: 80px;
	// 		width: 80px;
	// 		bottom: -30px;
	// 		right: -10px;
	// 	}
		
	// 	&-3 {
	// 		height: 100px;
	// 		width: 100px;
	// 		bottom: 120px;
	// 		right: -50px;
	// 		z-index: -1;
	// 	}
		
	// 	&-4 {
	// 		height: 120px;
	// 		width: 120px;
	// 		top: -60px;
	// 		right: -60px;
	// 	}
		
	// 	&-5 {
	// 		height: 60px;
	// 		width: 60px;
	// 		bottom: 170px;
	// 		left: 90px;
	// 		z-index: -1;
	// 	}
	// }

	.content-form {
		
		&.right-card {
			background: $white;
			box-shadow: 0 8px 32px 0 $shadow-glass;
			height: 435px; // cambiar por una medida más responsiva
			max-width: 449px; // cambiar por una medida más responsiva
			order: 1;
			padding: 2rem;

			.contact-form {
				margin: 0;
			}

			.buttons {
				margin: 0;
				position: relative;

				.btn-spirit {
					left: 0;
					margin: 0;
					position: absolute;
				}
			}
		}

		.form-content { padding: 0; }

		.form-field:not(.form-description) {
			font-weight: 100;
			text-transform: uppercase;
			span{
				color: $red;
			}
		}

		label { @include font-custom-gral($_form-color, 12px, 300, 2px, 24px); left: 2.4rem; text-transform: uppercase; }

		input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
		select.invalid:not(:focus) + label,
		select:invalid:not(:focus) + label,
		textarea:placeholder-shown:not(:focus) + label {
			top: 0;
		}

		input:not([type="radio"]):not([type="checkbox"]) + label,
		select + label,
		textarea + label {
			left: 2.4rem; padding-left: 0; top: 0; font-size: 14px; color: $black;
		}

		input:not([type="radio"]):not([type="checkbox"]):focus + label,
		select:focus + label,
		textarea:focus + label {
			font-size: 12px; left: 24px; top: -6px;
		}

		input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown),
		input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown),
		textarea.invalid:not(:focus):not(:placeholder-shown),
		textarea:invalid:not(:focus):not(:placeholder-shown) {
			border-bottom-color: $gray-lightest;
		}

	}

}

.pg-contacto {
	.bkg-contacto {
		background-size: cover;
		height: 435px;
		.container-fluid {
			max-width: 100% !important;
			padding: 0;
			#gmap_canvas, .gmap_canvas, .mapouter {
				width: 100%;
				height: 435px;
			}
		}
	}

	.textarea { margin-top: 15px; }

	.form-description {
		@include font-custom-gral($gray1, 17px, 300, 0, 24px);

		&.mb5 {
			margin-bottom: 15px;
		}
	}

	.content {
		.txt {
	    @media all and (min-width: map-get($grid-breakpoints, 'sm') + 1) {
			  max-width: 43.6rem;
			}
	  }
	}

	.contact-form {
		@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
			margin: 0 10% 0 20%;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			margin-left: auto; margin-right: auto;
		}
	}

	form[name="contactPage"] {
		background-color: transparent;
		padding: 1em 0.5em;
		width: 100%;

		.form-field {
			margin: 0;
			padding: 0;

			.form-content {

				&:nth-child(2) {
					background-color: $gray-lightest;
				}

				select,
				input {
					

					// border-style: hidden solid hidden solid;
					border-style: solid;
					height: 3em;
					padding: 1em 0 0 0.4em;
					width: 100%;
				}

				label {
					left: 1em;
					position: absolute;
					top: 0 !important;
				}
			}
		}
	}


	.content-al {
		order: 2; padding-top: 6.7rem;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			padding-top: 4.8rem;
		}

	}

	.sectitle-h1 { margin-bottom: 2.2rem; }

	.buttons {
		margin-bottom: 4rem; padding-top: 0;

		@media all and (min-width: map-get($grid-breakpoints, 'sm') + 1) {
			margin-bottom: 9rem;  margin-right: auto;  width: 43.6rem;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			margin-bottom: 0;
		}

	}

	.button  { margin: 0 5.5rem 0 auto; min-width: 16rem; }

	.gralmsgsend {
		display: none; flex-direction: column; margin-bottom: 5rem; text-align: center;

		&.sendsuccess {
			animation-duration: 1s;
		  animation-fill-mode: both;
			-webkit-animation-name: zoomInLeft;
			animation-name: zoomInLeft;
			display: flex;
		}

		h3 {
			@include font-custom-gral($gray1, 30px, 100, 0, 30px); margin: 0 auto 2rem auto;

			&::before { background: $slider-bg-arrow url(#{$_image-path}/bkg/icons-check.png) no-repeat center; background-size: contain; content: "stamp"; display: block; font-size: 0; height: 45px; margin: 1rem auto 2.2rem; text-align: center; width: 45px; }

		}

		h4 {
			@include font-custom-gral($gray1, 22px, 400, 0, 28px); margin: 0 auto 4.5rem; width: 65%;

			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				width: 90%;
			}
		}

		.link-default { @include font-custom-gral($gray1, 13px, 100, 0, 18px); display: block; text-decoration: underline; }

		.button-outline { margin: 0 auto 1.2rem auto; width: 245px; }

	}


}

.cinfo {
	margin-bottom: 4.2rem;

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
		margin-left: auto; margin-right: auto; width: 452px;
	}

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		margin-bottom: 2.5rem; width: 100%;
	}


	h6 {
        @include font-custom-gral($gray1, 22px, bold, 0, 28px); margin: 0 0 16px 0;

        &.mtb-wt {
            margin-top: 30px;
        }

    }

	p { @include font-custom-gral($gray1, 17px, 300, 0, 24px); margin: 0; }

	&.last {
		 h6 {
			 margin-bottom: 0.5rem;
		 }
	}

	&.item2 {
		margin-top: 7rem;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			margin-top: 4rem;
		}
	}

	&.item3 {
		margin-top: 8.3rem;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			margin-top: 4rem;
		}

	}

	&.last { margin-bottom: 11.5rem; }

}

.form-description {
	@include font-custom-gral($gray1, 17px, 300, 0, 24px); margin-bottom: 4rem;

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		margin-bottom: 5rem;
	}
}

.pg-contacto{
	.page-section.sec-contacto{
		input:not([type=radio]):not([type=checkbox]):placeholder-shown:not(:focus)+label{
			top: 18px !important;
		}
		input:not([type=radio]):not([type=checkbox]){
			padding: 2rem 0.4rem 0 !important;
		}
	}
}

#contactstamp-c{
	input:not([type=radio]):not([type=checkbox]), select, textarea {
		border: none;
		border-bottom: 0;
		color: $red;
		font-size: 16px;
		height: auto;
		padding: 2rem 2.4rem 0;
		outline: none;
		&::placeholder {
			color: $red !important;
			opacity: 100 !important;
			font-size: 16px;
		}
	}
}

#infoContact{
	margin-top: -10rem;
	margin-bottom: 6rem;
	a{
		display: flex;
		column-gap: 0.5rem;
		color: $black;
		
	}
	.info-contact-ico-first{
		p{
			img{
				width: 46px;
			}
		}
	}
}

.pg-contacto{
	#contactstamp{
		margin: 0;
		.buttons{
			margin: 0;
		}
	}
}

.mb14{
	margin-bottom: 14rem;
}

.info-contact-ico{
	display: flex;
	column-gap: 1rem;
	justify-content: flex-start;
	p{
		margin: 0;
	}
} 

.services-contact{
	.item{
		p{
			font-size: 14px;
			color: $black;
		}
		b{
			color: $red;
			height: 50px !important;
    display: block;
		}
	}
}

.sec-contacto-c {
	margin-top: -30rem;

	.form-field{
		&.sectitle {
			border: none;
			margin-bottom: 2rem !important;
			padding: 0;
		}
		border: 1px solid $black;
		border-bottom: 0;
		margin-bottom: 0 !important;
		padding: 1rem 0;
		&.last-field{
			border-bottom: 1px solid $black !important;
		}
	}

	.buttons{
		display: block;
		.btn-red{
			margin-left: 0;
			margin-top: 2rem;
		}
	}

	.container-fluid {
		display: flex;
		justify-content: flex-end;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			padding-left: 15px; padding-right: 15px;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			padding-top: 8rem;
		}
	}

	.links-icons {
		padding: 1em 0 5em;
		p {
			align-items: center;
			display: flex;
		}
		i {
			&.bx { margin-right: 1em; }
		}
		a {
			color: $gray6; font-size: 16px; font-weight: 200; line-height: 19px; margin-left: 1em; text-decoration: none;	

			&:hover { color: $gray3; text-decoration: underline; }
		}
	}

	.info-text {
		padding: 5em 0;
	}

	.wrapper-social { align-items: center; display: inline-flex; }

	.isocial { display: inline-flex; font-size: 0; }

	.i-fa {
		background: url(#{$_image-path}/bkg/contact-svg-facebook-1.svg) no-repeat center; height: 27px; margin-right: 3.7rem; width: 28px;
	}

	.i-tw {
		background: url(#{$_image-path}/bkg/contact-sv-twitter-1.svg) no-repeat center; height: 25px; width: 27px;
		margin-right: 3.7rem;
	}

	.i-ins {
		background: url(#{$_image-path}/bkg/insta-black.svg) no-repeat center; height: 25px; width: 27px;
		background-size: 48px;
	}

}


.in-il {
	color: $black;

	.form-content {
		align-items: center; border: 1px solid $gray-lightest; min-height: 48px;
	}

	input:not([type="radio"]):not([type="checkbox"])::placeholder,
	select::placeholder,
	textarea::placeholder {
		opacity: 0;
	}

	label { padding-left: 2.4rem; text-transform: uppercase; font-size: 14px;}

	input { border: 0; }

}
