.pg-cart {

	.h1cart { @include font-custom-gral($gray1, 22px, 700, 0, 28px); margin: 0 0 4rem; }

	.item-add {
		background: $bg-cart-item;
		display: grid;
		grid-template-columns: 200px auto;
		margin-bottom: 1rem;
		padding: 3.2rem 1.6rem;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			align-items: center; display: flex; flex-direction: column; justify-content: center;
		}

		.wrapper-img {
			overflow: hidden; width: 200px;

			img { display: block; height: 200px; object-fit: cover; width: 100%; }
		}

		h4 {
			@include font-custom-gral($gray1, 22px, 700, 0, 28px); margin: 15px 0 1rem 0.5rem;
		}

		p {
			@include font-custom-gral($gray1, 17px, 300, 2px, 18px); margin: 0 0 10px 0;
		}
	}

	.i-er {
		background: url(#{$_image-path}/bkg/edit-remove.png) no-repeat; background-size: 32px auto; font-size: 0; width: 32px; height: 32px;
	}

	.edit-item {
		background-position: 0px 0px;
	}

	.remove-item {
		background-position: 0 -34px;
		margin-left: auto;
	}

	.butttom-added {
		display: flex; margin-top: 2.7rem;

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			flex-wrap: wrap;
		}
	}

	.wrapper-right {

		@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
			padding-left: 1.2rem;
		}
	}

	.quantity {
		@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
			margin-left: 2.6rem;
		}

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			margin: 0 1rem;
		}

		input {
			@include font-custom-gral($gray1, 16px, 300, 0, 48px); -moz-appearance: textfield; background: $white; border-bottom: 1px solid $gray-lightest; border-top: 1px solid $gray-lightest; height: 48px; padding: 0 5px; text-align: center; width: 80px;

			@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
				width: 70px;
			}
			@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
				width: 50px;
			}

			&:focus,
			&:active {
				border-bottom-width: 1px;
			}
		}

		.quantity-button {
			@include font-custom-gral($gray1, 16px, 300, 0, 24px); background: $white; border: 1px solid $gray-lightest; cursor: pointer; font-size: 14px; height: 48px; line-height: 48px; text-align: center; width: 48px;

			&:hover,
			&:active,
			&:focus {
				@include font-custom-gral($white, 16px, 300, 0, 24px); background: $black;
			}

		}
	}

	.wrapper-total-mb {
		background: $gray3; bottom: 0; display: none; position: fixed; text-align: center; width: 100%; z-index: 10;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			display: block;
		}

		.button { margin-bottom: 0; min-height: 50px; width: 100%; }
	}

	.hidden-txt { display: none; }

	.lb-username .inline::before {
    content: "Para los siguientes campos asegurate de usar la misma información que manejas en tu cuenta de Paypal.";
    display: block;
    height: auto;
    width: 100%;
    font-size: 14px;
    text-transform: none;
    font-weight: bold;
    line-height: 16px;
		margin-bottom: 15px;
    letter-spacing: 0;
	}

    .promos-text {
        @include font-custom-gral($gray1, 13px, bold, 0, 16px);
		text-transform: uppercase;

    }

}

.wrapper-buy {
	background: $white-3; width: 100%; padding: 3.9rem 1.5rem;

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		padding-bottom: 2rem;
	}


	.valtotal {
		@include font-custom-gral($gray1, 30px, 300, 2px, 30px); order: 2;

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			@include font-custom-gral($gray1, 17px, 100, 2px, 14px);
		}

	}

	.form-content { align-items: center; justify-content: space-between; padding: 0; }

	.buttons {
		display: none; padding: 0; width: 100%;

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			display: none;
		}

	}

	.button { margin: 0; width: 100%; }

	.lb-jsonproducts { display: none; }

	.lb-costshipping {
        display: none !important;
		 margin-bottom:  3rem;

		 input { display: none !important; }

		 div { display: flex; }

		 .inline { order: 1; }

		 span {
			 @include font-custom-gral($gray4, 30px, 300, 2px, 30px); order: 2;

				@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
					@include font-custom-gral($gray4, 17px, 100, 2px, 14px);
				}
		 }
	 }


	.form-content {
		input {
			border: 0; margin-left: auto; order:2; width: 50%;

			+ label { @include font-custom-gral($gray1, 17px, 300, 2px, 18px); order: 1; position: static; }
		}
	}

	.lb-estimate {
		margin: 0 0 3rem auto; text-align: right; width: 193px;

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			margin-bottom: 0;
		}

		.inline { @include font-custom-gral($gray-estimacion, 17px, 100, 2px, 18px); order: 1; position: static; }


		input { display: none; }
	}

	.lb-buy-gral {

		.form-content { display: flex; }

		input {
			@include font-custom-gral($gray1, 30px, 300, 2px, 30px); border: 0;
		}
	}

	.lb-subtotal {
		margin: 5.4rem 0 4.6rem;

		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
			margin: 4.4rem 0 3rem;
		}

		input { display: none; }

		.valtotal { color: $gray4; }

	}

	.lb-shippingstandar {
		margin-bottom: 0.5rem;

		.shippingval {
			@include font-custom-gral($color-total-rate, 30px, 300, 0, 30px); order: 2;

			@media all and (min-width: map-get($grid-breakpoints, 'lg') + 1) {
				min-width: 230px;
			}
		}

		input { display: none; }

		.valtotal { text-align: right; }
	}

	.lb-totalbuy {
        margin-bottom: 15px;

		.form-content {
			display: flex;

			input {
				display: none;

				+ label {
					@include font-custom-gral($gray1, 22px, 700, 0, 28px);
				}

			}
		}
	}

	.gralfield {
		margin-bottom: 2.4rem;

		label { @include font-custom-gral($_form-color, 12px, 300, 2px, 24px); left: 2.4rem; text-transform: uppercase; }

		input:not([type="text"]),
		input:not([type="radio"]):not([type="checkbox"]) {
			@include font-custom-gral($_form-color, 16px, 300, 2px, 24px); background: $white; border: 1px solid $gray-lightest; min-height: 48px; padding: 1.5rem; width: 100%;
			border: 1px solid $gray-lightest !important;
		}

	}
}



.wrapper-cartbuy {
	margin-top: 7rem; padding-top: 4rem;

	 .lb-addcuppon {
		 margin-bottom: 2.4rem;

		 input {
				@include font-custom-gral($gray1, 12px, 100, 2px, 48px); opacity: 1; text-transform: uppercase; background: $white; border: solid 1px $gray-lightest; min-height: 48px; width: 100%;

				&:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus)::placeholder {
					@include font-custom-gral($gray1, 12px, 100, 2px, 48px); opacity: 1; text-transform: uppercase;
				}

				&:focus {
					border: 1px solid $gray-lightest;
				}

			}

		 label {
			 @include font-custom-gral($white, 13px, bold, 0, 16px); background: $gray-btnaply; cursor: pointer; line-height: 48px; min-width: 112px; position: absolute; right: 0; text-align: center; text-transform: uppercase; top: 0;
		 }

	 }
}

.buy-right {

	.h1cart {
		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
			display: none;
		}
	}

}


.pgcart0 { min-height: 70vh; }

.pg-paystamp {
	display: flex; flex-direction: column; min-height: 100vh;

	.sec-steps { text-align: center; }

	.footer { margin-top: auto; }

	main {
		img { display: block; margin: auto; }
	}
}

.sec-steps-in {

	.item {
		position: relative;

		&::before {
			background: $black; content: "stamp"; font-size: 0; height: 100%; left: 0; width: 100%; position: absolute; z-index: 5;
			@include transition-bs-service (0s);
		}

		&.active {
			&::before {
				background: $bg-item-service;
				@include transition-bs-service (0.5s);
			}
		}

		&:nth-child(2n) {
			// animation-delay: 0.2s;
			&::before {
				@include transition-bs-service (1s);
			}
		}

		&:nth-child(3n) {
			// animation-delay: 0.4s;
			&::before {
				@include transition-bs-service (1.5s);
			}
		}

		&:nth-child(4n) {
			// animation-delay: 0.6s;
			&::before {
				@include transition-bs-service (2s);
			}
		}
	}

	img { display: block; }

}

#submit-buy {
	display: none;
}

.lb-orderid {
	display: none;
}

.pg-succes-buy .content .col-lg-8 {
    min-height: 60vh;
		flex-direction: column;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.continue-buy {
	padding-left: 10px;
	padding-right: 10px;
}

.wrapper-paypal-button {
    width: 100%;
    position: relative;

	&:not(.continuebuypal) {
		&::before {
			content: "tees";
			font-size: 0;
			display: block;
			width: 100%;
			height: 100px;
			position: absolute;
			z-index: 9999;
		}
	}

}

#paypal-button {
	width: 100% !important;
}

#formbuymercado .buttons,
#formbuymercado .form-field {
	display: none;
}


#btnpagarmercado {
    margin-top: 20px;

    img  { margin-left: 5px; }

}

 .wrapper-mercadopago{
    width: 100%;
    position: relative;

     &:not(.continuemercadopago) {
 		&::before {
 			content: "tees";
 			font-size: 0;
 			display: block;
 			width: 100%;
 			height: 100px;
 			position: absolute;
 			z-index: 9999;
 		}
 	}
}

.f-typeplantilla,
.form-field.text.imgpcustomtshirt {
    display: none;
}
