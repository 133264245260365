[class*="button"] {
	align-items: center;
	display: inline-flex;
	justify-content: center;
	min-height: 40px;
	text-transform: uppercase;

	&:hover {
		text-decoration: none;
	}
}

body {
	font-family: $font-family-base;
	background: url(#{$_image-path}/bkg/g-back.png) center top repeat;
	background-size: 100%;

	* {
		font-family: $font-family-base;
	}
}

.button.thered {
	@include font-custom-gral($white, 16px, 100, 0, 14px);
	@include button-black-white($red, $white, $red);
	text-transform: initial;
	padding: 0 5rem;
}

.no-btn-crusel {
	.button-slide {
		display: none;
	}
}

.button {
	@include font-custom-gral($white, 13px, 700, 0, 14px);
	@include button-black-white($black, $white, $black);
	//@include bgbutton1($black, $white);

	min-height: 40px;
	padding-top: 12px;
	text-transform: uppercase;

	&.btn-spirit {
		background: transparent;
		border: 0;
		color: $white; // Change color vars
		font-size: 16px;
		height: 30px;
		position: relative;
		z-index: 1;
		font-weight: 100;
		background: $red;
		text-transform: initial;

		&::after,
		&::before {
			bottom: 0;
			content: "";
			position: absolute;
			right: 0;
			transition: all 0.4s;
			z-index: -99999;
		}

		&::before {
			background: $red;
			// border-radius: 10px;
			height: 100%;
			transform: translate(0%, 0%);
			width: 100%;
		}

		&::after {
			backdrop-filter: blur(5px);
			background: $spir-bd; // Change color vars
			// border-radius: 50px;
			height: 35px;
			transform: translate(10px, 10px);
			width: 35px;
		}

		&:hover::before {
			height: 110%;
			transform: translate(5%, 20%);
			width: 110%;
		}

		&:hover::after {
			// border-radius: 10px;
			height: 100%;
			transform: translate(0, 0);
			width: 100%;
		}

		&:active::after {
			transform: translate(0, 5%);
			transition: 0s;
		}

		&.btn-alt {
			border: 1px solid $white;
			font-weight: 300;

			&::before {
				background: transparent;
			}
			&::after {
				background: $white;
			}
			&:hover {
				color: $black;
			}
		}

		&.btn-shadow {
			border: 1px solid $gray6;
			color: $gray6;
			font-weight: 300;
			text-transform: capitalize;

			&::before {
				background: transparent;
			}
			&::after {
				background: $gray5;
			}
			&:hover {
				color: $black;
			}
		}
	}
}

.button-white {
	@include font-custom-gral($gray1, 13px, 700, 0, 14px);
	@include button-black-white($white, $black, $black);
	//@include bgbutton1($white, $black);

	background: $white;
	position: relative;

	&:hover {
		color: $white;
	}
	&::before {
		background: $black;
		color: $white;
	}
}

.button-outline {
	@include font-custom-gral($gray1, 13px, 700, 0, 14px);
	border: 5px solid $black;
	color: $black;
	line-height: 30px;
	min-width: 164px;
	padding: 0;
	position: relative;
	text-transform: uppercase;
	transform: translateZ(0);
	// -webkit-transform: translateZ(0);
	transition: color 1000ms;
	// -webkit-transition: color 1000ms;

	&::before {
		/*-webkit-transform-origin: 0 50%; -webkit-transform: scaleX(0); -webkit-transition-property: transform; -webkit-transition: 300ms ease-out;*/
		background: $black;
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		transform: scaleX(0);
		transform-origin: 0 50%;
		transition: 300ms ease-out;
		transition-property: transform;
		z-index: -1;
	}

	&:hover {
		color: $white;

		&::before {
			/* -webkit-transform: scaleX(1); */
			transform: scaleX(1);
		}
	}
}

.nav.flex-column.nav-pills {
	.nav-link {
		background-color: transparent;
		color: $gray6;
		font-size: 14px;
		line-height: 18px;

		&.active {
			font-weight: 500;
		}
	}
}

@media (min-width: 320px) and (max-width: 600px) {
	.col-lg-4.content-banner {
		margin-right: 2em !important;
		width: auto !important;
	}
	.content-banner .button:last-of-type {
		margin: 32px 2rem 28px auto !important;
	}
	.sec-home-work {
		.row {
			flex-direction: column;
			.col-6 {
				width: 90% !important;
				max-width: 90%;
				margin: 0 auto;
			}
		}
	}

	.grid-filters {
		flex-direction: column;
		margin-top: 2rem;
	}
	.wrapper-items.catalogon {
		display: flex !important;
		flex-direction: column !important;
		.catalogoimg.item {
			width: 90%;
			margin: 1rem auto;
		}
	}
	.nav-item-parent {
		margin-top: 2rem;
	}
	.store-left .grid-filters .wrapper-cat-product {
		justify-content: center !important;
	}
	.sec-home-intro2,
	.sec-home-intro3,
	.sec-gral-servicios,
	.sec-somos-servicios,
	.sec-somos-work,
	.sec-somos-work-2,
	.sec-contact,
	.product-top-img-vampyr,
	.product-top-img-paladin,
	.footer {
		.row {
			flex-direction: column;
			.col-6,
			.col-2 {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 100%;
				flex: 0 0 100%;
				max-width: 100%;
				p,
				h2,
				h3,
				h1 {
					text-align: center;
				}
			}
		}
	}
	.mb14 {
		margin-bottom: 2rem !important;
	}
	.page-section.sec-contacto.home-contacto #contactstamp-c {
		flex-direction: column !important;
	}
	.page-section.sec-contacto.home-contacto #contactstamp-c .form-field,
	.page-section.sec-contacto.home-contacto .buttons {
		width: 100% !important;
		max-width: 100% !important;
	}
	#header {
		.ico::before {
			color: $white;
		}
	}
	.page-section.sec-contacto.home-contacto .buttons {
		border: 0 !important;
	}
	.page-section.sec-contacto.home-contacto .form-field {
		border-right: 1px solid !important;
	}
	.footer-logo-link {
		margin: auto !important;
	}
	.menu-mobile::before {
		top: 2rem;
	}
	.sec-carusel .carusel-floating,
	.sec-home-work .carusel-floating {
		h2 {
			margin-left: 2rem;
		}
		position: initial !important;
		transform: none !important;
	}
	.sec-home-work,
	.footer {
		.container-fluid {
			width: 90%;
			margin: 0 auto;
		}
	}
	.menu-content {
	}
	.main-menu .menu-content {
		height: auto !important;
		background: $black !important;
		top: 70px !important;
	}
	.main-menu a.active,
	.main-menu a:hover {
		color: $white !important;
	}
	.pg-nosotros .content-banner h2 span {
		color: $white !important;
	}
	.sec-contacto-c {
		margin-top: 0 !important;
	}
	#infoContact {
		margin-top: 6rem !important;
		margin-bottom: 0rem !important;
	}
}

.simplesearch {
	.search-box {
		display: flex;
		margin: 10rem auto 4rem;
		max-width: 400px;
		.search-input{
			border: 1px solid #6d6d6d;
			padding: 2rem 2rem;
		}
		.btn-red {
			padding: 1.4rem 2rem;
		}
	}
	.resultados{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 1rem;
		.search-item{
			h3{
				margin: 0;
			}
			a{
				color: #c51625;
				font-size: 14px;
			}
			hr{
				display: none;
			}
		}
	}
}
