.sec-page-banner {
	min-height: 73rem;
	overflow: hidden;
	position: relative;

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		min-height: 466px;
	}

	.row {
		min-height: 73rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			min-height: 466px;
		}
	}

	.container-fluid {
		max-width: 100%;
	}

	.imgfull {
		left: 0;
		top: 0;
		position: absolute;
		width: 100%;
		// &::before {
		// 	background: $bgimgfull; content: 'stamp'; font-size: 0; height: 100%; left: 0; position: absolute; top: 0; width: 100%;
		// }

		p {
			margin: 0;
		}

		img {
			height: 73rem;
			object-fit: cover;
			object-fit: cover;
			opacity: 0.6;
			width: 100%;

			@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
				height: 960px;
				object-fit: cover;
				width: 100%;
			}

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				height: 620px;
			}
		}
	}

	.imgpoints {
		display: none;
	}
}

.content-banner {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 59px;

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		padding-top: 200px;
	}

	h1 {
		@include font-custom-gral($gray1, 7vw, 900, 0, 7vw);
		margin: 0 auto 10px 0;
		text-transform: uppercase;
		text-align: center;

		@media all and (min-width: map-get($grid-breakpoints, "lg") + 1) {
			@include font-custom-gral($gray1, 60px, 900, 0, 60px);
			text-align: left;
		}

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			font-size: 10vw;
			line-height: 10vw;
			margin-left: auto;
			margin-right: auto;
		}
	}

	h2 {
		@include font-custom-gral($gray1, 6vw, 700, 0, 6vw);
		margin: 0 auto 1rem 0;
		text-transform: uppercase;

		@media all and (min-width: map-get($grid-breakpoints, "lg") + 1) {
			@include font-custom-gral($gray1, 50px, 700, 0, 50px);
		}

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			font-size: 7vw;
			line-height: 7vw;
			margin-left: auto;
		}
	}

	.button {
		min-height: 40px;
		min-width: 17rem;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			margin: 0 auto;
		}

		&.firstbtn {
			margin-right: 43px;
			@media (max-width: 580px) {
				margin-right: auto;
			}
		}

		&.lastbtn {
			margin-left: 0;
		}

		&:last-of-type {
			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				margin: 32px auto 28px;
			}
		}
	}

	p {
		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			display: flex;
			flex-direction: column;
		}
	}
}

.sec-intro {
	&.sec-bg-black {
		.sec-description {
			@include font-custom-gral($white, 16px, 300, 0, 16px);

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				@include font-custom-gral($white, 14px, 300, 0, 14px);
			}
		}
	}

	&.sec-bg-white {
		.sec-description {
			@include font-custom-gral($gray1, 16px, 300, 0, 16px);

			@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
				@include font-custom-gral($gray1, 14px, 300, 0, 14px);
			}
		}
	}

	.sec-description {
		margin: 3.2rem 0;

		text-align: center;

		@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
			@include font-custom-gral($gray1, 14px, 300, 0, 14px);
			text-align: center;
		}

		p {
			margin: 0;
		}

		.button-outline {
			display: inline-flex;
			margin-top: 2.2rem;
		}
	}
}

.sec-somos-servicios {
	margin: 6rem 0;
	.col-sm-6 {
		p {
			font-size: 14px;
			color: $black;
		}
	}
}

.sec-somos-work {
	.item {
		p {
			img {
				width: 100%;
			}
		}
		h2 {
			font-weight: 600;
			font-size: 30px;
		}
	}
}

#somos-servicios-2{
	margin-top: 4rem;
}

#somos-categories{
	order: 2;
	padding: 2rem 0;
	.item{
		text-align: center;
	}
	.cat-titles{
		p{
			color: $black;
		}
		p, a{
			margin: 2rem 0;
		}
		a{
			color: $red;
			text-decoration: underline;
		}
	}
}

.sec-somos-work-2 {
	.item {
		p {
			img {
				width: 100%;
			}
		}
		h2 {
			text-transform: uppercase;
			font-size: 30px;
		}
	}
}

.sec-home-intro1 {
	.container-fluid {
		border-bottom: 1px solid $gray5;
	}
}

//
.sec-bg-white {
	background: $white;
}

.sec-bg-black {
	background: $black;
	color: $white;
}

.sec-title {
	@include font-custom-gral($gray1, 39px, 700, 0, 39px);
	margin: 0;
	text-align: center;
}

h1,
.sectitle-h1 {
	@include font-custom-gral($gray1, 30px, bold, 0, 30px);

	@media all and (max-width: map-get($grid-breakpoints, "sm") - 1) {
		@include font-custom-gral($gray1, 22px, bold, 0, 22px);
	}
}

.content {
	margin-top: 0;
}

.sec-description-gral {
	p {
		@include font-custom-gral($gray1, 14px, 300, 0, 24px);
	}
}

// pg default

.pg-terms {
	.content {
		ol {
			margin-left: 35px;
			padding-left: 0;
		}

		li,
		p {
			@include font-custom-gral($gray1, 14px, 100, 0, 24px);
		}
	}
}

.pg-default {

	.content {
		margin-top: 130px;
	}

	main {
		.container-fluid {
			padding-bottom: 7.5rem;
		}

		strong {
			color: $black;
			display: block;
			font-weight: 900;
			margin: 20px 0 24px 0;
		}

		p {
			@include font-custom-gral($gray1, 14px, 300, 0, 24px);
			margin: 0 0 15px 0;
		}

		h2 {
			@include font-custom-gral($gray1, 24px, bold, 0, 24px);
		}

		h3 {
			@include font-custom-gral($gray1, 18px, bold, 0, 18px);
		}

		h4 {
			@include font-custom-gral($gray1, 16px, bold, 0, 16px);
		}

		h5 {
			@include font-custom-gral($gray1, 13px, bold, 0, 13px);
		}

		h6 {
			@include font-custom-gral($gray1, 10px, bold, 0, 10px);
		}
	}

	&.pg-paystamp {
		main {
			p {
				margin: 0;
				text-align: center;
			}
		}
	}

	h1 {
		margin-top: 7rem;
	}
	&.privacy-policy{
		h1{
			text-align: center;
		}
	}
}

// promos textos
.promos-text {
	@include font-custom-gral($gray1, 13px, bold, 0, 16px);
	text-transform: uppercase;
}

html {
	.promos-text {
		@include font-custom-gral($gray1, 13px, bold, 0, 16px);
		text-transform: uppercase;
		margin-bottom: 20px;
		@media (max-width: 580px) {
			text-align: center;
		}
	}
}

.expresate-plasmada {
	p:nth-child(2n) {
		@include font-custom-gral($white, 22px, 600, 0, 23px);
		margin: 10px auto 15px;
	}
}

.sec-home-intro2 {
	&.expresate-plasmada {
		p:nth-child(2n) {
			@include font-custom-gral($white, 22px, 600, 0, 23px);
			margin: 10px auto 15px;
		}
	}
}

.sec-we-intro2 {
	&.expresate-plasmada {
		p:nth-child(2n) {
			@include font-custom-gral($gray4, 22px, 600, 0, 23px);
			margin: 10px auto 15px;
		}
	}
}

.sec-home-intro1 {
	&.expresate-plasmada {
		p:nth-child(2n) {
			@include font-custom-gral($gray4, 22px, 600, 0, 23px);
			margin: 10px auto 15px;
		}
	}
}
