@charset "UTF-8";
/*! by SalsaLab */
/*
 * SCSS DEFAULT VARIABLEs File
 * by SalsaLab
 */
/*
 * DEBUG
 * ****************************************
 */
/*
 * BOOTSTRAP GRID VARS
 * ****************************************
 */
/*
 * COLORS
 * ****************************************
 */
/*
 * FONTS
 * ****************************************
 */
/*
 * HTML ELEMENTS
 * ****************************************
 */
/*
 * INCLUDES
 * ****************************************
 */
/*
 * MISC
 * ****************************************
 */
/*
 * SCSS THEME VARIABLEs File
 * by SalsaLab
 */
/*
 * DEBUG
 * ****************************************
 */
/*
 * BOOTSRAP GRID
 * ****************************************
 */
/*
 * COLORS
 * ****************************************
 */
/*
 *
 *  Buttons colors spirit
 *
*/
/*
 * FONTS
 * ****************************************
 */
/*
 * MISC
 * ****************************************
 */
/*
 * HTML ELEMENTS
 * ****************************************
 */
/*
 * LAYOUT
 * ****************************************
 */
/*
 * PATTERNS
 * ****************************************
 */
/*
 *
 *  Se implementa variables para el modal de search general
 *
*/
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1248px) {
    .container {
      max-width: 1248px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1248px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1248px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1248px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/**
 * SCSS Basic File
 * by SalsaLab
 */
@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont.eot");
  src: url("../fonts/iconfont.eot?#iefix") format("embedded-opentype"), url("../fonts/iconfont.woff2") format("woff2"), url("../fonts/iconfont.woff") format("woff"), url("../fonts/iconfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@-ms-viewport {
  width: device-width; }

html {
  font-size: 62.5%;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  background: #fff;
  box-sizing: border-box;
  color: #818e98;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  -ms-overflow-style: scrollbar;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0; }
  body > * {
    font-size: 1.6rem; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container-fluid {
  margin: 0 auto;
  max-width: 1248px; }

/*
 * SCSS BASIC: BLOCK Tags file
 * by SalsaLab
 */
p, ul,
ol {
  margin: 1em auto; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.33333;
  margin: 1em auto; }

h1 {
  font-size: 3.78975rem; }

h2 {
  font-size: 2.8rem; }

h3 {
  font-size: 2.4rem; }

h4 {
  font-size: 2.84302rem; }

h5 {
  font-size: 2.1328rem; }

h6 {
  font-size: 2.84302rem; }

img,
svg {
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: baseline; }

img,
iframe {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

audio:not([controls]) {
  display: none;
  height: 0; }

ul,
ol {
  padding-left: 2em; }

li {
  list-style: none;
  position: relative; }
  li::before {
    color: #2d4254;
    left: -2em;
    position: absolute;
    top: 0; }

ol {
  counter-reset: bullet; }

ol > li::before {
  content: counter(bullet) ".";
  counter-increment: bullet; }

ul > li::before {
  content: "•"; }

nav ul,
nav ol {
  margin: 0;
  padding: 0; }

nav li::before {
  content: none; }

/*
 * SCSS BASIC: INLINE Tags file
 * by SalsaLab
 */
a {
  color: #0275d8;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }
  a:active, a:hover {
    color: #014c8c;
    outline: 0;
    text-decoration: underline; }

/*
 * SCSS BASIC: FORMS Tags file
 * by SalsaLab
 */
.form-field {
  position: relative; }

.form-content,
.buttons {
  padding: 2rem 0;
  position: relative; }

label {
  display: inline-block;
  font: inherit; }
  label:empty {
    display: none; }

textarea:placeholder-shown:not(:focus) + label,
input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label, select:invalid:not(:focus) + label,
select.invalid:not(:focus) + label {
  color: #6d6d6d;
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 0.1rem 2.4rem;
  pointer-events: none;
  top: 2rem; }

select,
textarea,
input:not([type="radio"]):not([type="checkbox"]) {
  background: none;
  border: none;
  border-bottom: solid 1px #e1e1e1;
  color: #6d6d6d;
  font-family: inherit;
  font-size: 1.2rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0.1rem 2.4rem;
  transition: all 0.2s ease-in-out;
  width: 100%; }
  select + label,
  textarea + label,
  input:not([type="radio"]):not([type="checkbox"]) + label {
    color: #6d6d6d;
    display: block;
    font-size: 0.9rem;
    left: 0;
    position: absolute;
    top: 0.5rem;
    transition: all 0.2s ease-in-out; }
  select::placeholder,
  textarea::placeholder,
  input:not([type="radio"]):not([type="checkbox"])::placeholder {
    color: #6d6d6d; }
  select:focus,
  textarea:focus,
  input:not([type="radio"]):not([type="checkbox"]):focus {
    border-bottom: solid 2px #e1e1e1;
    box-shadow: none;
    padding-bottom: 0rem; }
    select:focus + label,
    textarea:focus + label,
    input:not([type="radio"]):not([type="checkbox"]):focus + label {
      color: #6d6d6d; }
  select:invalid, select.invalid,
  textarea:invalid,
  textarea.invalid,
  input:not([type="radio"]):not([type="checkbox"]):invalid,
  input:not([type="radio"]):not([type="checkbox"]).invalid {
    box-shadow: none; }
  select[disabled], select[disabled]:placeholder-shown:not(:focus),
  textarea[disabled],
  textarea[disabled]:placeholder-shown:not(:focus),
  input:not([type="radio"]):not([type="checkbox"])[disabled],
  input:not([type="radio"]):not([type="checkbox"])[disabled]:placeholder-shown:not(:focus) {
    border-color: #cbcfd3;
    color: #cbcfd3;
    cursor: default;
    pointer-events: none; }
    select[disabled] + label, select[disabled]:placeholder-shown:not(:focus) + label,
    textarea[disabled] + label,
    textarea[disabled]:placeholder-shown:not(:focus) + label,
    input:not([type="radio"]):not([type="checkbox"])[disabled] + label,
    input:not([type="radio"]):not([type="checkbox"])[disabled]:placeholder-shown:not(:focus) + label {
      color: #cbcfd3; }

textarea:placeholder-shown:not(:focus)::placeholder,
input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus)::placeholder {
  color: #6d6d6d;
  border-bottom-color: #e1e1e1;
  opacity: 0; }

textarea:invalid:not(:focus):not(:placeholder-shown), textarea.invalid:not(:focus):not(:placeholder-shown),
input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown),
input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown) {
  border-bottom-color: #d9534f;
  box-shadow: none;
  color: #d9534f; }
  textarea:invalid:not(:focus):not(:placeholder-shown) + label, textarea.invalid:not(:focus):not(:placeholder-shown) + label,
  input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown) + label,
  input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown) + label {
    color: #d9534f; }

input[type="radio"], input[type="checkbox"] {
  margin-right: 1.6rem; }
  input[type="radio"] + label, input[type="checkbox"] + label {
    color: #6d6d6d; }

select:invalid:not(:focus),
select.invalid:not(:focus) {
  color: rgba(109, 109, 109, 0);
  border-bottom-color: #e1e1e1; }

option {
  color: #6d6d6d; }
  option[disabled] {
    color: #999999; }

textarea {
  height: 3em;
  min-height: 3em;
  resize: vertical; }

button,
.button {
  appereance: none;
  background: #000;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: 1;
  margin-bottom: 16px;
  padding: 0.8rem 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out; }
  button + button,
  button + .button,
  .button + button,
  .button + .button {
    margin-left: 16px; }
  button:hover, button:focus, button:active,
  .button:hover,
  .button:focus,
  .button:active {
    background: #fff;
    color: #fff;
    text-decoration: none; }
  button.negative,
  .button.negative {
    background: #fff;
    color: #000; }
  button[disabled],
  .button[disabled] {
    background: #ccc;
    color: #cbcfd3;
    cursor: default;
    pointer-events: none; }

/**
 * SCSS ICONFONT Styles
 * by SalsaLab
 */
.ico {
  display: inline-block;
  line-height: 1; }
  .ico::before {
    display: inline-block;
    font-family: "iconfont";
    font-size: 2.2rem;
    font-weight: normal;
    line-height: 1;
    vertical-align: middle;
    color: inherit;
    content: attr(data-ico);
    padding: 1.1rem; }

.ico-sm::before {
  font-size: 1.6rem;
  padding: 1.6rem; }

.ico-md::before {
  font-size: 4.8rem;
  padding: 2.4rem; }

.ico-lg::before {
  font-size: 6.4rem;
  padding: 3.2rem; }

.ico-inline::before {
  padding: 0 0.8rem 0 0; }

/*
 * LAYOUT: SITE HEADER
 * by SalsaLab
 */
.header {
  height: 9.6rem;
  width: 100%;
  height: 9.6rem;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 10; }
  @media all and (min-width: 992px) {
    .header {
      height: 6.4rem; } }

.content {
  margin-top: 9.6rem; }
  @media all and (min-width: 992px) {
    .content {
      margin-top: 6.4rem; } }

.header .container-fluid {
  align-items: center;
  display: flex;
  justify-content: space-between; }

.site-logo-link {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(../images/logo.svg) center no-repeat;
  background-size: contain;
  display: block;
  height: 9.6rem;
  width: 12.8rem; }
  @media all and (min-width: 992px) {
    .site-logo-link {
      height: 14rem;
      width: 15.6rem; } }

/*
 * LAYOUT: MENU
 * by SalsaLab
 */
.menu a {
  cursor: pointer;
  display: block;
  text-decoration: none; }

.menu-mobile {
  cursor: pointer; }

@media all and (max-width: 991px) {
  .menu {
    position: relative; }
    .menu.active .menu-content {
      opacity: 1;
      pointer-events: all; }
    .menu a {
      display: block;
      width: 100%; }
  .menu-content {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 4.8rem;
    transition: opacity 0.15s linear;
    width: 100vw; }
    .menu-content.active {
      display: block;
      opacity: 1; } }

@media all and (min-width: 992px) {
  .menu-mobile {
    display: none; }
  .menu-content {
    display: flex;
    justify-content: flex-start; } }

/*
 * LAYOUT: SITE HEADER
 * by SalsaLab
 */
.footer-logo-link {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(../images/bkg/payments.svg) center no-repeat;
  background-size: contain;
  display: block;
  height: 10rem;
  width: 10rem; }
  @media all and (min-width: 768px) {
    .footer-logo-link {
      height: 10rem;
      width: 10rem; } }

/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-wrapper {
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  transition-property: transform;
  width: 100%;
  z-index: 1; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  box-lines: multiple;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  margin: 0 auto;
  transition-timing-function: ease-out; }

.swiper-slide {
  height: 100%;
  flex-shrink: 0;
  position: relative;
  width: 100%; }
  .swiper-slide .container {
    position: relative;
    z-index: 3; }
  .swiper-slide .banner {
    position: absolute;
    z-index: 2; }

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height; }

.swiper-button-prev,
.swiper-button-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  cursor: default;
  opacity: 0.35;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 0;
  right: auto; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  left: auto;
  right: 0; }

.swiper-pagination {
  bottom: 0;
  left: 50%;
  position: absolute;
  text-align: center;
  transition: 300ms;
  transform: translateX(-50%);
  z-index: 10; }

.swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  display: inline-block;
  height: 1rem;
  line-height: 1;
  margin: 1.1rem 0.7rem;
  width: 1rem; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-bullet-active {
  background: #b69b6e;
  background-clip: padding-box; }

.swiper-slide {
  overflow: hidden; }
  .swiper-slide .banner {
    height: auto;
    left: 50%;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto; }

/**
 * LIGHTBOX basic styles
 * by SalsaLab
 */
.slb-lightbox-container, .slb-lightbox-media {
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw; }

.slb-lightbox-container {
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  z-index: 10001; }
  .slb-lightbox-container.active {
    opacity: 1;
    pointer-events: all; }

.slb-lightbox-nav {
  height: 0; }
  .slb-lightbox-nav a {
    cursor: pointer;
    position: absolute;
    z-index: 10005; }

.slb-lightbox-prev {
  left: 0;
  top: 50%;
  transform: translateY(-50%); }

.slb-lightbox-next {
  right: 0;
  top: 50%;
  transform: translateY(-50%); }

.slb-lightbox-close {
  right: 0;
  top: 0; }

.slb-lightbox-media {
  z-index: 10002; }
  @media all and (min-width: 992px) {
    .slb-lightbox-media {
      padding: 0; } }
  .slb-lightbox-media figure,
  .slb-lightbox-media div {
    height: 100%;
    margin: 0;
    position: relative;
    width: 100%; }
  .slb-lightbox-media figure.loading:before {
    animation: loading 1s ease-in-out infinite alternate;
    background: #fff;
    content: '';
    border-radius: 2rem;
    display: block;
    height: 2rem;
    left: 50%;
    margin: -1rem 0 0 -1rem;
    position: absolute;
    top: 50%;
    width: 2rem;
    z-index: 10003; }
  .slb-lightbox-media img,
  .slb-lightbox-media video,
  .slb-lightbox-media iframe {
    animation: fadein 0.25s;
    height: auto;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.15s linear;
    z-index: 10002; }
    .slb-lightbox-media img.remove-queued,
    .slb-lightbox-media video.remove-queued,
    .slb-lightbox-media iframe.remove-queued {
      opacity: 0; }
  .slb-lightbox-media video {
    background: #000; }
  .slb-lightbox-media iframe {
    background: #000;
    border: 0;
    display: block;
    height: 100%;
    width: 100%; }
  .slb-lightbox-media figcaption:empty {
    display: none; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes loading {
  from {
    transform: scale(1); }
  to {
    transform: scale(3); } }

.slb-lightbox-active {
  overflow: hidden; }

@font-face {
  font-family: 'uni_sansheavy_caps';
  src: url("../fonts/uni_sans_heavy-webfont.woff2") format("woff2"), url("../fonts/uni_sans_heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'brandon_grotesqueblack';
  src: url("../fonts/brandon-grotesque-black-webfont.woff2") format("woff2"), url("../fonts/brandon-grotesque-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.delay-1s {
  animation-delay: 1s; }

.animated.delay-2s {
  animation-delay: 2s; }

.animated.delay-3s {
  animation-delay: 3s; }

.animated.delay-4s {
  animation-delay: 4s; }

.animated.delay-5s {
  animation-delay: 5s; }

.animated.fast {
  animation-duration: 800ms; }

.animated.faster {
  animation-duration: 500ms; }

.animated.slow {
  animation-duration: 2s; }

.animated.slower {
  animation-duration: 3s; }

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  animation-name: slideInUp; }

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  animation-name: slideInLeft; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1; }
  25%,
  75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes pulse {
  from,
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); } }

@keyframes pulse {
  from,
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes heartBeat {
  0%,
  28%,
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14%,
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); } }

@keyframes heartBeat {
  0%,
  28%,
  70% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  14%,
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); } }

.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

[class*="button"] {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  min-height: 40px;
  text-transform: uppercase; }
  [class*="button"]:hover {
    text-decoration: none; }

body {
  font-family: "Montserrat", sans-serif;
  background: url(../images/bkg/g-back.png) center top repeat;
  background-size: 100%; }
  body * {
    font-family: "Montserrat", sans-serif; }

.button.thered {
  color: #fff;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 14px;
  background: #C51625;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: color 1000ms;
  transition: color 1000ms;
  text-transform: initial;
  padding: 0 5rem; }
  .button.thered:active, .button.thered:focus, .button.thered:hover {
    background: #C51625; }
  .button.thered::before {
    -webkit-transform-origin: 0 50%;
    -webkit-transform: scaleX(0);
    -webkit-transition-property: transform;
    -webkit-transition: 300ms ease-out;
    background: #fff;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 0 50%;
    transform: scaleX(0);
    transition-property: transform;
    transition: 300ms ease-out;
    z-index: -1; }
  .button.thered:hover {
    color: #C51625;
    border: solid 3px #000; }
    .button.thered:hover::before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1); }

.no-btn-crusel .button-slide {
  display: none; }

.button {
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 14px;
  background: #000;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: color 1000ms;
  transition: color 1000ms;
  min-height: 40px;
  padding-top: 12px;
  text-transform: uppercase; }
  .button:active, .button:focus, .button:hover {
    background: #000; }
  .button::before {
    -webkit-transform-origin: 0 50%;
    -webkit-transform: scaleX(0);
    -webkit-transition-property: transform;
    -webkit-transition: 300ms ease-out;
    background: #fff;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 0 50%;
    transform: scaleX(0);
    transition-property: transform;
    transition: 300ms ease-out;
    z-index: -1; }
  .button:hover {
    color: #000;
    border: solid 3px #000; }
    .button:hover::before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1); }
  .button.btn-spirit {
    background: transparent;
    border: 0;
    color: #fff;
    font-size: 16px;
    height: 30px;
    position: relative;
    z-index: 1;
    font-weight: 100;
    background: #C51625;
    text-transform: initial; }
    .button.btn-spirit::after, .button.btn-spirit::before {
      bottom: 0;
      content: "";
      position: absolute;
      right: 0;
      transition: all 0.4s;
      z-index: -99999; }
    .button.btn-spirit::before {
      background: #C51625;
      height: 100%;
      transform: translate(0%, 0%);
      width: 100%; }
    .button.btn-spirit::after {
      backdrop-filter: blur(5px);
      background: #ffffff15;
      height: 35px;
      transform: translate(10px, 10px);
      width: 35px; }
    .button.btn-spirit:hover::before {
      height: 110%;
      transform: translate(5%, 20%);
      width: 110%; }
    .button.btn-spirit:hover::after {
      height: 100%;
      transform: translate(0, 0);
      width: 100%; }
    .button.btn-spirit:active::after {
      transform: translate(0, 5%);
      transition: 0s; }
    .button.btn-spirit.btn-alt {
      border: 1px solid #fff;
      font-weight: 300; }
      .button.btn-spirit.btn-alt::before {
        background: transparent; }
      .button.btn-spirit.btn-alt::after {
        background: #fff; }
      .button.btn-spirit.btn-alt:hover {
        color: #000; }
    .button.btn-spirit.btn-shadow {
      border: 1px solid #2f2f2f;
      color: #2f2f2f;
      font-weight: 300;
      text-transform: capitalize; }
      .button.btn-spirit.btn-shadow::before {
        background: transparent; }
      .button.btn-spirit.btn-shadow::after {
        background: #eaebf0; }
      .button.btn-spirit.btn-shadow:hover {
        color: #000; }

.button-white {
  color: #252233;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 14px;
  background: #fff;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: color 1000ms;
  transition: color 1000ms;
  background: #fff;
  position: relative; }
  .button-white:active, .button-white:focus, .button-white:hover {
    background: #fff; }
  .button-white::before {
    -webkit-transform-origin: 0 50%;
    -webkit-transform: scaleX(0);
    -webkit-transition-property: transform;
    -webkit-transition: 300ms ease-out;
    background: #fff;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 0 50%;
    transform: scaleX(0);
    transition-property: transform;
    transition: 300ms ease-out;
    z-index: -1; }
  .button-white:hover {
    color: #000;
    border: solid 3px #000; }
    .button-white:hover::before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1); }
  .button-white:hover {
    color: #fff; }
  .button-white::before {
    background: #000;
    color: #fff; }

.button-outline {
  color: #252233;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 14px;
  border: 5px solid #000;
  color: #000;
  line-height: 30px;
  min-width: 164px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  transform: translateZ(0);
  transition: color 1000ms; }
  .button-outline::before {
    /*-webkit-transform-origin: 0 50%; -webkit-transform: scaleX(0); -webkit-transition-property: transform; -webkit-transition: 300ms ease-out;*/
    background: #000;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: 300ms ease-out;
    transition-property: transform;
    z-index: -1; }
  .button-outline:hover {
    color: #fff; }
    .button-outline:hover::before {
      /* -webkit-transform: scaleX(1); */
      transform: scaleX(1); }

.nav.flex-column.nav-pills .nav-link {
  background-color: transparent;
  color: #2f2f2f;
  font-size: 14px;
  line-height: 18px; }
  .nav.flex-column.nav-pills .nav-link.active {
    font-weight: 500; }

@media (min-width: 320px) and (max-width: 600px) {
  .col-lg-4.content-banner {
    margin-right: 2em !important;
    width: auto !important; }
  .content-banner .button:last-of-type {
    margin: 32px 2rem 28px auto !important; }
  .sec-home-work .row {
    flex-direction: column; }
    .sec-home-work .row .col-6 {
      width: 90% !important;
      max-width: 90%;
      margin: 0 auto; }
  .grid-filters {
    flex-direction: column;
    margin-top: 2rem; }
  .wrapper-items.catalogon {
    display: flex !important;
    flex-direction: column !important; }
    .wrapper-items.catalogon .catalogoimg.item {
      width: 90%;
      margin: 1rem auto; }
  .nav-item-parent {
    margin-top: 2rem; }
  .store-left .grid-filters .wrapper-cat-product {
    justify-content: center !important; }
  .sec-home-intro2 .row,
  .sec-home-intro3 .row,
  .sec-gral-servicios .row,
  .sec-somos-servicios .row,
  .sec-somos-work .row,
  .sec-somos-work-2 .row,
  .sec-contact .row,
  .product-top-img-vampyr .row,
  .product-top-img-paladin .row,
  .footer .row {
    flex-direction: column; }
    .sec-home-intro2 .row .col-6,
    .sec-home-intro2 .row .col-2,
    .sec-home-intro3 .row .col-6,
    .sec-home-intro3 .row .col-2,
    .sec-gral-servicios .row .col-6,
    .sec-gral-servicios .row .col-2,
    .sec-somos-servicios .row .col-6,
    .sec-somos-servicios .row .col-2,
    .sec-somos-work .row .col-6,
    .sec-somos-work .row .col-2,
    .sec-somos-work-2 .row .col-6,
    .sec-somos-work-2 .row .col-2,
    .sec-contact .row .col-6,
    .sec-contact .row .col-2,
    .product-top-img-vampyr .row .col-6,
    .product-top-img-vampyr .row .col-2,
    .product-top-img-paladin .row .col-6,
    .product-top-img-paladin .row .col-2,
    .footer .row .col-6,
    .footer .row .col-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
      .sec-home-intro2 .row .col-6 p,
      .sec-home-intro2 .row .col-6 h2,
      .sec-home-intro2 .row .col-6 h3,
      .sec-home-intro2 .row .col-6 h1,
      .sec-home-intro2 .row .col-2 p,
      .sec-home-intro2 .row .col-2 h2,
      .sec-home-intro2 .row .col-2 h3,
      .sec-home-intro2 .row .col-2 h1,
      .sec-home-intro3 .row .col-6 p,
      .sec-home-intro3 .row .col-6 h2,
      .sec-home-intro3 .row .col-6 h3,
      .sec-home-intro3 .row .col-6 h1,
      .sec-home-intro3 .row .col-2 p,
      .sec-home-intro3 .row .col-2 h2,
      .sec-home-intro3 .row .col-2 h3,
      .sec-home-intro3 .row .col-2 h1,
      .sec-gral-servicios .row .col-6 p,
      .sec-gral-servicios .row .col-6 h2,
      .sec-gral-servicios .row .col-6 h3,
      .sec-gral-servicios .row .col-6 h1,
      .sec-gral-servicios .row .col-2 p,
      .sec-gral-servicios .row .col-2 h2,
      .sec-gral-servicios .row .col-2 h3,
      .sec-gral-servicios .row .col-2 h1,
      .sec-somos-servicios .row .col-6 p,
      .sec-somos-servicios .row .col-6 h2,
      .sec-somos-servicios .row .col-6 h3,
      .sec-somos-servicios .row .col-6 h1,
      .sec-somos-servicios .row .col-2 p,
      .sec-somos-servicios .row .col-2 h2,
      .sec-somos-servicios .row .col-2 h3,
      .sec-somos-servicios .row .col-2 h1,
      .sec-somos-work .row .col-6 p,
      .sec-somos-work .row .col-6 h2,
      .sec-somos-work .row .col-6 h3,
      .sec-somos-work .row .col-6 h1,
      .sec-somos-work .row .col-2 p,
      .sec-somos-work .row .col-2 h2,
      .sec-somos-work .row .col-2 h3,
      .sec-somos-work .row .col-2 h1,
      .sec-somos-work-2 .row .col-6 p,
      .sec-somos-work-2 .row .col-6 h2,
      .sec-somos-work-2 .row .col-6 h3,
      .sec-somos-work-2 .row .col-6 h1,
      .sec-somos-work-2 .row .col-2 p,
      .sec-somos-work-2 .row .col-2 h2,
      .sec-somos-work-2 .row .col-2 h3,
      .sec-somos-work-2 .row .col-2 h1,
      .sec-contact .row .col-6 p,
      .sec-contact .row .col-6 h2,
      .sec-contact .row .col-6 h3,
      .sec-contact .row .col-6 h1,
      .sec-contact .row .col-2 p,
      .sec-contact .row .col-2 h2,
      .sec-contact .row .col-2 h3,
      .sec-contact .row .col-2 h1,
      .product-top-img-vampyr .row .col-6 p,
      .product-top-img-vampyr .row .col-6 h2,
      .product-top-img-vampyr .row .col-6 h3,
      .product-top-img-vampyr .row .col-6 h1,
      .product-top-img-vampyr .row .col-2 p,
      .product-top-img-vampyr .row .col-2 h2,
      .product-top-img-vampyr .row .col-2 h3,
      .product-top-img-vampyr .row .col-2 h1,
      .product-top-img-paladin .row .col-6 p,
      .product-top-img-paladin .row .col-6 h2,
      .product-top-img-paladin .row .col-6 h3,
      .product-top-img-paladin .row .col-6 h1,
      .product-top-img-paladin .row .col-2 p,
      .product-top-img-paladin .row .col-2 h2,
      .product-top-img-paladin .row .col-2 h3,
      .product-top-img-paladin .row .col-2 h1,
      .footer .row .col-6 p,
      .footer .row .col-6 h2,
      .footer .row .col-6 h3,
      .footer .row .col-6 h1,
      .footer .row .col-2 p,
      .footer .row .col-2 h2,
      .footer .row .col-2 h3,
      .footer .row .col-2 h1 {
        text-align: center; }
  .mb14 {
    margin-bottom: 2rem !important; }
  .page-section.sec-contacto.home-contacto #contactstamp-c {
    flex-direction: column !important; }
  .page-section.sec-contacto.home-contacto #contactstamp-c .form-field,
  .page-section.sec-contacto.home-contacto .buttons {
    width: 100% !important;
    max-width: 100% !important; }
  #header .ico::before {
    color: #fff; }
  .page-section.sec-contacto.home-contacto .buttons {
    border: 0 !important; }
  .page-section.sec-contacto.home-contacto .form-field {
    border-right: 1px solid !important; }
  .footer-logo-link {
    margin: auto !important; }
  .menu-mobile::before {
    top: 2rem; }
  .sec-carusel .carusel-floating,
  .sec-home-work .carusel-floating {
    position: initial !important;
    transform: none !important; }
    .sec-carusel .carusel-floating h2,
    .sec-home-work .carusel-floating h2 {
      margin-left: 2rem; }
  .sec-home-work .container-fluid,
  .footer .container-fluid {
    width: 90%;
    margin: 0 auto; }
  .main-menu .menu-content {
    height: auto !important;
    background: #000 !important;
    top: 70px !important; }
  .main-menu a.active,
  .main-menu a:hover {
    color: #fff !important; }
  .pg-nosotros .content-banner h2 span {
    color: #fff !important; }
  .sec-contacto-c {
    margin-top: 0 !important; }
  #infoContact {
    margin-top: 6rem !important;
    margin-bottom: 0rem !important; } }

.simplesearch .search-box {
  display: flex;
  margin: 10rem auto 4rem;
  max-width: 400px; }
  .simplesearch .search-box .search-input {
    border: 1px solid #6d6d6d;
    padding: 2rem 2rem; }
  .simplesearch .search-box .btn-red {
    padding: 1.4rem 2rem; }

.simplesearch .resultados {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem; }
  .simplesearch .resultados .search-item h3 {
    margin: 0; }
  .simplesearch .resultados .search-item a {
    color: #c51625;
    font-size: 14px; }
  .simplesearch .resultados .search-item hr {
    display: none; }

.header {
  background: #000;
  height: auto;
  position: fixed; }
  .header .container-fluid {
    position: relative; }
    @media all and (max-width: 991px) {
      .header .container-fluid {
        padding: 0; } }
  .header.oncartheader .container-fluid::after {
    content: none; }
  @media all and (max-width: 991px) {
    .header .site-logo {
      order: 2; } }

.top-bar {
  background: #2f2f2f; }
  .top-bar .container-fluid {
    justify-content: flex-end !important; }
    .top-bar .container-fluid a {
      font-size: 14px;
      color: #fff; }

.main-menu {
  margin-left: auto;
  order: 1;
  height: 70px; }
  @media all and (min-width: 769px) {
    .main-menu {
      margin-right: 3rem; } }
  @media all and (max-width: 991px) {
    .main-menu {
      margin: 0; } }
  @media all and (min-width: 769px) {
    .main-menu .mnu {
      margin-right: 30px; } }
  @media all and (max-width: 991px) {
    .main-menu .mnu {
      margin-bottom: 1.9rem; } }
  .main-menu a {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 2.65px;
    line-height: 24px;
    text-align: center;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media all and (max-width: 991px) {
      .main-menu a {
        padding-left: 15px;
        text-align: left; } }
    .main-menu a:hover, .main-menu a.active {
      border-bottom: 4px solid #C51625; }
      @media all and (max-width: 991px) {
        .main-menu a:hover, .main-menu a.active {
          color: #000; } }
  .main-menu .menu-content {
    display: flex;
    justify-content: space-around;
    height: 70px; }
    @media all and (max-width: 991px) {
      .main-menu .menu-content {
        background: #c2d1d9;
        flex-direction: column;
        padding-top: 2rem;
        top: 37px; } }

.header .cart-top .icon-cart {
  background-size: 35px auto;
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 35px; }

.header .cart-top:active .icon-cart, .header .cart-top:focus .icon-cart, .header .cart-top:hover .icon-cart {
  background-size: 35px auto; }

.cart-top {
  background: transparent;
  position: relative;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: color 1000ms;
  transition: color 1000ms;
  align-items: center;
  display: flex;
  height: 7rem;
  justify-content: space-around;
  order: 3;
  position: relative;
  width: auto; }
  .cart-top:active, .cart-top:focus, .cart-top:hover {
    background: transparent; }
  .cart-top::before {
    -webkit-transform-origin: 0 50%;
    -webkit-transform: scaleX(0);
    -webkit-transition-property: transform;
    -webkit-transition: 300ms ease-out;
    background: #fff;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: 0 50%;
    transform: scaleX(0);
    transition-property: transform;
    transition: 300ms ease-out;
    z-index: -1; }
  .cart-top:hover {
    color: #000;
    border: solid 3px #000; }
    .cart-top:hover::before {
      -webkit-transform: scaleX(1);
      transform: scaleX(1); }
  .cart-top .total-cart {
    background: #fff;
    border-radius: 50%;
    color: #000;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 15px;
    width: 20px; }
    @media all and (max-width: 991px) {
      .cart-top .total-cart {
        right: 5px;
        top: 5px; } }
  .cart-top:active, .cart-top:focus, .cart-top:hover {
    border: 0;
    text-decoration: none; }
    .cart-top:active .icon-cart, .cart-top:focus .icon-cart, .cart-top:hover .icon-cart {
      background: url(../images/bkg/icon-cart-square.svg) no-repeat center;
      background-size: 53px auto; }
    .cart-top:active .txt, .cart-top:focus .txt, .cart-top:hover .txt {
      color: #000; }
  @media all and (max-width: 991px) {
    .cart-top {
      height: 4.8rem;
      width: 4.8rem; } }
  .cart-top div {
    align-items: center;
    display: flex; }
  .cart-top .txt {
    color: #fff;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 2.65px;
    line-height: 24px;
    text-transform: uppercase; }
    @media all and (max-width: 991px) {
      .cart-top .txt {
        display: none; } }
  .cart-top .icon-cart {
    background: url(../images/bkg/icon-cart-square.svg) no-repeat center;
    background-size: 53px auto;
    display: block;
    height: 53px;
    width: 53px; }
    .cart-top .icon-cart:active, .cart-top .icon-cart:focus, .cart-top .icon-cart:hover {
      border: 0;
      text-decoration: none; }
      .cart-top .icon-cart:active .icon-cart, .cart-top .icon-cart:focus .icon-cart, .cart-top .icon-cart:hover .icon-cart {
        background: url(../images/bkg/icon-cart-square.svg) no-repeat center;
        background-size: 53px auto; }
      .cart-top .icon-cart:active .txt, .cart-top .icon-cart:focus .txt, .cart-top .icon-cart:hover .txt {
        color: #000; }
    @media all and (max-width: 991px) {
      .cart-top .icon-cart {
        background-position: right center; } }
  .cart-top .box {
    -webkit-transition: 0.5s;
    border: 3px solid #000;
    color: #000;
    cursor: pointer;
    height: 100%;
    justify-content: space-around;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: 0.5s;
    vertical-align: middle;
    width: 100%; }
    .cart-top .box:hover {
      border: 3px solid rgba(0, 160, 80, 0);
      color: #000; }
      .cart-top .box:hover::after, .cart-top .box:hover::before {
        -webkit-transform: scale(1.1); }
    .cart-top .box::before, .cart-top .box::after {
      -webkit-transform: scale(0);
      box-sizing: border-box;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.5s;
      width: 100%;
      z-index: 3; }
  .cart-top .foo::before {
    -webkit-transform-origin: 0 100%;
    border-bottom: 3px solid #000;
    border-left: 3px solid #000; }
  .cart-top .foo::after {
    -webkit-transform-origin: 100% 0%;
    border-right: 3px solid #000;
    border-top: 3px solid #000; }

.mnu-tienda,
.mnu-home {
  min-width: 84px; }

.mnu-contacto {
  min-width: 122px; }

.mnu-nosotros {
  min-width: 137px; }

.site-logo-link {
  height: 55px;
  width: 189px; }

.menu-mobile {
  color: #000; }
  .menu-mobile::before {
    left: 12px;
    font-size: 26px;
    padding: 0;
    position: relative; }

.sticky {
  background: #000;
  position: fixed;
  z-index: 10; }

.mnu.mnu-product:hover .children {
  opacity: 1;
  pointer-events: all; }
  .mnu.mnu-product:hover .children:hover {
    opacity: 1; }

.mnu .children {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  background: #fff;
  padding: 2rem;
  width: 992px;
  min-height: 310px;
  column-gap: 2rem;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  right: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  .mnu .children .prior-product {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    position: relative; }
    .mnu .children .prior-product:before {
      content: "";
      width: 40px;
      height: 40px;
      background: url(../images/bkg/badget.svg) no-repeat left;
      position: absolute;
      top: 5px;
      right: 5px; }
    .mnu .children .prior-product .prior-data {
      background: rgba(255, 255, 255, 0.5); }
      .mnu .children .prior-product .prior-data p {
        margin: 0; }
        .mnu .children .prior-product .prior-data p.p-title {
          color: #C51625;
          font-size: 16px; }
        .mnu .children .prior-product .prior-data p.p-description {
          color: #3c4352;
          font-size: 20px;
          line-height: 1; }
        .mnu .children .prior-product .prior-data p.p-price {
          color: #252233;
          font-size: 22px; }
  .mnu .children .mnu-childrens {
    display: grid;
    grid-template-columns: 1fr 1fr; }
    .mnu .children .mnu-childrens .sub-mnu a {
      color: #3c4352;
      font-size: 14px;
      text-transform: uppercase;
      border-bottom: 0;
      text-align: left;
      display: flex;
      justify-content: flex-start;
      height: auto;
      margin-bottom: 1rem; }
      .mnu .children .mnu-childrens .sub-mnu a:before {
        content: "";
        width: 20px;
        height: 11px;
        background: url(../images/bkg/mnu-arrow.svg) no-repeat left; }

.wrapper-icon {
  height: 7rem;
  position: relative;
  order: 2;
  width: 3em;
  display: flex; }
  .wrapper-icon .i-search {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }
    .wrapper-icon .i-search div {
      fill: #000; }
      .wrapper-icon .i-search div:hover {
        fill: #666f80; }

.modal-search {
  margin: 0;
  max-width: 1100%; }
  .modal-search .wrapper-form {
    display: flex;
    background: url(../images/bkg/marco.svg) center top no-repeat;
    background-size: 100%;
    padding: 13rem 0; }
  .modal-search .modal-content {
    background: black;
    background: linear-gradient(0deg, #6d6d6d 10%, #eaebf0 50%, #eaebf0 80%, #6d6d6d 100%); }
    .modal-search .modal-content .modal-header {
      display: none; }
    .modal-search .modal-content .modal-body {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 99vh;
      overflow: hidden;
      width: 98vw; }
      .modal-search .modal-content .modal-body .icon-x {
        color: #C51625;
        cursor: pointer;
        margin: 0 1.5em;
        position: relative;
        right: 0;
        top: 0;
        z-index: 12;
        display: flex;
        align-items: center; }
      .modal-search .modal-content .modal-body .btn-spirit {
        min-width: 11.5em; }
  .modal-search .bx-x:before {
    font-size: 64px; }
  .modal-search .search-box {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 50px;
    margin-right: 3em; }
    .modal-search .search-box input[type="text"] {
      border: none;
      box-sizing: border-box;
      border-radius: 50px;
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      height: 50px;
      min-width: 31em;
      padding: 5px 40px 5px 10px;
      transition: width 800ms cubic-bezier(0.68, -0.55, 0.27, 1.55) 150ms;
      text-align: center; }
      .modal-search .search-box input[type="text"]::placeholder {
        color: #4e4e4e !important;
        opacity: 100 !important;
        font-size: 30px; }
      .modal-search .search-box input[type="text"]:focus {
        outline: none; }

.footer {
  background: #000;
  min-height: 16.1rem;
  padding-top: 4rem; }
  @media all and (max-width: 767px) {
    .footer {
      text-align: center; } }
  @media all and (max-width: 767px) {
    .footer .row {
      flex-direction: column; } }
  .footer .icosocial {
    display: inline-flex;
    font-size: 0;
    width: 24px;
    height: 24px; }
    .footer .icosocial:hover {
      -webkit-animation-name: heartBeat;
      animation-name: heartBeat;
      -webkit-animation-duration: 1.3s;
      animation-duration: 1.3s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out; }
  .footer .socials-media {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .footer .ifacebook {
    background: url(../images/bkg/Facebook.svg) no-repeat center;
    background-size: 10px auto; }
  .footer .itwitter {
    background: url(../images/bkg/Twitter.svg) no-repeat center;
    background-size: 24px auto; }
  .footer .itinstagram {
    background: url(../images/bkg/Instagram.svg) no-repeat center;
    background-size: 22px auto; }
  .footer .ityoutube {
    background: url(../images/bkg/Youtube.svg) no-repeat center;
    background-size: 24px auto; }
  .footer .itvimeo {
    background: url(../images/bkg/Vimeo.svg) no-repeat center;
    background-size: 24px auto; }
  .footer a,
  .footer p,
  .footer h1 {
    color: #fff; }
  .footer a,
  .footer p {
    font-size: 14px;
    text-decoration: none; }
  .footer h1 {
    font-size: 16px;
    text-transform: uppercase; }
  .footer ul {
    padding-left: 0; }
    .footer ul ul {
      margin-left: 2rem; }
    .footer ul.footer-nav li {
      padding: 1rem 0;
      text-decoration: none; }
    .footer ul.nav-categories {
      padding-left: 3rem; }
      .footer ul.nav-categories li.parent::before {
        content: "";
        background: url(../images/bkg/small-red-arrow.svg) no-repeat center;
        width: 10px;
        height: 9px;
        margin-top: 7px; }
    .footer ul li {
      list-style: none; }
    .footer ul li::before {
      content: ""; }

.footer-last-fblend {
  display: flex;
  column-gap: 4rem;
  align-items: center; }

.mosquito {
  background: url(../images/bkg/mosquito.png) no-repeat center;
  width: 38.52px;
  height: 30px;
  cursor: pointer; }
  .mosquito:hover {
    background: url(../images/bkg/mosquito.png) no-repeat center; }

.footer .form-group {
  border: 1px solid #4e4e4e;
  padding: 1rem; }
  .footer .form-group .form-control {
    border: 0 !important;
    padding: 0 0 1rem !important;
    height: auto !important;
    color: #4e4e4e !important;
    font-size: 16px; }
    .footer .form-group .form-control::placeholder {
      color: #4e4e4e !important;
      opacity: 100 !important;
      font-size: 16px; }

.footer .button {
  background: 0 0;
  border: 1px solid #c51625;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
  font-family: Montserrat,sans-serif;
  font-weight: 100;
  padding: 1rem 3rem;
  margin-top: 2rem !important;
  margin-bottom: 8rem !important; }

.footer-bottom {
  margin-top: 4rem;
  background: #6d6d6d; }
  .footer-bottom .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0; }
    .footer-bottom .container-fluid p {
      margin: 0; }

.footer-logo {
  order: 3; }
  @media all and (max-width: 767px) {
    .footer-logo {
      margin-right: auto; } }

.footer-mnu  {
  margin-top: 0.8rem;
  order: 2; }
  .footer-mnu  a {
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px; }

.accordion-button {
  width: 80%;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  padding: 2rem 1.25rem; }
  .accordion-button:hover, .accordion-button:focus {
    background: transparent; }

.accordion-body {
  width: 80%;
  color: #fff;
  font-size: 14px; }

.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>"); }

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>"); }

.accordion-button:not(.collapsed) {
  color: #fff; }

.accordion-button:focus {
  border-color: #fff; }

.copyright {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 3.4rem; }
  .copyright.last {
    display: none; }
    @media all and (max-width: 767px) {
      .copyright.last {
        display: inline-flex; } }
  @media all and (max-width: 767px) {
    .copyright.first {
      display: none; } }

.link-terms {
  margin-right: 13px; }

.footer-logo-link {
  height: 40px;
  margin-right: auto;
  width: 80%;
  margin-top: 4rem; }
  @media all and (max-width: 767px) {
    .footer-logo-link {
      margin-right: auto; } }

.accordion-item {
  background-color: transparent;
  border: none; }

.btn-whatsapp {
  outline: none !important;
  background: url(../images/bkg/whats.svg) no-repeat;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  position: fixed;
  bottom: 30px;
  right: 40px;
  z-index: 999; }

@media all and (max-width: 767px) {
  #dk-whatsapp {
    display: none; } }

#mb-whatsapp {
  display: none; }
  @media all and (max-width: 767px) {
    #mb-whatsapp {
      display: block !important; } }

.sec-page-banner {
  min-height: 73rem;
  overflow: hidden;
  position: relative; }
  @media all and (max-width: 575px) {
    .sec-page-banner {
      min-height: 466px; } }
  .sec-page-banner .row {
    min-height: 73rem; }
    @media all and (max-width: 575px) {
      .sec-page-banner .row {
        min-height: 466px; } }
  .sec-page-banner .container-fluid {
    max-width: 100%; }
  .sec-page-banner .imgfull {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%; }
    .sec-page-banner .imgfull p {
      margin: 0; }
    .sec-page-banner .imgfull img {
      height: 73rem;
      object-fit: cover;
      object-fit: cover;
      opacity: 0.6;
      width: 100%; }
      @media all and (max-width: 767px) {
        .sec-page-banner .imgfull img {
          height: 960px;
          object-fit: cover;
          width: 100%; } }
      @media all and (max-width: 575px) {
        .sec-page-banner .imgfull img {
          height: 620px; } }
  .sec-page-banner .imgpoints {
    display: none; }

.content-banner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 59px; }
  @media all and (max-width: 575px) {
    .content-banner {
      padding-top: 200px; } }
  .content-banner h1 {
    color: #252233;
    font-size: 7vw;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 7vw;
    margin: 0 auto 10px 0;
    text-transform: uppercase;
    text-align: center; }
    @media all and (min-width: 993px) {
      .content-banner h1 {
        color: #252233;
        font-size: 60px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 60px;
        text-align: left; } }
    @media all and (max-width: 767px) {
      .content-banner h1 {
        font-size: 10vw;
        line-height: 10vw;
        margin-left: auto;
        margin-right: auto; } }
  .content-banner h2 {
    color: #252233;
    font-size: 6vw;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 6vw;
    margin: 0 auto 1rem 0;
    text-transform: uppercase; }
    @media all and (min-width: 993px) {
      .content-banner h2 {
        color: #252233;
        font-size: 50px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 50px; } }
    @media all and (max-width: 575px) {
      .content-banner h2 {
        font-size: 7vw;
        line-height: 7vw;
        margin-left: auto; } }
  .content-banner .button {
    min-height: 40px;
    min-width: 17rem; }
    @media all and (max-width: 575px) {
      .content-banner .button {
        margin: 0 auto; } }
    .content-banner .button.firstbtn {
      margin-right: 43px; }
      @media (max-width: 580px) {
        .content-banner .button.firstbtn {
          margin-right: auto; } }
    .content-banner .button.lastbtn {
      margin-left: 0; }
    @media all and (max-width: 575px) {
      .content-banner .button:last-of-type {
        margin: 32px auto 28px; } }
  @media all and (max-width: 575px) {
    .content-banner p {
      display: flex;
      flex-direction: column; } }

.sec-intro.sec-bg-black .sec-description {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px; }
  @media all and (max-width: 575px) {
    .sec-intro.sec-bg-black .sec-description {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 14px; } }

.sec-intro.sec-bg-white .sec-description {
  color: #252233;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px; }
  @media all and (max-width: 575px) {
    .sec-intro.sec-bg-white .sec-description {
      color: #252233;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 14px; } }

.sec-intro .sec-description {
  margin: 3.2rem 0;
  text-align: center; }
  @media all and (max-width: 575px) {
    .sec-intro .sec-description {
      color: #252233;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center; } }
  .sec-intro .sec-description p {
    margin: 0; }
  .sec-intro .sec-description .button-outline {
    display: inline-flex;
    margin-top: 2.2rem; }

.sec-somos-servicios {
  margin: 6rem 0; }
  .sec-somos-servicios .col-sm-6 p {
    font-size: 14px;
    color: #000; }

.sec-somos-work .item p img {
  width: 100%; }

.sec-somos-work .item h2 {
  font-weight: 600;
  font-size: 30px; }

#somos-servicios-2 {
  margin-top: 4rem; }

#somos-categories {
  order: 2;
  padding: 2rem 0; }
  #somos-categories .item {
    text-align: center; }
  #somos-categories .cat-titles p {
    color: #000; }
  #somos-categories .cat-titles p, #somos-categories .cat-titles a {
    margin: 2rem 0; }
  #somos-categories .cat-titles a {
    color: #C51625;
    text-decoration: underline; }

.sec-somos-work-2 .item p img {
  width: 100%; }

.sec-somos-work-2 .item h2 {
  text-transform: uppercase;
  font-size: 30px; }

.sec-home-intro1 .container-fluid {
  border-bottom: 1px solid #eaebf0; }

.sec-bg-white {
  background: #fff; }

.sec-bg-black {
  background: #000;
  color: #fff; }

.sec-title {
  color: #252233;
  font-size: 39px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 39px;
  margin: 0;
  text-align: center; }

h1,
.sectitle-h1 {
  color: #252233;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px; }
  @media all and (max-width: 575px) {
    h1,
    .sectitle-h1 {
      color: #252233;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px; } }

.content {
  margin-top: 0; }

.sec-description-gral p {
  color: #252233;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px; }

.pg-terms .content ol {
  margin-left: 35px;
  padding-left: 0; }

.pg-terms .content li,
.pg-terms .content p {
  color: #252233;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 24px; }

.pg-default .content {
  margin-top: 130px; }

.pg-default main .container-fluid {
  padding-bottom: 7.5rem; }

.pg-default main strong {
  color: #000;
  display: block;
  font-weight: 900;
  margin: 20px 0 24px 0; }

.pg-default main p {
  color: #252233;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 0 15px 0; }

.pg-default main h2 {
  color: #252233;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px; }

.pg-default main h3 {
  color: #252233;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px; }

.pg-default main h4 {
  color: #252233;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px; }

.pg-default main h5 {
  color: #252233;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 13px; }

.pg-default main h6 {
  color: #252233;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 10px; }

.pg-default.pg-paystamp main p {
  margin: 0;
  text-align: center; }

.pg-default h1 {
  margin-top: 7rem; }

.pg-default.privacy-policy h1 {
  text-align: center; }

.promos-text {
  color: #252233;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: uppercase; }

html .promos-text {
  color: #252233;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 20px; }
  @media (max-width: 580px) {
    html .promos-text {
      text-align: center; } }

.expresate-plasmada p:nth-child(2n) {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  margin: 10px auto 15px; }

.sec-home-intro2.expresate-plasmada p:nth-child(2n) {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  margin: 10px auto 15px; }

.sec-we-intro2.expresate-plasmada p:nth-child(2n) {
  color: #666f80;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  margin: 10px auto 15px; }

.sec-home-intro1.expresate-plasmada p:nth-child(2n) {
  color: #666f80;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  margin: 10px auto 15px; }

.sec-home .content {
  background: #000;
  margin-top: 0; }

.sec-home .mnu-home a {
  font-weight: 800; }

.sec-gral-servicios img {
  height: 440px;
  object-fit: cover; }

.sec-gral-servicios h5 {
  color: #252233;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0 0 3.4rem;
  text-align: center;
  text-transform: uppercase;
  z-index: 5;
  pointer-events: none; }

.sec-gral-servicios p {
  color: #000;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0; }
  .sec-gral-servicios p b {
    margin-bottom: 1rem !important;
    color: #C51625;
    display: block;
    font-weight: 800; }
  .sec-gral-servicios p:last-of-type {
    margin: 0;
    min-height: 96px;
    z-index: 5; }

.sec-gral-servicios .item strong {
  font-weight: bold; }

.sec-gral-servicios .sec-title {
  margin-bottom: 2.8rem; }

.sec-gral-servicios .item a {
  font-size: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 5; }

.no-border .container-fluid {
  border-bottom: 0 !important;
  margin: 3rem auto; }

.sec-home-servicios {
  overflow: hidden;
  padding-top: 4rem;
  margin-bottom: 8rem; }
  .sec-home-servicios .row {
    position: relative; }

@media all and (max-width: 767px) {
  .sec-home-work .container-fluid {
    padding: 0; } }

.sec-home-work p {
  margin: 0; }

.sec-home-work .sec-title {
  color: #000;
  padding: 4.4rem 0; }

.sec-home-work .item {
  position: relative;
  /*&::before {
			background: $black;
			content: "stamp";
			font-size: 0;
			height: 100%;
			left: 0;
			width: 100%;
			position: absolute;
			z-index: 5;
			@include transition-bs-service(0s);
		}*/ }
  .sec-home-work .item.active::before {
    background: rgba(255, 255, 255, 0);
    -webkit-transition: background-color 0s ease-out;
    -moz-transition: background-color 0s ease-out;
    -o-transition: background-color 0s ease-out;
    transition: background-color 0s ease-out; }
  .sec-home-work .item:nth-child(2n)::before {
    -webkit-transition: background-color 0.4s ease-out;
    -moz-transition: background-color 0.4s ease-out;
    -o-transition: background-color 0.4s ease-out;
    transition: background-color 0.4s ease-out; }
  .sec-home-work .item:nth-child(3n)::before {
    -webkit-transition: background-color 0.8s ease-out;
    -moz-transition: background-color 0.8s ease-out;
    -o-transition: background-color 0.8s ease-out;
    transition: background-color 0.8s ease-out; }
  .sec-home-work .item:nth-child(4n)::before {
    -webkit-transition: background-color 1.2s ease-out;
    -moz-transition: background-color 1.2s ease-out;
    -o-transition: background-color 1.2s ease-out;
    transition: background-color 1.2s ease-out; }
  .sec-home-work .item:nth-child(5n)::before {
    -webkit-transition: background-color 1.6s ease-out;
    -moz-transition: background-color 1.6s ease-out;
    -o-transition: background-color 1.6s ease-out;
    transition: background-color 1.6s ease-out; }
  .sec-home-work .item:nth-child(6n)::before {
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out; }
  .sec-home-work .item:nth-child(7n)::before {
    -webkit-transition: background-color 2.4s ease-out;
    -moz-transition: background-color 2.4s ease-out;
    -o-transition: background-color 2.4s ease-out;
    transition: background-color 2.4s ease-out; }
  .sec-home-work .item:nth-child(8n)::before {
    -webkit-transition: background-color 2.8s ease-out;
    -moz-transition: background-color 2.8s ease-out;
    -o-transition: background-color 2.8s ease-out;
    transition: background-color 2.8s ease-out; }
  .sec-home-work .item:nth-child(9n)::before {
    -webkit-transition: background-color 3.2s ease-out;
    -moz-transition: background-color 3.2s ease-out;
    -o-transition: background-color 3.2s ease-out;
    transition: background-color 3.2s ease-out; }
  .sec-home-work .item:nth-child(10n)::before {
    -webkit-transition: background-color 3.6s ease-out;
    -moz-transition: background-color 3.6s ease-out;
    -o-transition: background-color 3.6s ease-out;
    transition: background-color 3.6s ease-out; }
  .sec-home-work .item:nth-child(11n)::before {
    -webkit-transition: background-color 4s ease-out;
    -moz-transition: background-color 4s ease-out;
    -o-transition: background-color 4s ease-out;
    transition: background-color 4s ease-out; }
  .sec-home-work .item:nth-child(12n)::before {
    -webkit-transition: background-color 4.4s ease-out;
    -moz-transition: background-color 4.4s ease-out;
    -o-transition: background-color 4.4s ease-out;
    transition: background-color 4.4s ease-out; }

.sec-home-work img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 384px; }

.bx-right-arrow-alt {
  width: 50px !important; }
  .bx-right-arrow-alt:before {
    content: "" !important;
    background: url(../images/bkg/red-arrow.svg) center top repeat;
    width: 44px;
    height: 40px;
    display: block; }

.sec-carusel .container-fluid {
  position: relative; }

.sec-carusel .carusel-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
  margin-top: 3rem; }
  .sec-carusel .carusel-header a {
    color: #C51625;
    text-decoration: underline; }

.sec-carusel .carusel-floating {
  position: absolute;
  left: -100px;
  transform: rotate(-90deg);
  bottom: 20rem; }
  .sec-carusel .carusel-floating h2 {
    color: #C51625;
    font-size: 16px;
    font-weight: 600;
    width: 200px; }

.sec-home-work {
  margin-bottom: 4rem; }
  .sec-home-work .container-fluid {
    position: relative; }
  .sec-home-work .carusel-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3rem;
    margin-top: 3rem; }
    .sec-home-work .carusel-header a {
      color: #C51625;
      text-decoration: underline; }
  .sec-home-work .carusel-floating {
    position: absolute;
    left: -100px;
    transform: rotate(-90deg);
    bottom: 20rem; }
    .sec-home-work .carusel-floating h2 {
      color: #C51625;
      font-size: 16px;
      font-weight: 600;
      width: 200px; }

#home-work .product .cart-top {
  height: 53px;
  position: absolute;
  right: 1rem;
  top: 0;
  width: 53px; }

.opc:hover {
  opacity: 0.7; }

.wrapper-items.wrapper-items-inhome {
  grid-template-columns: 2fr 1fr 1fr;
  padding-top: 0; }

.sec-carusel .wrapper-items .add-tocart-btn {
  right: 10px; }

.sec-carusel .title-product-prev {
  text-align: left; }

#home-carrusel .swiper-slide .home-slider-slide,
#home-carrusel-1 .swiper-slide .home-slider-slide,
#home-carrusel-2 .swiper-slide .home-slider-slide,
#home-carrusel-3 .swiper-slide .home-slider-slide {
  margin-top: 0;
  position: relative; }

#home-carrusel .swiper-slide .container,
#home-carrusel-1 .swiper-slide .container,
#home-carrusel-2 .swiper-slide .container,
#home-carrusel-3 .swiper-slide .container {
  padding: 0; }

#home-carrusel .swiper-slide .col-lg-4,
#home-carrusel-1 .swiper-slide .col-lg-4,
#home-carrusel-2 .swiper-slide .col-lg-4,
#home-carrusel-3 .swiper-slide .col-lg-4 {
  max-width: 100%;
  width: 100%;
  flex: 100%;
  position: initial !important; }
  #home-carrusel .swiper-slide .col-lg-4 p,
  #home-carrusel-1 .swiper-slide .col-lg-4 p,
  #home-carrusel-2 .swiper-slide .col-lg-4 p,
  #home-carrusel-3 .swiper-slide .col-lg-4 p {
    margin: 0; }
  #home-carrusel .swiper-slide .col-lg-4.content-banner,
  #home-carrusel-1 .swiper-slide .col-lg-4.content-banner,
  #home-carrusel-2 .swiper-slide .col-lg-4.content-banner,
  #home-carrusel-3 .swiper-slide .col-lg-4.content-banner {
    margin-right: 0;
    margin-bottom: 0;
    bottom: 0;
    padding: initial !important; }
    #home-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info,
    #home-carrusel-1 .swiper-slide .col-lg-4.content-banner .ctnt-info,
    #home-carrusel-2 .swiper-slide .col-lg-4.content-banner .ctnt-info,
    #home-carrusel-3 .swiper-slide .col-lg-4.content-banner .ctnt-info {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: repeat(2fr, 1fr);
      text-align: left !important; }
      #home-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info p,
      #home-carrusel-1 .swiper-slide .col-lg-4.content-banner .ctnt-info p,
      #home-carrusel-2 .swiper-slide .col-lg-4.content-banner .ctnt-info p,
      #home-carrusel-3 .swiper-slide .col-lg-4.content-banner .ctnt-info p {
        margin: 0; }
      #home-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title,
      #home-carrusel-1 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title,
      #home-carrusel-2 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title,
      #home-carrusel-3 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title {
        color: #C51625;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        margin: 0.5rem 0;
        text-transform: uppercase; }
        #home-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title a,
        #home-carrusel-1 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title a,
        #home-carrusel-2 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title a,
        #home-carrusel-3 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title a {
          color: #C51625; }
      #home-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info .p-price,
      #home-carrusel-1 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-price,
      #home-carrusel-2 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-price,
      #home-carrusel-3 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-price {
        color: #252233;
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
        margin: 0.5rem 0;
        text-align: right; }
      #home-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info .p-desc,
      #home-carrusel-1 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-desc,
      #home-carrusel-2 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-desc,
      #home-carrusel-3 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-desc {
        color: #74788a;
        font-size: 16px;
        font-weight: 400; }
      #home-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info .p-oldprice,
      #home-carrusel-1 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-oldprice,
      #home-carrusel-2 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-oldprice,
      #home-carrusel-3 .swiper-slide .col-lg-4.content-banner .ctnt-info .p-oldprice {
        color: #74788a;
        font-size: 14px;
        text-align: right;
        text-decoration: line-through; }
    #home-carrusel .swiper-slide .col-lg-4.content-banner .cart-top,
    #home-carrusel-1 .swiper-slide .col-lg-4.content-banner .cart-top,
    #home-carrusel-2 .swiper-slide .col-lg-4.content-banner .cart-top,
    #home-carrusel-3 .swiper-slide .col-lg-4.content-banner .cart-top {
      background-color: #C51625;
      height: 53px;
      position: absolute;
      right: 0;
      top: 0;
      width: 53px; }
  #home-carrusel .swiper-slide .col-lg-4 .button.btn-alt.firstbtn,
  #home-carrusel-1 .swiper-slide .col-lg-4 .button.btn-alt.firstbtn,
  #home-carrusel-2 .swiper-slide .col-lg-4 .button.btn-alt.firstbtn,
  #home-carrusel-3 .swiper-slide .col-lg-4 .button.btn-alt.firstbtn {
    display: none; }

#home-carrusel .swiper-slide img,
#home-carrusel-1 .swiper-slide img,
#home-carrusel-2 .swiper-slide img,
#home-carrusel-3 .swiper-slide img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 384px; }

#blog-carrusel .swiper-slide .home-slider-slide {
  margin-top: 0;
  position: relative; }

#blog-carrusel .swiper-slide .container {
  padding: 0;
  align-items: flex-start; }

#blog-carrusel .swiper-slide .col-lg-4 {
  max-width: 100%;
  width: 100%;
  flex: 100%;
  position: initial !important; }
  #blog-carrusel .swiper-slide .col-lg-4 p {
    margin: 0; }
  #blog-carrusel .swiper-slide .col-lg-4.content-banner {
    margin-right: 0;
    margin-bottom: 0;
    bottom: 0;
    padding: initial !important; }
    #blog-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info {
      text-align: left !important; }
      #blog-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info p {
        margin: 0; }
      #blog-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title {
        color: #C51625;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        margin: 0.5rem 0;
        text-transform: uppercase; }
        #blog-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info .p-title a {
          color: #C51625; }
      #blog-carrusel .swiper-slide .col-lg-4.content-banner .ctnt-info .p-desc {
        color: #74788a;
        font-size: 16px;
        font-weight: 400; }

#blog-carrusel .swiper-slide img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto; }

#blog-carrusel .button-slide .button {
  color: #C51625;
  margin-top: 3rem; }

.page-section.sec-contacto.home-contacto {
  order: 1;
  padding: 0 !important;
  background: url(../images/bkg/subs-bkg.png) no-repeat center;
  background-size: 100%;
  height: 433px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .page-section.sec-contacto.home-contacto .sectitle-h1 {
    font-size: 0; }
    .page-section.sec-contacto.home-contacto .sectitle-h1::before {
      content: "SUSCRIBETE A NUESTRO NEWSLETTER";
      font-weight: 100;
      text-transform: uppercase;
      color: #252233;
      font-size: 30px; }
  .page-section.sec-contacto.home-contacto .content-form {
    width: 100%; }
  .page-section.sec-contacto.home-contacto input:not([type="radio"]):not([type="checkbox"]),
  .page-section.sec-contacto.home-contacto select,
  .page-section.sec-contacto.home-contacto textarea {
    border-bottom: none !important;
    height: 2rem;
    margin: 0 2rem;
    width: auto;
    width: 80%;
    color: #C51625;
    padding: 0.5rem; }
    .page-section.sec-contacto.home-contacto input:not([type="radio"]):not([type="checkbox"])::placeholder,
    .page-section.sec-contacto.home-contacto select::placeholder,
    .page-section.sec-contacto.home-contacto textarea::placeholder {
      color: #C51625 !important;
      opacity: 100 !important;
      font-size: 16px; }
    .page-section.sec-contacto.home-contacto input:not([type="radio"]):not([type="checkbox"]):-ms-input-placeholder,
    .page-section.sec-contacto.home-contacto select:-ms-input-placeholder,
    .page-section.sec-contacto.home-contacto textarea:-ms-input-placeholder {
      color: #C51625 !important;
      opacity: 100 !important;
      font-size: 16px; }
    .page-section.sec-contacto.home-contacto input:not([type="radio"]):not([type="checkbox"])::-ms-input-placeholder,
    .page-section.sec-contacto.home-contacto select::-ms-input-placeholder,
    .page-section.sec-contacto.home-contacto textarea::-ms-input-placeholder {
      color: #C51625 !important;
      opacity: 100 !important;
      font-size: 16px; }
  .page-section.sec-contacto.home-contacto input::placeholder {
    color: #C51625 !important;
    opacity: 100 !important;
    font-size: 16px; }
  .page-section.sec-contacto.home-contacto .form-field {
    margin: 0;
    padding: 0; }
    .page-section.sec-contacto.home-contacto .form-field .form-content {
      border-right: none !important;
      border-color: #000 !important;
      padding: 1rem 0; }
  .page-section.sec-contacto.home-contacto .buttons {
    padding: 1rem 4rem 1rem 0;
    border: 1px solid #000;
    border-left: none;
    width: 16%; }
    .page-section.sec-contacto.home-contacto .buttons .button,
    .page-section.sec-contacto.home-contacto .buttons button {
      margin-bottom: 0;
      padding: 0 5rem; }
  .page-section.sec-contacto.home-contacto .container-fluid {
    background: transparent; }
  .page-section.sec-contacto.home-contacto .sectitle-h1 {
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-weight: 100; }
  .page-section.sec-contacto.home-contacto #contactstamp,
  .page-section.sec-contacto.home-contacto #contactstamp-c {
    padding: 2rem 3rem;
    background: #fff; }
  .page-section.sec-contacto.home-contacto #contactstamp-c {
    display: flex;
    width: 100%;
    margin: auto; }
    .page-section.sec-contacto.home-contacto #contactstamp-c .form-field {
      width: 41.66667%;
      max-width: 41.66667%; }
      .page-section.sec-contacto.home-contacto #contactstamp-c .form-field.col-md-12 {
        width: 100%;
        max-width: 100%; }
      .page-section.sec-contacto.home-contacto #contactstamp-c .form-field.field-subject, .page-section.sec-contacto.home-contacto #contactstamp-c .form-field.field-phone {
        display: none; }
      .page-section.sec-contacto.home-contacto #contactstamp-c .form-field .form-content {
        border: 1px solid #000;
        padding: 1.1rem 0; }
    .page-section.sec-contacto.home-contacto #contactstamp-c .buttons {
      margin-bottom: 0; }

.home-container .content {
  display: flex;
  flex-direction: column; }

.sec-home-intro1 .sec-description p:first-of-type {
  color: #666f80;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 10px; }
  @media all and (max-width: 575px) {
    .sec-home-intro1 .sec-description p:first-of-type {
      color: #666f80;
      font-size: 14px;
      font-weight: 100;
      letter-spacing: 0;
      line-height: 24px; } }

.sec-home-intro2 {
  margin-top: 4.7rem; }
  .sec-home-intro2 .button-white {
    margin-top: 3rem;
    min-width: 16.4rem; }
    .sec-home-intro2 .button-white:hover {
      border: solid 2px #fff; }

.sec-page-banner h1,
.sec-page-banner h2 {
  opacity: 0; }
  .sec-page-banner h1.active,
  .sec-page-banner h2.active {
    opacity: 1; }

.sec-home-banner .button {
  padding-top: 8px; }

.sec-slider {
  overflow: hidden;
  position: relative; }
  .sec-slider .bgw {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5; }
  .sec-slider.sec-carusel .container-fluid {
    max-width: 1248px;
    padding-right: 15px;
    padding-left: 15px; }
  .sec-slider .container-fluid {
    max-width: 100%;
    padding: 0;
    width: 100%; }
  .sec-slider .container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    right: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%; }
    .sec-slider .container .home-slider-slide {
      margin-top: auto;
      padding-bottom: 1.7rem;
      width: 100%; }
      @media all and (max-width: 575px) {
        .sec-slider .container .home-slider-slide {
          width: 90%; } }
    .sec-slider .container .button-slide {
      margin-left: 0;
      min-height: 50px;
      position: absolute;
      right: 0; }
      @media all and (max-width: 575px) {
        .sec-slider .container .button-slide {
          min-height: 150px; } }
      @media (max-width: 580px) {
        .sec-slider .container .button-slide {
          min-height: 150px;
          width: 100%; } }

#home-slider .swiper-container {
  height: 70vh;
  max-width: 100%; }

#product-slider-h.swiper-container,
#product-slider-h-1.swiper-container,
#product-slider-h-2.swiper-container,
#product-slider-h-3.swiper-container {
  height: 500px;
  max-width: 100%; }

.swiper-slide {
  background-repeat: no-repeat;
  background-size: cover; }
  .swiper-slide:first-of-type .erdbtn {
    display: none; }
  .swiper-slide:first-of-type .orthbtn {
    display: none; }
  @media all and (max-width: 991px) {
    .swiper-slide:nth-child(1n) {
      background-position: 80% center; } }
  .swiper-slide:nth-child(1n) .spoty {
    display: none; }
  .swiper-slide:nth-child(1n) .lastbtn {
    display: none; }
  @media all and (max-width: 991px) {
    .swiper-slide:nth-child(2n) {
      background-position: 80% center; } }
  .swiper-slide:nth-child(2n) .erdbtn {
    display: none; }
  .swiper-slide:nth-child(2n) .lastbtn {
    display: none; }
  .swiper-slide:nth-child(2n) .orthbtn {
    display: none; }
  .swiper-slide:nth-child(2n) .spoty {
    display: inline-flex;
    height: 73px;
    width: 170px; }
  .swiper-slide:nth-child(2n) .nospoty {
    display: none; }
  .swiper-slide:nth-child(2n) .content-banner {
    display: block !important; }
    .swiper-slide:nth-child(2n) .content-banner .button:last-of-type {
      margin-left: auto;
      margin-right: auto; }
  @media all and (max-width: 991px) {
    .swiper-slide:nth-child(3n) {
      background-position: 80% center; } }
  .swiper-slide:nth-child(3n) .firstbtn {
    display: none; }
  .swiper-slide:nth-child(3n) .erdbtn {
    display: none; }
  .swiper-slide:nth-child(3n) .orthbtn {
    display: none; }
  .swiper-slide:nth-child(3n) .spoty {
    display: none; }
  @media all and (max-width: 991px) {
    .swiper-slide:nth-child(4n) {
      background-position: 85% center; } }
  .swiper-slide:nth-child(4n) .firstbtn {
    display: none; }
  .swiper-slide:nth-child(4n) .erdbtn {
    display: none; }
  .swiper-slide:nth-child(4n) .lastbtn {
    display: none; }
  .swiper-slide:nth-child(4n) .orthbtn {
    display: inline-flex; }
  .swiper-slide:nth-child(4n) .spoty {
    display: none; }
  @media all and (max-width: 991px) {
    .swiper-slide:nth-child(5n) {
      background-position: 80% center; } }
  .swiper-slide:nth-child(5n) .firstbtn {
    display: none; }
  .swiper-slide:nth-child(5n) .erdbtn {
    display: none; }
  .swiper-slide:nth-child(5n) .orthbtn {
    display: none; }
  .swiper-slide:nth-child(5n) .spoty {
    display: none; }
  .swiper-slide.center-slde-home .content-banner {
    display: block !important; }
    .swiper-slide.center-slde-home .content-banner h2 {
      margin-left: 0;
      margin-right: 0;
      text-align: left; }

.swiper-pagination {
  pointer-events: fill; }

.button {
  padding: 0;
  pointer-events: fill; }

.content-banner h2 {
  text-align: left; }

.col-lg-4.content-banner {
  background: transparent !important;
  bottom: 5em;
  display: block !important;
  margin-right: 7em;
  padding: 1.5em !important;
  position: absolute;
  right: 0;
  width: 39em; }
  .col-lg-4.content-banner h2,
  .col-lg-4.content-banner h3,
  .col-lg-4.content-banner p {
    color: #fff;
    text-align: left; }
  .col-lg-4.content-banner h2 {
    font-size: 30px;
    font-weight: 100;
    line-height: 37px;
    text-transform: uppercase; }
  .col-lg-4.content-banner h3,
  .col-lg-4.content-banner p {
    font-size: 16px;
    line-height: 19px;
    font-weight: 100; }
  .col-lg-4.content-banner .button-slide .button {
    background: transparent;
    border: 1px solid #C51625;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 100; }

#topSectionBlog .button {
  background: transparent;
  border: 1px solid #C51625;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 100; }

#home-slider .swiper-wrapper .swiper-slide-active:before {
  content: "";
  position: absolute;
  left: 0;
  width: 120px;
  background-color: #C51625;
  height: 100%;
  z-index: 6; }

#home-slider .swiper-button-next {
  left: 0;
  right: auto;
  bottom: 0;
  top: auto; }
  #home-slider .swiper-button-next:before {
    background: url(../images/bkg/next.svg) no-repeat center; }

#home-slider .swiper-button-prev {
  bottom: 4rem;
  top: auto; }
  #home-slider .swiper-button-prev:before {
    background: url(../images/bkg/prev.svg) no-repeat center; }

#home-slider .swiper-button-next,
#home-slider .swiper-button-prev {
  display: block;
  height: 43px;
  width: 43px;
  cursor: pointer;
  left: 3rem; }
  #home-slider .swiper-button-next:before,
  #home-slider .swiper-button-prev:before {
    content: "";
    width: 44px;
    height: 44px;
    display: block; }

.wrapper-ctbanner {
  position: absolute;
  z-index: 8;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%; }

.iconos-pasos {
  display: flex;
  margin-left: 0;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  justify-content: space-between;
  margin-top: 20px; }
  .iconos-pasos .items {
    display: flex;
    flex-direction: column;
    margin-right: 5px; }
  .iconos-pasos img {
    margin: auto 3rem auto 0; }

.items.itm3 img {
  position: relative;
  top: 5px; }

.txt-vert {
  transform: rotate(180deg);
  writing-mode: vertical-rl; }

.ctnt-link {
  display: flex;
  justify-content: flex-end;
  padding: 3em 0 2em; }
  .ctnt-link .link-ref {
    color: #74788a;
    margin-right: 0.5em; }

.product .cart-top {
  height: 53px;
  right: 15px;
  top: 0;
  width: 53px; }

.product .ctnt-info {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(2, 1fr); }
  .product .ctnt-info .p-title {
    color: #C51625;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    margin: 0.5rem 0;
    text-transform: uppercase; }
  .product .ctnt-info .p-price {
    color: #252233;
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
    margin: 0.5rem 0;
    text-align: right; }
  .product .ctnt-info .p-desc {
    color: #74788a;
    font-size: 16px;
    font-weight: 400; }
  .product .ctnt-info .p-oldprice {
    color: #74788a;
    font-size: 14px;
    text-align: right;
    text-decoration: line-through; }

.call-to-action .action {
  background-color: #f6f7fc;
  padding: 2.5em 0 1.5em; }

.call-to-action .ctnt-action {
  position: relative;
  padding: 0 2rem; }

.call-to-action .a-title {
  font-size: 14px;
  color: #000;
  font-weight: 800; }

.call-to-action .a-txt {
  font-size: 14px;
  color: #000; }

.call-to-action .btn-red {
  margin-left: auto;
  display: block;
  padding: 1rem 2rem; }

/*
 *
 * Se usan # para la manipulación de ciertos contenedores
 *
*/
#categoriesHome {
  padding: 3rem 0;
  order: 2; }
  #categoriesHome .col-6 {
    text-align: center; }
  #categoriesHome .cat-titles {
    margin-bottom: 2rem; }
    #categoriesHome .cat-titles p {
      margin: 0 !important;
      color: #000; }

.sec-home-intro1 .sec-description h1 {
  text-transform: uppercase;
  font-weight: 100; }

.sec-home-intro1 .sec-description p {
  line-height: 1.3 !important; }

.sec-home-categories a {
  color: #C51625;
  text-decoration: underline; }

.sec-home-categories .item p {
  color: #000; }

#bannerHome {
  order: 3; }

.sec-home-intro2 {
  margin-top: 6rem;
  margin-bottom: 6rem; }
  .sec-home-intro2 .item p {
    display: flex;
    justify-content: center;
    align-items: center; }

.sec-home-intro3 {
  margin-bottom: 4rem; }

#serviciosHome {
  order: 4; }

#callToAction {
  order: 5; }

.sec-home.call-to-action {
  background: #f6f7fc; }

@media all and (max-width: 991px) {
  .pg-form-gral .content  .row {
    justify-content: center; } }

.pg-form-gral .content  .txt {
  margin-right: auto; }
  .pg-form-gral .content  .txt.in-il input {
    border: 0;
    color: #000;
    outline: 0;
    padding-left: 8px; }

.pg-form-gral .content  .textarea textarea {
  border: 1px solid #e1e1e1;
  min-height: 136px;
  outline: 0;
  padding-top: 13px; }
  .pg-form-gral .content  .textarea textarea:focus {
    border-bottom-width: 1px; }

.pg-form-gral .content-form.right-card {
  background: #fff;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.27);
  height: 435px;
  max-width: 449px;
  order: 1;
  padding: 2rem; }
  .pg-form-gral .content-form.right-card .contact-form {
    margin: 0; }
  .pg-form-gral .content-form.right-card .buttons {
    margin: 0;
    position: relative; }
    .pg-form-gral .content-form.right-card .buttons .btn-spirit {
      left: 0;
      margin: 0;
      position: absolute; }

.pg-form-gral .content-form .form-content {
  padding: 0; }

.pg-form-gral .content-form .form-field:not(.form-description) {
  font-weight: 100;
  text-transform: uppercase; }
  .pg-form-gral .content-form .form-field:not(.form-description) span {
    color: #C51625; }

.pg-form-gral .content-form label {
  color: #6d6d6d;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 24px;
  left: 2.4rem;
  text-transform: uppercase; }

.pg-form-gral .content-form input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label,
.pg-form-gral .content-form select.invalid:not(:focus) + label,
.pg-form-gral .content-form select:invalid:not(:focus) + label,
.pg-form-gral .content-form textarea:placeholder-shown:not(:focus) + label {
  top: 0; }

.pg-form-gral .content-form input:not([type="radio"]):not([type="checkbox"]) + label,
.pg-form-gral .content-form select + label,
.pg-form-gral .content-form textarea + label {
  left: 2.4rem;
  padding-left: 0;
  top: 0;
  font-size: 14px;
  color: #000; }

.pg-form-gral .content-form input:not([type="radio"]):not([type="checkbox"]):focus + label,
.pg-form-gral .content-form select:focus + label,
.pg-form-gral .content-form textarea:focus + label {
  font-size: 12px;
  left: 24px;
  top: -6px; }

.pg-form-gral .content-form input:not([type="radio"]):not([type="checkbox"]).invalid:not(:focus):not(:placeholder-shown),
.pg-form-gral .content-form input:not([type="radio"]):not([type="checkbox"]):invalid:not(:focus):not(:placeholder-shown),
.pg-form-gral .content-form textarea.invalid:not(:focus):not(:placeholder-shown),
.pg-form-gral .content-form textarea:invalid:not(:focus):not(:placeholder-shown) {
  border-bottom-color: #e1e1e1; }

.pg-contacto .bkg-contacto {
  background-size: cover;
  height: 435px; }
  .pg-contacto .bkg-contacto .container-fluid {
    max-width: 100% !important;
    padding: 0; }
    .pg-contacto .bkg-contacto .container-fluid #gmap_canvas, .pg-contacto .bkg-contacto .container-fluid .gmap_canvas, .pg-contacto .bkg-contacto .container-fluid .mapouter {
      width: 100%;
      height: 435px; }

.pg-contacto .textarea {
  margin-top: 15px; }

.pg-contacto .form-description {
  color: #252233;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px; }
  .pg-contacto .form-description.mb5 {
    margin-bottom: 15px; }

@media all and (min-width: 577px) {
  .pg-contacto .content  .txt {
    max-width: 43.6rem; } }

@media all and (min-width: 769px) {
  .pg-contacto .contact-form {
    margin: 0 10% 0 20%; } }

@media all and (max-width: 991px) {
  .pg-contacto .contact-form {
    margin-left: auto;
    margin-right: auto; } }

.pg-contacto form[name="contactPage"] {
  background-color: transparent;
  padding: 1em 0.5em;
  width: 100%; }
  .pg-contacto form[name="contactPage"] .form-field {
    margin: 0;
    padding: 0; }
    .pg-contacto form[name="contactPage"] .form-field .form-content:nth-child(2) {
      background-color: #e1e1e1; }
    .pg-contacto form[name="contactPage"] .form-field .form-content select,
    .pg-contacto form[name="contactPage"] .form-field .form-content input {
      border-style: solid;
      height: 3em;
      padding: 1em 0 0 0.4em;
      width: 100%; }
    .pg-contacto form[name="contactPage"] .form-field .form-content label {
      left: 1em;
      position: absolute;
      top: 0 !important; }

.pg-contacto .content-al {
  order: 2;
  padding-top: 6.7rem; }
  @media all and (max-width: 767px) {
    .pg-contacto .content-al {
      padding-top: 4.8rem; } }

.pg-contacto .sectitle-h1 {
  margin-bottom: 2.2rem; }

.pg-contacto .buttons {
  margin-bottom: 4rem;
  padding-top: 0; }
  @media all and (min-width: 577px) {
    .pg-contacto .buttons {
      margin-bottom: 9rem;
      margin-right: auto;
      width: 43.6rem; } }
  @media all and (max-width: 991px) {
    .pg-contacto .buttons {
      margin-bottom: 0; } }

.pg-contacto .button {
  margin: 0 5.5rem 0 auto;
  min-width: 16rem; }

.pg-contacto .gralmsgsend {
  display: none;
  flex-direction: column;
  margin-bottom: 5rem;
  text-align: center; }
  .pg-contacto .gralmsgsend.sendsuccess {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-name: zoomInLeft;
    animation-name: zoomInLeft;
    display: flex; }
  .pg-contacto .gralmsgsend h3 {
    color: #252233;
    font-size: 30px;
    font-weight: 100;
    letter-spacing: 0;
    line-height: 30px;
    margin: 0 auto 2rem auto; }
    .pg-contacto .gralmsgsend h3::before {
      background: rgba(216, 216, 216, 0.4) url(../images/bkg/icons-check.png) no-repeat center;
      background-size: contain;
      content: "stamp";
      display: block;
      font-size: 0;
      height: 45px;
      margin: 1rem auto 2.2rem;
      text-align: center;
      width: 45px; }
  .pg-contacto .gralmsgsend h4 {
    color: #252233;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0 auto 4.5rem;
    width: 65%; }
    @media all and (max-width: 575px) {
      .pg-contacto .gralmsgsend h4 {
        width: 90%; } }
  .pg-contacto .gralmsgsend .link-default {
    color: #252233;
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 0;
    line-height: 18px;
    display: block;
    text-decoration: underline; }
  .pg-contacto .gralmsgsend .button-outline {
    margin: 0 auto 1.2rem auto;
    width: 245px; }

.cinfo {
  margin-bottom: 4.2rem; }
  @media all and (max-width: 991px) {
    .cinfo {
      margin-left: auto;
      margin-right: auto;
      width: 452px; } }
  @media all and (max-width: 767px) {
    .cinfo {
      margin-bottom: 2.5rem;
      width: 100%; } }
  .cinfo h6 {
    color: #252233;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0 0 16px 0; }
    .cinfo h6.mtb-wt {
      margin-top: 30px; }
  .cinfo p {
    color: #252233;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0; }
  .cinfo.last h6 {
    margin-bottom: 0.5rem; }
  .cinfo.item2 {
    margin-top: 7rem; }
    @media all and (max-width: 767px) {
      .cinfo.item2 {
        margin-top: 4rem; } }
  .cinfo.item3 {
    margin-top: 8.3rem; }
    @media all and (max-width: 767px) {
      .cinfo.item3 {
        margin-top: 4rem; } }
  .cinfo.last {
    margin-bottom: 11.5rem; }

.form-description {
  color: #252233;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 4rem; }
  @media all and (max-width: 767px) {
    .form-description {
      margin-bottom: 5rem; } }

.pg-contacto .page-section.sec-contacto input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus) + label {
  top: 18px !important; }

.pg-contacto .page-section.sec-contacto input:not([type="radio"]):not([type="checkbox"]) {
  padding: 2rem 0.4rem 0 !important; }

#contactstamp-c input:not([type="radio"]):not([type="checkbox"]), #contactstamp-c select, #contactstamp-c textarea {
  border: none;
  border-bottom: 0;
  color: #C51625;
  font-size: 16px;
  height: auto;
  padding: 2rem 2.4rem 0;
  outline: none; }
  #contactstamp-c input:not([type="radio"]):not([type="checkbox"])::placeholder, #contactstamp-c select::placeholder, #contactstamp-c textarea::placeholder {
    color: #C51625 !important;
    opacity: 100 !important;
    font-size: 16px; }

#infoContact {
  margin-top: -10rem;
  margin-bottom: 6rem; }
  #infoContact a {
    display: flex;
    column-gap: 0.5rem;
    color: #000; }
  #infoContact .info-contact-ico-first p img {
    width: 46px; }

.pg-contacto #contactstamp {
  margin: 0; }
  .pg-contacto #contactstamp .buttons {
    margin: 0; }

.mb14 {
  margin-bottom: 14rem; }

.info-contact-ico {
  display: flex;
  column-gap: 1rem;
  justify-content: flex-start; }
  .info-contact-ico p {
    margin: 0; }

.services-contact .item p {
  font-size: 14px;
  color: #000; }

.services-contact .item b {
  color: #C51625;
  height: 50px !important;
  display: block; }

.sec-contacto-c {
  margin-top: -30rem; }
  .sec-contacto-c .form-field {
    border: 1px solid #000;
    border-bottom: 0;
    margin-bottom: 0 !important;
    padding: 1rem 0; }
    .sec-contacto-c .form-field.sectitle {
      border: none;
      margin-bottom: 2rem !important;
      padding: 0; }
    .sec-contacto-c .form-field.last-field {
      border-bottom: 1px solid #000 !important; }
  .sec-contacto-c .buttons {
    display: block; }
    .sec-contacto-c .buttons .btn-red {
      margin-left: 0;
      margin-top: 2rem; }
  .sec-contacto-c .container-fluid {
    display: flex;
    justify-content: flex-end; }
    @media all and (max-width: 767px) {
      .sec-contacto-c .container-fluid {
        padding-left: 15px;
        padding-right: 15px; } }
    @media all and (max-width: 575px) {
      .sec-contacto-c .container-fluid {
        padding-top: 8rem; } }
  .sec-contacto-c .links-icons {
    padding: 1em 0 5em; }
    .sec-contacto-c .links-icons p {
      align-items: center;
      display: flex; }
    .sec-contacto-c .links-icons i.bx {
      margin-right: 1em; }
    .sec-contacto-c .links-icons a {
      color: #2f2f2f;
      font-size: 16px;
      font-weight: 200;
      line-height: 19px;
      margin-left: 1em;
      text-decoration: none; }
      .sec-contacto-c .links-icons a:hover {
        color: #d2d2d2;
        text-decoration: underline; }
  .sec-contacto-c .info-text {
    padding: 5em 0; }
  .sec-contacto-c .wrapper-social {
    align-items: center;
    display: inline-flex; }
  .sec-contacto-c .isocial {
    display: inline-flex;
    font-size: 0; }
  .sec-contacto-c .i-fa {
    background: url(../images/bkg/contact-svg-facebook-1.svg) no-repeat center;
    height: 27px;
    margin-right: 3.7rem;
    width: 28px; }
  .sec-contacto-c .i-tw {
    background: url(../images/bkg/contact-sv-twitter-1.svg) no-repeat center;
    height: 25px;
    width: 27px;
    margin-right: 3.7rem; }
  .sec-contacto-c .i-ins {
    background: url(../images/bkg/insta-black.svg) no-repeat center;
    height: 25px;
    width: 27px;
    background-size: 48px; }

.in-il {
  color: #000; }
  .in-il .form-content {
    align-items: center;
    border: 1px solid #e1e1e1;
    min-height: 48px; }
  .in-il input:not([type="radio"]):not([type="checkbox"])::placeholder,
  .in-il select::placeholder,
  .in-il textarea::placeholder {
    opacity: 0; }
  .in-il label {
    padding-left: 2.4rem;
    text-transform: uppercase;
    font-size: 14px; }
  .in-il input {
    border: 0; }

.sec-we-intro1 {
  padding-top: 5.5rem;
  padding-bottom: 4rem; }
  .sec-we-intro1 p {
    width: 85%; }

.pg-tees-default .sec-description-gral h1,
.pg-tees-default .sec-description-gral h2,
.pg-tees-default .sec-description-gral h3,
.pg-tees-default .sec-description-gral h4,
.pg-tees-default .sec-description-gral h5,
.pg-tees-default .sec-description-gral h6 {
  margin-bottom: 20px;
  color: #252233;
  font-size: 39px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px; }
  @media all and (max-width: 767px) {
    .pg-tees-default .sec-description-gral h1,
    .pg-tees-default .sec-description-gral h2,
    .pg-tees-default .sec-description-gral h3,
    .pg-tees-default .sec-description-gral h4,
    .pg-tees-default .sec-description-gral h5,
    .pg-tees-default .sec-description-gral h6 {
      margin-left: 0; } }
  @media all and (max-width: 575px) {
    .pg-tees-default .sec-description-gral h1,
    .pg-tees-default .sec-description-gral h2,
    .pg-tees-default .sec-description-gral h3,
    .pg-tees-default .sec-description-gral h4,
    .pg-tees-default .sec-description-gral h5,
    .pg-tees-default .sec-description-gral h6 {
      color: #252233;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px; } }

.pg-nosotros h1 {
  margin-bottom: 20px; }
  @media all and (max-width: 767px) {
    .pg-nosotros h1 {
      margin-left: 0; } }

@media all and (max-width: 767px) {
  .pg-nosotros .sec-gral-servicios .container-fluid {
    padding: 0; } }

.pg-nosotros .content-banner h2 {
  font-size: 30px;
  font-weight: 100;
  text-transform: uppercase;
  color: #fff; }
  .pg-nosotros .content-banner h2 span {
    color: #C51625; }

.pg-nosotros .content-banner p {
  margin: 5px auto 43px 0; }

.pg-nosotros .sec-description-gral p {
  margin: 0 0 35px; }

.pg-nosotros .sec-intro .sec-description p:first-of-type {
  color: #666f80;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 10px; }
  @media all and (max-width: 575px) {
    .pg-nosotros .sec-intro .sec-description p:first-of-type {
      color: #666f80;
      font-size: 17px;
      font-weight: 100;
      letter-spacing: 0;
      line-height: 24px; } }

.sec-nosotros-servicios p:last-of-type {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80%; }
  @media all and (min-width: 769px) {
    .sec-nosotros-servicios p:last-of-type {
      width: 26.3rem; } }

.sec-we-intro2 {
  padding-top: 6.6rem; }
  .sec-we-intro2 .button-outline {
    margin: 3.7rem 0 9rem 0; }

.pg-store .opc-tipopersona {
  display: none; }

.pg-store .content {
  margin-top: 70px; }
  @media all and (max-width: 991px) {
    .pg-store .content {
      margin-top: 48px; } }
  @media all and (max-width: 767px) {
    .pg-store .content {
      margin-top: 46px; } }

.pg-store .wraper-img {
  background: #dbdbdb;
  height: 380px;
  margin-bottom: 10px; }
  .pg-store .wraper-img img {
    height: 380px;
    object-fit: cover;
    width: 100%; }

@media all and (min-width: 769px) {
  .pg-store .filter-product  {
    display: none; } }

.pg-store .filter-product {
  border: solid 1px #e1e1e1;
  min-height: 48px; }
  .pg-store .filter-product option {
    color: #6d6d6d; }

@media all and (max-width: 767px) {
  .pg-store .sec-page-banner.banner-store .imgfull img {
    height: auto; } }

.wrapper-filter-product {
  padding: 0 12px; }

.store-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0 0 0 28px; }
  .store-nav li {
    list-style: none;
    min-width: 110px; }
    .store-nav li::before, .store-nav li::before {
      content: none; }

.wrapper-store-nav {
  background: #000; }
  @media all and (max-width: 767px) {
    .wrapper-store-nav {
      display: none; } }
  .wrapper-store-nav a {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 24px;
    align-items: center;
    display: flex;
    min-height: 44px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase; }
    .wrapper-store-nav a:hover, .wrapper-store-nav a.active {
      font-weight: bold; }

.store-list {
  padding-bottom: 9.6rem; }
  @media all and (min-width: 769px) {
    .store-list {
      display: block; } }
  @media (min-width: 768px) and (max-width: 1247.98px) {
    .store-list {
      grid-template-columns: 3fr 8.5fr 0.5fr; } }
  @media all and (max-width: 991px) {
    .store-list {
      flex-direction: column; } }

.store-left {
  background: #fff;
  padding: 2rem 0; }
  .store-left .grid-filters {
    display: flex;
    column-gap: 2rem; }
    .store-left .grid-filters .wrapper-cat-product {
      display: flex;
      justify-content: flex-start;
      column-gap: 2rem; }
      .store-left .grid-filters .wrapper-cat-product li {
        width: 208px; }
    .store-left .grid-filters ul {
      margin: 0; }
  .store-left h3 {
    color: #252233;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 16px;
    text-transform: uppercase; }
  .store-left li {
    list-style: none;
    overflow: hidden; }
    .store-left li::after, .store-left li::before {
      display: none; }
  .store-left .nav-item {
    margin-bottom: 1rem;
    text-align: center; }
  .store-left .nav-link {
    color: #292c33;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: uppercase; }
  .store-left .nav {
    display: none; }
    @media all and (min-width: 767px) {
      .store-left .nav {
        display: block;
        margin-right: 4rem; } }
  .store-left .arrow-i {
    -webkit-transition: -webkit-transform .1s ease-in-out;
    -ms-transition: -ms-transform .1s ease-in-out;
    transform: rotate(90deg);
    transition: transform .1s ease-in-out;
    background: url(../images/bkg/arrow-mnu.png) no-repeat center;
    background-size: auto 15px;
    background-position: -18px -3px;
    display: block;
    height: 12px;
    width: 12px;
    margin-right: 1rem; }
  .store-left .submenu {
    display: none;
    margin: 0.3rem 0 0 0 !important;
    overflow: hidden;
    transition: height 0.15s ease-out;
    padding: 0;
    border: 1px solid #ddd; }
    .store-left .submenu .nav-item {
      text-align: left; }
      .store-left .submenu .nav-item .nav-link {
        font-size: 12px;
        text-transform: inherit; }
  .store-left .nav-link-parent {
    color: #292c33;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 16px;
    align-items: center;
    background: #fff;
    border: 1px solid #000;
    display: flex;
    justify-content: space-between;
    min-height: 41px;
    text-decoration: none;
    transition: .2s ease-in-out; }
  .store-left .nav-item-parent .stext {
    margin: auto 1rem;
    transition: color 0.25s ease-out; }
  .store-left .nav-item-parent.active .stext {
    margin: 0 1rem; }
  .store-left .nav-item-parent.active .submenu {
    display: block; }
  .store-left .nav-item-parent.active .arrow-i {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    background-position: -5px center; }
  .store-left .nav-item-parent.active .nav-link-parent {
    font-weight: 400;
    position: relative;
    z-index: 5; }

.wrapper-items {
  display: grid;
  padding-top: 2.8rem; }
  @media all and (min-width: 769px) {
    .wrapper-items {
      grid-gap: 4.6rem 2rem;
      grid-template-columns: repeat(4, 1fr);
      padding-top: 9.8rem; } }
  @media all and (max-width: 991px) {
    .wrapper-items {
      grid-gap: 4.6rem 2rem;
      grid-template-columns: repeat(3, 2fr); } }
  @media all and (max-width: 575px) {
    .wrapper-items {
      grid-gap: 16px 0;
      grid-template-columns: repeat(2, 50%); } }
  .wrapper-items .product-desc {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  .wrapper-items .add-tocart-btn {
    position: absolute;
    text-indent: -9999px;
    right: 0;
    background: #C51625 url(../images/bkg/icon-cart-square.svg) center no-repeat;
    white-space: nowrap;
    text-indent: -9999px;
    width: 53px;
    height: 53px; }
  .wrapper-items .item {
    position: relative; }
    .wrapper-items .item.guitarras, .wrapper-items .item.pedales, .wrapper-items .item.accesorios, .wrapper-items .item.cuerdas {
      display: none; }
    .wrapper-items .item h3 {
      color: #C51625;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 2px;
      line-height: auto;
      margin: 0;
      text-transform: uppercase; }
    .wrapper-items .item .product-description p {
      margin: 0;
      font-weight: 100;
      font-size: 16px; }
    .wrapper-items .item .mindescription {
      color: #252233;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 24px;
      padding: 0 9px; }
      .wrapper-items .item .mindescription * {
        color: #252233;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 2px;
        line-height: 24px; }
    .wrapper-items .item p {
      color: #252233;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: auto;
      margin: 1.5rem 0 2.4rem 9px; }
      .wrapper-items .item p.rate {
        margin: 0;
        padding-top: 0; }
    .wrapper-items .item:hover::before, .wrapper-items .item:hover::after {
      width: 100%; }
    .wrapper-items .item:hover .link-item::before, .wrapper-items .item:hover .link-item::after {
      height: 100%; }
    .wrapper-items .item::before {
      left: 50%;
      top: 0;
      transition-duration: 0.4s; }
    .wrapper-items .item::after {
      bottom: 0;
      left: 50%;
      transition-duration: 0.4s; }
    .wrapper-items .item .link-item::before {
      left: 0;
      top: 50%;
      transition-duration: 0.4s; }
    .wrapper-items .item .link-item::after {
      right: 0;
      top: 50%;
      transition-duration: 0.4s; }
    .wrapper-items .item:hover::before, .wrapper-items .item:hover::after {
      left: 0; }
    .wrapper-items .item:hover .link-item::before, .wrapper-items .item:hover .link-item::after {
      top: 0; }
  .wrapper-items .link-item {
    height: 100%;
    display: block; }
    .wrapper-items .link-item:hover {
      text-decoration: none; }

.wrapper-store-nav-mb {
  align-items: center;
  border: 1px solid #e1e1e1;
  display: flex;
  justify-content: flex-start;
  min-height: 48px;
  padding-left: 10px; }
  @media all and (min-width: 769px) {
    .wrapper-store-nav-mb {
      display: none; } }
  .wrapper-store-nav-mb .store-nav-mb {
    color: #252233;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 24px;
    border: 0;
    height: auto;
    outline: none; }
    .wrapper-store-nav-mb .store-nav-mb option {
      color: #252233;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 2px;
      line-height: 24px; }

h3.style-nav-select-mb {
  color: #6d6d6d;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-transform: uppercase; }

.store-cat-style-bebes .submenu li:first-of-type {
  display: none; }

.sec-page-banner.banner-store {
  top: 0;
  position: static;
  top: 0;
  min-height: auto; }
  .sec-page-banner.banner-store .content-banner {
    padding-top: 0; }
  .sec-page-banner.banner-store .imgfull {
    position: relative; }
    .sec-page-banner.banner-store .imgfull img {
      height: auto;
      opacity: 1; }
  .sec-page-banner.banner-store .row {
    min-height: auto;
    height: auto; }
  .sec-page-banner.banner-store .content-banner {
    justify-content: center;
    margin-bottom: 0; }
    .sec-page-banner.banner-store .content-banner h1 {
      font-size: 40px;
      line-height: 40px; }
    .sec-page-banner.banner-store .content-banner h2 {
      font-size: 25px;
      line-height: 25px; }

.video-tienda {
  width: 100%;
  height: 340px;
  text-align: center; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

@media all and (max-width: 575px) {
  #store-dk-video {
    display: none; } }

#store-mb-video {
  display: none; }
  @media all and (max-width: 575px) {
    #store-mb-video {
      display: block; } }
  #store-mb-video .embed-responsive-item {
    width: 100%; }

.font-size-default p,
.font-size-defaultfont p {
  font-size: 30px;
  line-height: 30px; }

.font-size-extrachica p {
  font-size: 34px;
  line-height: 34px; }

.font-size-chica p {
  font-size: 24px;
  line-height: 24px; }

.font-size-mediana p {
  font-size: 40px;
  line-height: 40px; }

.font-size-grande p {
  font-size: 46px;
  line-height: 46px; }

.font-size-extragrande p {
  font-size: 50px;
  line-height: 50px; }

.the-first img {
  height: 550px; }

.btn-red, .footer .btn-red {
  background: #C51625;
  text-transform: initial;
  font-size: 16px;
  font-weight: 100; }

.modal-body .btn-red {
  margin: 0 !important; }

.categories-top {
  background: url(../images/bkg/bkg-guitar.png) center top repeat;
  display: flex;
  align-items: flex-end;
  height: 435px;
  margin-top: 90px; }
  .categories-top h3 {
    font-size: 16px;
    color: #C51625;
    margin: 0; }
  .categories-top h1 {
    color: #fff;
    margin-top: 0;
    font-weight: 100;
    text-transform: uppercase; }
    .categories-top h1 span {
      color: #C51625; }
  .categories-top p {
    color: #fff;
    margin-top: 0.5rem; }

#paladin-page-bkg {
  background: #fff url(../images/bkg/paladin-page-bkg.png) center top repeat;
  min-height: 500px;
  background-size: contain;
  background-repeat: no-repeat; }

.pg-single-product #product-top {
  height: 435px;
  margin-top: 90px; }
  .pg-single-product #product-top h3 {
    font-size: 16px;
    color: #C51625;
    margin: 0; }
  .pg-single-product #product-top h1 {
    color: #fff;
    margin-top: 0;
    font-weight: 600; }
  .pg-single-product #product-top p {
    color: #fff;
    margin-top: 0.5rem; }
  .pg-single-product #product-top.product-top-img-guitarra {
    background: url(../images/bkg/bkg-guitar.png) center top repeat; }
  .pg-single-product #product-top.product-top-img-vampyr {
    background: url(../images/bkg/bkg-vampyr.png) center top repeat;
    background-size: cover; }
    .pg-single-product #product-top.product-top-img-vampyr .thered {
      margin-top: 6rem; }
    .pg-single-product #product-top.product-top-img-vampyr h1, .pg-single-product #product-top.product-top-img-vampyr p {
      color: #000; }
    .pg-single-product #product-top.product-top-img-vampyr h1 {
      margin-bottom: 0; }
    .pg-single-product #product-top.product-top-img-vampyr .item img {
      width: 94%; }
  .pg-single-product #product-top.product-top-img-paladin {
    background: url(../images/bkg/bkg-paladin.png) center top repeat;
    background-size: cover; }
    .pg-single-product #product-top.product-top-img-paladin .thered {
      margin-top: 6rem; }
    .pg-single-product #product-top.product-top-img-paladin h1, .pg-single-product #product-top.product-top-img-paladin p {
      color: #000; }
    .pg-single-product #product-top.product-top-img-paladin h1 {
      margin-bottom: 0; }
    .pg-single-product #product-top.product-top-img-paladin .item img {
      width: 94%; }

.pg-single-product .sec-home-intro1 {
  margin-top: 4rem; }
  .pg-single-product .sec-home-intro1 h2 {
    font-weight: 600;
    color: #000; }

.pg-single-product .sec-somos-work {
  color: #000; }

.pg-single-product .sec-somos-work-2 {
  color: #000; }
  .pg-single-product .sec-somos-work-2 h2 {
    font-weight: 600;
    text-transform: none; }

.pg-single-product .product-lists {
  display: flex;
  column-gap: 2rem; }

.container-product .cart-p-title h1 {
  font-size: 16px;
  color: #C51625;
  text-transform: uppercase;
  font-weight: 100;
  margin: 0; }

.container-product .ratesnormal {
  font-size: 16px;
  color: #252233;
  font-weight: 700;
  margin: 0.5rem 0; }

.container-product .product-description p {
  margin: 0; }

.container-product .product-description-large p {
  font-size: 16px;
  margin: 0; }

.container-product .imgp img {
  width: 100%; }

.product .container-fluid.bg-white {
  padding-top: 11.3rem;
  background: transparent !important; }

.product .product-description  {
  color: #252233;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  padding-right: 3rem; }

.product input:not([type="radio"]):not([type="checkbox"]),
.product select,
.product textarea {
  border: 0; }

.product input:not([type="radio"]):not([type="checkbox"]) + label,
.product select + label,
.product textarea + label {
  color: #252233;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px; }

.product .fhiden input {
  display: none; }

.product .custom-design {
  border-bottom: solid 1px #c2d1d9;
  display: none;
  opacity: 0;
  transition: visibility 0s linear 1s, opacity 1s linear;
  visibility: hidden;
  width: 453px;
  margin: 0; }
  .product .custom-design.oncustomdesign {
    display: flex !important;
    margin: 25px auto;
    opacity: 1;
    padding-bottom: 24px;
    transition-delay: 0s;
    visibility: visible; }
  @media all and (max-width: 991px) {
    .product .custom-design {
      margin-left: auto;
      margin-right: auto;
      width: 100%; } }
  @media all and (max-width: 575px) {
    .product .custom-design {
      align-items: center;
      border-bottom: 0;
      flex-direction: column;
      width: 100%; } }
  .product .custom-design p {
    color: #252233;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0;
    width: 210px; }
  .product .custom-design .button-outline {
    margin-left: auto;
    min-height: 50px;
    min-width: 225px; }
    @media all and (max-width: 575px) {
      .product .custom-design .button-outline {
        margin: 27px auto 0; } }

.product .addcontinue {
  color: #252233;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  display: block;
  margin-top: 3rem;
  text-align: center;
  text-transform: uppercase; }

.product .itemc,
.product .matdes {
  display: none; }

.product .initctxt {
  display: block; }

.product .reflejante-plata .itemc.plata {
  display: block; }

.product .reflejante-plata .textcolor .group-field {
  display: none; }
  .product .reflejante-plata .textcolor .group-field:nth-child(5) {
    display: inline-flex; }

.product .reflejante-plata .reflejante-plata {
  display: block; }

.product .terciopelo .textcolor .group-field {
  display: none; }
  .product .terciopelo .textcolor .group-field:nth-child(1), .product .terciopelo .textcolor .group-field:nth-child(2), .product .terciopelo .textcolor .group-field:nth-child(6) {
    display: inline-flex; }

.product .terciopelo .terciopelo {
  display: block; }

.product .terciopelo .material-description.opc1 .itemc.blanco {
  display: block; }

.product .terciopelo .material-description.opc2 .itemc.negro {
  display: block; }

.product .terciopelo .material-description.opc6 .itemc.azul {
  display: block; }

.product .vinil-mate .vinil-mate {
  display: block; }

.product .vinil-mate .material-description.opc1 .itemc.blanco {
  display: block; }

.product .vinil-mate .material-description.opc2 .itemc.negro {
  display: block; }

.product .vinil-mate .material-description.opc3 .itemc.amarillo {
  display: block; }

.product .vinil-mate .material-description.opc4 .itemc.rojo {
  display: block; }

.product .vinil-mate .material-description.opc5 .itemc.gris {
  display: block; }

.product .vinil-mate .material-description.opc6 .itemc.azul {
  display: block; }

.product .vinil-brillante .vinil-brillante {
  display: block; }

.product .vinil-brillante .material-description.opc1 .itemc.blanco {
  display: block; }

.product .vinil-brillante .material-description.opc2 .itemc.negro {
  display: block; }

.product .vinil-brillante .material-description.opc3 .itemc.amarillo {
  display: block; }

.product .vinil-brillante .material-description.opc4 .itemc.rojo {
  display: block; }

.product .vinil-brillante .material-description.opc5 .itemc.gris {
  display: block; }

.product .vinil-brillante .material-description.opc6 .itemc.azul {
  display: block; }

.product-galery .swiper-button-next  {
  height: 435px;
  opacity: 1;
  top: 0;
  transform: none !important;
  width: 100px; }
  .product-galery .swiper-button-next  span {
    display: block;
    width: 50px;
    height: 44px;
    margin-left: auto;
    background: #fff url(../images/bkg/arrow-right.svg) no-repeat center; }
  @media all and (max-width: 575px) {
    .product-galery .swiper-button-next  {
      height: 230px; } }

.product-galery .swiper-button-prev {
  height: 435px;
  opacity: 1;
  top: 0;
  transform: rotate(180deg) !important;
  width: 100px; }
  .product-galery .swiper-button-prev span {
    display: block;
    width: 50px;
    height: 44px;
    margin-left: auto;
    background: #fff url(../images/bkg/arrow-right.svg) no-repeat center; }
  @media all and (max-width: 575px) {
    .product-galery .swiper-button-prev {
      height: 230px; } }

.product-galery .swiper-slide img {
  height: auto;
  object-fit: cover;
  width: 100%; }
  @media all and (max-width: 575px) {
    .product-galery .swiper-slide img {
      height: 230px; } }

@media all and (max-width: 575px) {
  .product-galery .swiper-container {
    width: 320px; } }

@media all and (max-width: 320px) {
  .product-galery .swiper-container {
    width: 100%; } }

.product-galery .swiper-pagination-bullets {
  display: none; }

.product-galery .product-image-thumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.8rem 0 3.3rem; }
  .product-galery .product-image-thumbs .item {
    height: 106px;
    margin-bottom: 0.5rem;
    position: relative;
    overflow: hidden;
    width: 106px; }
    @media all and (max-width: 991px) {
      .product-galery .product-image-thumbs .item {
        height: 67px;
        width: 67px; } }
    .product-galery .product-image-thumbs .item::before, .product-galery .product-image-thumbs .item::after {
      background: #000;
      content: "";
      height: 3px;
      position: absolute;
      transition: all 0.2s linear;
      width: 0; }
    .product-galery .product-image-thumbs .item:hover::before, .product-galery .product-image-thumbs .item:hover::after {
      width: 100%; }
    .product-galery .product-image-thumbs .item:hover .link-item::before, .product-galery .product-image-thumbs .item:hover .link-item::after {
      height: 100%; }
    .product-galery .product-image-thumbs .item::before {
      left: 50%;
      top: 0;
      transition-duration: 0.4s; }
    .product-galery .product-image-thumbs .item::after {
      bottom: 0;
      left: 50%;
      transition-duration: 0.4s; }
    .product-galery .product-image-thumbs .item .link-item::before {
      left: 0;
      top: 50%;
      transition-duration: 0.4s; }
    .product-galery .product-image-thumbs .item .link-item::after {
      right: 0;
      top: 50%;
      transition-duration: 0.4s; }
    .product-galery .product-image-thumbs .item:hover::before, .product-galery .product-image-thumbs .item:hover::after {
      left: 0; }
    .product-galery .product-image-thumbs .item:hover .link-item::before, .product-galery .product-image-thumbs .item:hover .link-item::after {
      top: 0; }
  .product-galery .product-image-thumbs a {
    display: block; }
  .product-galery .product-image-thumbs img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 106px; }
    @media all and (max-width: 991px) {
      .product-galery .product-image-thumbs img {
        width: 67px; } }
  .product-galery .product-image-thumbs .link-item {
    border: 1px solid #000;
    display: block;
    height: 100%;
    opacity: 0.8; }
    .product-galery .product-image-thumbs .link-item.animateline {
      opacity: 1;
      border: 3px solid #000; }
    .product-galery .product-image-thumbs .link-item:hover {
      text-decoration: none; }
    .product-galery .product-image-thumbs .link-item::before, .product-galery .product-image-thumbs .link-item::after {
      background: #000;
      content: "";
      height: 0;
      position: absolute;
      transition: all 0.2s linear;
      width: 3px; }

@media all and (max-width: 575px) {
  .product-galery .product-description {
    display: none; } }

@media all and (max-width: 575px) {
  .product-wrapper-content {
    padding-bottom: 0.4rem; } }

.product-title {
  margin: 0 0 2rem;
  padding-left: 5%; }
  .product-title h1,
  .product-title .ptitle {
    color: #252233;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px; }
  .product-title strong,
  .product-title span {
    color: #252233;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px; }

.product-bottom-description {
  color: #252233;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  display: flex;
  background: #fff;
  justify-content: center;
  padding: 4.5rem 8px 5.7rem 8px;
  text-align: center; }
  .product-bottom-description p {
    color: #252233;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0; }

@media all and (max-width: 575px) {
  .previmg {
    width: 350px !important;
    height: 350px !important; } }

.wrapper-product-bootom {
  background: #fff;
  min-height: 400px;
  text-align: center;
  width: 500px; }
  @media all and (max-width: 575px) {
    .wrapper-product-bootom {
      width: 350px !important;
      height: 350px !important;
      min-height: 250px;
      margin: auto; } }
  .wrapper-product-bootom img {
    display: block;
    margin: auto; }
  .wrapper-product-bootom .text {
    cursor: move;
    display: inline-block;
    position: relative;
    width: 165px; }
    .wrapper-product-bootom .text.text::before {
      background: url(../images/bkg/movimiento.png) no-repeat center;
      cursor: move;
      background-position: left -10px;
      position: relative;
      z-index: 5;
      content: "lala";
      font-size: 0;
      height: 14px;
      width: 14px;
      background-size: contain;
      display: block;
      background-position: center;
      left: -10px;
      top: 10px; }
      @media all and (max-width: 575px) {
        .wrapper-product-bootom .text.text::before {
          height: 24px;
          width: 24px;
          background-size: 24px auto; } }
    .wrapper-product-bootom .text p {
      margin: 5px auto; }

.wrapper-total {
  margin-left: auto;
  width: 50%;
  margin: 0; }
  @media all and (max-width: 991px) {
    .wrapper-total {
      margin-left: 0;
      width: 100%; } }
  @media all and (max-width: 575px) {
    .wrapper-total {
      background: #ebf0f2;
      bottom: 0;
      left: 0;
      padding: 20px 0 0;
      position: fixed;
      width: 100%;
      z-index: 999; } }
  .wrapper-total a {
    align-items: center;
    display: flex;
    min-height: 50px;
    text-align: center;
    width: 100%; }
  .wrapper-total h3 {
    color: #666f80;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0 0 15px; }
    @media all and (max-width: 575px) {
      .wrapper-total h3 {
        color: #000;
        text-align: center; } }
  .wrapper-total .button {
    margin-bottom: 1.4rem; }
    @media all and (max-width: 575px) {
      .wrapper-total .button {
        margin-bottom: 0; } }
  @media all and (max-width: 767px) {
    .wrapper-total .addcontinue {
      display: none !important; } }

.faddcart {
  margin-left: auto;
  overflow: hidden;
  width: 453px;
  margin: 3rem 0 0; }
  .faddcart .error {
    color: #cb3234;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px; }
  @media all and (max-width: 991px) {
    .faddcart {
      margin: 2.2rem auto 0;
      width: 100%; } }
  @media all and (max-width: 575px) {
    .faddcart {
      -webkit-animation-name: tada;
      animation-name: tada;
      width: 100%; } }
  .faddcart .content-radiobutton .group-field {
    margin-bottom: 2px;
    margin-top: 2px; }
  .faddcart .form-content {
    padding: 0; }
  .faddcart .buttons .button {
    display: none; }
  .faddcart .labelfirst {
    color: #484e5a;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px; }
    .faddcart .labelfirst .form-content {
      align-items: center;
      display: flex; }
    .faddcart .labelfirst .inline {
      margin-right: 1rem;
      order: 1; }
    .faddcart .labelfirst .content-radiobutton {
      flex-wrap: wrap;
      order: 2; }
      @media all and (max-width: 575px) {
        .faddcart .labelfirst .content-radiobutton {
          margin-left: auto; } }
      @media all and (max-width: 575px) {
        .faddcart .labelfirst .content-radiobutton .group-field {
          margin-bottom: 5px; } }
  .faddcart .selectsize {
    margin-bottom: 3px; }
    .faddcart .selectsize .group-field {
      margin-right: 8px; }
      .faddcart .selectsize .group-field label {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 24px;
        background: #b4b4b4;
        border-radius: 12px;
        cursor: pointer;
        height: 24px;
        padding-top: 0.7px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        width: auto; }
      .faddcart .selectsize .group-field input {
        display: none; }
      .faddcart .selectsize .group-field.active label {
        background: #000;
        color: #ebf0f2; }
  .faddcart .clothescolor {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    margin-bottom: 5px; }
    @media all and (max-width: 767px) {
      .faddcart .clothescolor {
        margin: 20px auto; } }
    .faddcart .clothescolor .form-content {
      min-height: 48px; }
    .faddcart .clothescolor .group-field input {
      display: none; }
    .faddcart .clothescolor .group-field label {
      border: 1px solid #979797;
      border-radius: 50%;
      cursor: pointer;
      font-size: 0;
      height: 21px;
      margin-right: 8px;
      width: 21px; }
    .faddcart .clothescolor .group-field:first-of-type.active label {
      background: white; }
    .faddcart .clothescolor .group-field:last-of-type label {
      background: #000; }
    .faddcart .clothescolor .group-field:last-of-type.active label {
      background: #000; }
    .faddcart .clothescolor .group-field.active {
      margin-left: 5px; }
      .faddcart .clothescolor .group-field.active label {
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        font-size: 0;
        height: 21px;
        margin-right: 8px;
        position: relative;
        top: 0px;
        width: 21px; }
        .faddcart .clothescolor .group-field.active label::before {
          border-radius: 50%;
          border: solid 2px black;
          content: ".";
          display: block;
          display: block;
          height: 29px;
          left: -4px;
          position: relative;
          top: -4px;
          width: 29px; }
  .faddcart .subtitle-design {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
    color: #484e5a;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0.6rem; }
  .faddcart .subtitle-design-3,
  .faddcart .subtitle-design-2 {
    color: #6d6d6d;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 24px;
    margin-right: 1.6rem;
    text-transform: uppercase; }
  .faddcart #abril-fatface {
    font-family: "Abril Fatface", cursive; }
  .faddcart #anton {
    font-family: "Anton"; }
  .faddcart #athelas {
    font-family: "Athelas"; }
  .faddcart #bangers {
    font-family: "bangers"; }
  .faddcart #barriecito {
    font-family: "Barriecito", cursive; }
  .faddcart #brandon-grotesque {
    font-family: "brandon_grotesqueblack"; }
  .faddcart #bungee-inline {
    font-family: "Bungee Inline", cursive; }
  .faddcart #creepster {
    font-family: "Creepster", cursive; }
  .faddcart #dosis {
    font-family: "Dosis"; }
  .faddcart #hanalei {
    font-family: "Hanalei", cursive; }
  .faddcart #kaushan {
    font-family: "Kaushan Script"; }
  .faddcart #kaushan-script {
    font-family: "Kaushan Script", cursive; }
  .faddcart #libre-baskerville {
    font-family: "Libre Baskerville"; }
  .faddcart #lobster {
    font-family: "Lobster", cursive; }
  .faddcart #molle {
    font-family: "Molle", cursive; }
  .faddcart #montserrat {
    font-family: "Montserrat", sans-serif; }
  .faddcart #pacifico {
    font-family: "Pacifico 400"; }
  .faddcart #play-fair {
    font-family: "Playfair Display", serif; }
  .faddcart #press-strat {
    font-family: "Press Start 2P", cursive; }
  .faddcart #roboto-slab {
    font-family: "Roboto Slab"; }
  .faddcart #ruge {
    font-family: "Ruge Boogie", cursive; }
  .faddcart #saria {
    font-family: "Saira Stencil One", cursive; }
  .faddcart #satisfy {
    font-family: "Satisfy", cursive; }
  .faddcart #shojumaru {
    font-family: "Shojumaru", cursive; }
  .faddcart #spicy-rice {
    font-family: "Spicy Rice", cursive; }
  .faddcart #unisansheavy {
    font-family: "uni_sansheavy_caps"; }
  .faddcart #volkhov {
    font-family: "Volkhov", serif; }
  .faddcart .fonttype {
    margin-bottom: 15px; }
  .faddcart .fontsize,
  .faddcart .fonttype {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s; }
    .faddcart .fontsize .inline,
    .faddcart .fonttype .inline {
      color: #6d6d6d;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 2px;
      line-height: 24px;
      display: none;
      min-width: 210px;
      margin-left: 2rem;
      text-transform: uppercase; }
    .faddcart .fontsize .form-content,
    .faddcart .fonttype .form-content {
      align-items: center;
      border: 1px solid #e1e1e1;
      display: flex;
      min-height: 48px;
      padding: 0 8px; }
  .faddcart select {
    color: #6d6d6d;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 24px;
    outline: none;
    padding: 0;
    text-transform: uppercase;
    min-height: 24px; }
    .faddcart select:focus, .faddcart select:active {
      outline: none; }
  .faddcart .textcolor {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
    margin: 1.7rem 0 1rem; }
    .faddcart .textcolor .group-field {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-right: 8px; }
      @media all and (max-width: 575px) {
        .faddcart .textcolor .group-field {
          margin-bottom: 9px; } }
    @media all and (max-width: 575px) {
      .faddcart .textcolor .content-radiobutton {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: auto;
        width: 160px; } }
    .faddcart .textcolor .content-radiobutton .active {
      background: #fff;
      border-radius: 50%;
      border: solid 2px #000;
      height: 28px;
      width: 28px; }
    .faddcart .textcolor .content-radiobutton label {
      border-radius: 50%;
      cursor: pointer;
      font-size: 0;
      height: 20px;
      width: 20px; }
      .faddcart .textcolor .content-radiobutton label[value*="opc1"] {
        background: #fff;
        border: 1px solid #979797; }
      .faddcart .textcolor .content-radiobutton label[value*="opc2"] {
        background: #000; }
      .faddcart .textcolor .content-radiobutton label[value*="opc3"] {
        background: #f8f32b; }
      .faddcart .textcolor .content-radiobutton label[value*="opc4"] {
        background: #f00; }
      .faddcart .textcolor .content-radiobutton label[value*="opc5"] {
        background: #9c9c9c; }
      .faddcart .textcolor .content-radiobutton label[value*="opc6"] {
        background: #00f; }
      .faddcart .textcolor .content-radiobutton label[value*="opc7"] {
        background: #99bc16; }
      .faddcart .textcolor .content-radiobutton label[value*="opc8"] {
        background: #ffd100; }
      .faddcart .textcolor .content-radiobutton label[value*="opc9"] {
        background: #e7473e; }
      .faddcart .textcolor .content-radiobutton label[value*="opc10"] {
        background: #8518a6; }
    .faddcart .textcolor .content-radiobutton input {
      display: none; }
    .faddcart .textcolor .inline {
      min-width: 124px; }
  .faddcart .posplayera {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    min-height: 48px; }
    @media all and (max-width: 767px) {
      .faddcart .posplayera {
        margin-bottom: 25px; } }
  .faddcart .typematerial {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    border-bottom: solid 1px #c2d1d9;
    padding-bottom: 22px; }
    @media all and (max-width: 767px) {
      .faddcart .typematerial {
        padding-bottom: 25px; } }
    .faddcart .typematerial .content-radiobutton {
      justify-content: flex-start; }
    .faddcart .typematerial label {
      padding-left: 10px;
      padding-right: 10px; }
  .faddcart .typecuello .form-content,
  .faddcart .posplayera .form-content,
  .faddcart .typematerial .form-content {
    padding: 0; }
  .faddcart .typecuello .group-field,
  .faddcart .posplayera .group-field,
  .faddcart .typematerial .group-field {
    margin-right: 8px; }
    .faddcart .typecuello .group-field label,
    .faddcart .posplayera .group-field label,
    .faddcart .typematerial .group-field label {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
      background: #b4b4b4;
      border-radius: 12px;
      cursor: pointer;
      min-height: 24px;
      min-width: 92px;
      text-align: center;
      text-transform: capitalize; }
    .faddcart .typecuello .group-field input,
    .faddcart .posplayera .group-field input,
    .faddcart .typematerial .group-field input {
      display: none; }
    .faddcart .typecuello .group-field.active label,
    .faddcart .posplayera .group-field.active label,
    .faddcart .typematerial .group-field.active label {
      background: #000;
      color: #ebf0f2; }
  .faddcart .textarea {
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin: 2.4rem 0 0; }
    .faddcart .textarea label {
      display: none; }
    .faddcart .textarea textarea {
      color: #6d6d6d;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 24px;
      border: 1px solid #e1e1e1;
      min-height: 81px;
      padding: 1.5rem; }

.area-img {
  display: inline-flex;
  position: relative;
  width: 500px;
  height: 435px; }
  @media all and (max-width: 575px) {
    .area-img {
      width: 350px !important;
      height: 350px !important; } }

#box-design-edit {
  border-radius: 0px;
  border: 1px dashed #979797;
  font-size: 24px;
  line-height: 24px;
  height: 190px;
  padding: 15px;
  position: absolute;
  text-align: initial;
  width: 119px;
  word-wrap: break-word; }
  #box-design-edit.abril-fatface p {
    font-family: "Abril Fatface", cursive; }
  #box-design-edit.anton p {
    font-family: "Anton"; }
  #box-design-edit.athelas p {
    font-family: "Athelas"; }
  #box-design-edit.bangers p {
    font-family: "bangers"; }
  #box-design-edit.barriecito p {
    font-family: "Barriecito", cursive; }
  #box-design-edit.brandon-grotesque p {
    font-family: "brandon_grotesqueblack"; }
  #box-design-edit.bungee-inline p {
    font-family: "Bungee Inline", cursive; }
  #box-design-edit.creepster p {
    font-family: "Creepster", cursive; }
  #box-design-edit.dosis p {
    font-family: "Dosis"; }
  #box-design-edit.hanalei p {
    font-family: "Hanalei", cursive; }
  #box-design-edit.kaushan p {
    font-family: "Kaushan Script"; }
  #box-design-edit.kaushan-script p {
    font-family: "Kaushan Script", cursive; }
  #box-design-edit.libre-baskerville p {
    font-family: "Libre Baskerville"; }
  #box-design-edit.lobster p {
    font-family: "Lobster", cursive; }
  #box-design-edit.molle p {
    font-family: "Molle", cursive; }
  #box-design-edit.montserrat p {
    font-family: "Montserrat", sans-serif; }
  #box-design-edit.pacifico p {
    font-family: "Pacifico 400"; }
  #box-design-edit.play-fair p {
    font-family: "Playfair Display", serif; }
  #box-design-edit.press-strat p {
    font-family: "Press Start 2P", cursive; }
  #box-design-edit.roboto-slab p {
    font-family: "Roboto Slab"; }
  #box-design-edit.ruge p {
    font-family: "Ruge Boogie", cursive; }
  #box-design-edit.saria p {
    font-family: "Saira Stencil One", cursive; }
  #box-design-edit.satisfy p {
    font-family: "Satisfy", cursive; }
  #box-design-edit.shojumaru p {
    font-family: "Shojumaru", cursive; }
  #box-design-edit.spicy-rice p {
    font-family: "Spicy Rice", cursive; }
  #box-design-edit.unisansheavy p {
    font-family: "uni_sansheavy_caps"; }
  #box-design-edit.volkhov p {
    font-family: "Volkhov", serif; }

.opc-tag,
.opc-archives_month,
.opc-archives_year,
.hiddenfield,
.jsonproductadd {
  display: none; }

@media all and (max-width: 767px) {
  .filter-product {
    display: none; } }

.pg-mameluco .clothescolor .group-field:last-of-type {
  display: none; }

.cat-tienda-mujeres-playera-cuello-v #box-design-edit {
  height: 250px;
  left: 31.5%;
  top: 33%;
  width: 185px; }
  @media all and (max-width: 575px) {
    .cat-tienda-mujeres-playera-cuello-v #box-design-edit {
      height: 200px;
      width: 130px; } }

.cat-tienda-mujeres-playera-cuello-v.pos-atras #box-design-edit {
  left: 31.5%;
  top: 27%; }

.cat-tienda-mujeres-playera-cuello-redondo #box-design-edit {
  height: 250px;
  left: 31.5%;
  top: 30%;
  width: 185px; }
  @media all and (max-width: 575px) {
    .cat-tienda-mujeres-playera-cuello-redondo #box-design-edit {
      height: 200px;
      width: 130px; } }

.cat-tienda-mujeres-playera-cuello-redondo.pos-atras #box-design-edit {
  left: 31%;
  top: 28%; }

.cat-tienda-hombres-playera-cuello-v #box-design-edit {
  height: 250px;
  left: 34.5%;
  top: 32%;
  width: 160px; }
  @media all and (max-width: 575px) {
    .cat-tienda-hombres-playera-cuello-v #box-design-edit {
      height: 200px;
      width: 110px; } }

.cat-tienda-hombres-playera-cuello-v.pos-atras #box-design-edit {
  left: 33%;
  top: 28%; }

.cat-tienda-hombres-playera-cuello-redondo #box-design-edit {
  height: 250px;
  left: 34%;
  top: 27%;
  width: 165px; }
  @media all and (max-width: 575px) {
    .cat-tienda-hombres-playera-cuello-redondo #box-design-edit {
      height: 200px;
      width: 120px; } }

.cat-tienda-hombres-playera-cuello-redondo.pos-atras #box-design-edit {
  left: 33%;
  top: 25%; }

.cat-tienda-ninos-playera-cuello-redondo #box-design-edit {
  height: 250px;
  left: 32.5%;
  top: 35%;
  width: 170px; }
  @media all and (max-width: 575px) {
    .cat-tienda-ninos-playera-cuello-redondo #box-design-edit {
      height: 200px;
      width: 130px; } }

.cat-tienda-ninos-playera-cuello-redondo.pos-atras #box-design-edit {
  left: 33.5%;
  top: 33%; }

.cat-tienda-bebes-mameluco #box-design-edit {
  height: 210px;
  left: 39.5%;
  top: 32%;
  width: 130px; }
  @media all and (max-width: 575px) {
    .cat-tienda-bebes-mameluco #box-design-edit {
      height: 200px;
      width: 130px; } }

.cat-tienda-bebes-mameluco.pos-atras #box-design-edit {
  left: 36.5%;
  top: 32%; }

.container-product {
  display: block; }

html .typematerial .inline {
  padding-left: 0px; }

.product-cat-bebes .clothescolor .group-field:last-of-type {
  display: none; }

.btnRotate {
  background: url(../images/bkg/rotacion.png) no-repeat center;
  background-size: 20px auto;
  cursor: pointer;
  height: 20px;
  width: 20px;
  display: block;
  font-size: 0;
  position: absolute;
  right: 10px;
  top: 11px; }
  @media all and (max-width: 575px) {
    .btnRotate {
      height: 24px;
      width: 24px;
      background-size: 24px auto;
      top: 5px;
      z-index: 888; } }

.title-product-prev h3 {
  display: flex;
  flex-direction: column; }

.title-product-prev strong {
  font-size: 12px;
  line-height: 12px;
  margin-top: 8px; }

@media all and (max-width: 575px) {
  .pg-custom-design .content-radiobutton {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; } }

.pg-custom-design .clothescolor .group-field.active {
  top: -15px; }

.faddcart .clothescolor .group-field.active label {
  top: 0 !important; }

.selecttipersona {
  margin-bottom: 3px; }
  .selecttipersona .group-field {
    margin-right: 8px;
    min-height: 40px;
    display: inline-flex;
    align-items: center; }
    .selecttipersona .group-field label {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
      background: #b4b4b4;
      border-radius: 12px;
      cursor: pointer;
      height: 24px;
      padding-top: 0.7px;
      text-align: center;
      width: 85px; }
    .selecttipersona .group-field input {
      display: none; }
    .selecttipersona .group-field.active label {
      background: #000;
      color: #ebf0f2; }

.oncustomdesignct .inline {
  width: 100%; }

.faddcart .selectsize .inline {
  min-width: 120px; }

.faddcart .content-radiobutton .group-field.active {
  display: inline-flex !important; }

.prod-simple .swiper-pagination-bullets {
  display: block !important; }

.prod-simple .custom-design.oncustomdesign.namesong, .prod-simple .custom-design.oncustomdesign.posplayera, .prod-simple .custom-design.oncustomdesign.clothescolor, .prod-simple .custom-design.oncustomdesign.selectsize,
.prod-simple .form-field.namesong,
.prod-simple .form-field.posplayera,
.prod-simple .form-field.clothescolor,
.prod-simple .form-field.selectsize {
  display: block !important; }

.prod-simple .container-product {
  margin-bottom: 10%; }

.product .swiper-button-prev,
.product .swiper-button-next {
  display: flex !important; }

.product .swiper-container {
  pointer-events: auto;
  height: 100%;
  margin-bottom: 20px; }

.prevnextbtn {
  position: static !important;
  height: 25px;
  width: 25px;
  display: block;
  background: black; }

.pg-store .content {
  min-height: calc(100vh - 25rem); }

.labelpromo-simple {
  color: #484e5a;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px; }
  @media all and (max-width: 991px) {
    .labelpromo-simple {
      text-align: center; } }

.prod-simple.pg-colornegro .clothescolor .group-field:first-of-type {
  display: none !important; }

.prod-simple.pg-colorblanco .clothescolor .group-field:last-of-type {
  display: none !important; }

.prod-simple.pg-colornegro .clothescolor .group-field:last-of-type {
  display: block !important; }

.product .selectsize .group-field {
  display: none; }

.product .talla-24meses {
  display: block !important; }

.product-cat-mujeres .talla-24meses,
.product-cat-hombres .talla-24meses,
.product-cat-spotify .talla-24meses {
  display: none !important; }

.prod-simple.color2 .clothescolor .group-field:first-of-type,
.prod-simple.color2 .clothescolor .group-field:last-of-type,
.prod-simple.pg-colorblanconegro .clothescolor .group-field:first-of-type,
.prod-simple.pg-colorblanconegro .clothescolor .group-field:last-of-type {
  display: block !important; }

.prod-simple.print-espalda .group-field:first-of-type {
  display: none; }

.prod-simple.print-frente .group-field:last-of-type {
  display: none; }

.prod-simple.canspoti .spoticode,
.prod-simple.cancuello .typecuello {
  display: block !important; }

.prod-simple.cuello-vcuello-u .typecuello .group-field:last-of-type,
.prod-simple.cuello-vcuello-u .typecuello .group-field:first-of-type,
.prod-simple.cuello-ucuello-v .typecuello .group-field:last-of-type,
.prod-simple.cuello-ucuello-v .typecuello .group-field:first-of-type {
  display: block !important; }

.prod-simple.cuello-u .typecuello .group-field:last-of-type {
  display: block !important; }

.prod-simple.cuello-v .typecuello .group-field:first-of-type {
  display: block !important; }

.product.product-cat-hombres .talla-chico,
.product.product-cat-hombres .talla-mediano,
.product.product-cat-hombres .talla-grande,
.product.product-cat-hombres .talla-extragrande,
.product.product-cat-mujeres .talla-chico,
.product.product-cat-mujeres .talla-mediano,
.product.product-cat-mujeres .talla-grande,
.product.product-cat-mujeres .talla-extragrande,
.product-cat-spotify .talla-chico,
.product-cat-spotify .talla-mediano,
.product-cat-spotify .talla-grande,
.product-cat-spotify .talla-extragrande {
  display: block; }

.product.product-cat-ninos .talla-1a2years,
.product.product-cat-ninos .talla-3a4years,
.product.product-cat-ninos .talla-5a6years,
.product.product-cat-ninos .talla-7a8years,
.product.product-cat-ninos .talla-9a14years,
.product.product-cat-ninos .talla-11a12years {
  display: block !important; }

.product-cat-bebes .talla-0a3meses,
.product-cat-bebes .talla-6meses,
.product-cat-bebes .talla-9meses,
.product-cat-bebes .talla-12meses,
.product-cat-bebes .talla-18meses,
.product-cat-bebes .talla-24meses {
  display: block !important; }

.namesong .lbdef,
.namesong h3 {
  color: #484e5a;
  font-family: "Oswald";
  font-size: 16px;
  line-height: 16px; }

.namesong label:not(.lbdef) {
  display: none; }

.namesong .inline {
  display: none !important; }

.form-field.textv2.namesong {
  display: none !important; }
  .form-field.textv2.namesong.showsong {
    display: block !important; }

.textnamesong {
  border-bottom: solid 1px #6d6d6d !important;
  min-height: auto !important;
  height: auto !important;
  line-height: 16px !important;
  font-size: 14px !important;
  padding: 0 !important; }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-flex;
  width: 60px;
  height: 34px;
  order: 2; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.slider::before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #2196f3; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3; }

input:checked + .slider::before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round::before {
  border-radius: 50%; }

.spoticode .form-content {
  display: flex; }
  .spoticode .form-content .inline {
    display: inline-flex;
    align-items: center;
    order: 1;
    margin-right: 15px; }

.spoticode {
  margin-bottom: 10px; }

.prod-simple .imgp {
  display: none !important; }
  .prod-simple .imgp:not(.active) {
    display: none !important; }
  .prod-simple .imgp.active {
    display: block !important; }

.prod-simple.icolor-negro.icuello-u .imgp.negro-frente-u {
  display: block; }

.prod-simple.icolor-blanco.icuello-u .imgp.blanco-frente-u {
  display: block; }

.ratespotify {
  display: none !important; }

.mainsptifyon .ratesnormal {
  display: none; }

.mainsptifyon .ratespotify {
  display: block !important; }

.product-show-song .ratespotify {
  display: block !important; }

.sec-design-custom-bottom .row {
  justify-content: center; }

.sec-design-custom-bottom .wrapper-file {
  align-items: center;
  border: 4px dashed #d3d3d3;
  display: flex;
  flex-direction: column;
  min-height: 354px; }
  @media all and (max-width: 991px) {
    .sec-design-custom-bottom .wrapper-file {
      margin: 4rem auto 0 auto; } }
  .sec-design-custom-bottom .wrapper-file small {
    color: #252233;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px; }
  .sec-design-custom-bottom .wrapper-file .button-outline {
    margin: auto auto 3.4rem;
    width: 160px; }
  .sec-design-custom-bottom .wrapper-file .icon-upload {
    margin-top: 5.5rem; }

.sec-design-custom-bottom .file {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%; }
  @media all and (max-width: 991px) {
    .sec-design-custom-bottom .file {
      position: static; } }
  @media all and (max-width: 767px) {
    .sec-design-custom-bottom .file {
      width: 64%; } }
  @media all and (max-width: 575px) {
    .sec-design-custom-bottom .file {
      width: 97%; } }
  .sec-design-custom-bottom .file .form-content {
    padding-left: 27px;
    width: 100%; }
    @media all and (max-width: 991px) {
      .sec-design-custom-bottom .file .form-content {
        padding-left: 0; } }
  .sec-design-custom-bottom .file .form-input-wrapper {
    align-items: center;
    border: 4px dashed #d3d3d3;
    display: flex;
    flex-direction: column;
    min-height: 354px; }
  .sec-design-custom-bottom .file input {
    display: none; }

.form-custom-design .spleft {
  padding-left: 15px !important; }

@media all and (max-width: 991px) {
  .form-custom-design {
    margin: auto; } }

.form-custom-design .specupload {
  margin: auto;
  text-align: center;
  width: 70%; }
  .form-custom-design .specupload p {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600; }

@media all and (max-width: 991px) {
  .form-custom-design .txt {
    margin-left: auto; } }

.form-custom-design .file label {
  display: none; }

.form-custom-design textarea:placeholder-shown:not(:focus) + label  {
  font-size: 16px;
  text-transform: none; }

.form-custom-design #mujer .group-field:nth-child(4n), .form-custom-design #mujer .group-field:nth-child(3n), .form-custom-design #mujer .group-field:nth-child(2n), .form-custom-design #mujer .group-field:nth-child(1n),
.form-custom-design #hombre .group-field:nth-child(4n),
.form-custom-design #hombre .group-field:nth-child(3n),
.form-custom-design #hombre .group-field:nth-child(2n),
.form-custom-design #hombre .group-field:nth-child(1n) {
  display: inline-flex; }

.form-custom-design #mujer .group-field:nth-child(11n), .form-custom-design #mujer .group-field:nth-child(12n), .form-custom-design #mujer .group-field:nth-child(13n), .form-custom-design #mujer .group-field:nth-child(9n), .form-custom-design #mujer .group-field:nth-child(8n), .form-custom-design #mujer .group-field:nth-child(7n), .form-custom-design #mujer .group-field:nth-child(6n), .form-custom-design #mujer .group-field:nth-child(5n),
.form-custom-design #hombre .group-field:nth-child(11n),
.form-custom-design #hombre .group-field:nth-child(12n),
.form-custom-design #hombre .group-field:nth-child(13n),
.form-custom-design #hombre .group-field:nth-child(9n),
.form-custom-design #hombre .group-field:nth-child(8n),
.form-custom-design #hombre .group-field:nth-child(7n),
.form-custom-design #hombre .group-field:nth-child(6n),
.form-custom-design #hombre .group-field:nth-child(5n) {
  display: none; }

.form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(11n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(12n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(13n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(14n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(15n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(4n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(3n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(2n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(1n),
.form-custom-design #ninos .group-field:nth-child(11n),
.form-custom-design #ninos .group-field:nth-child(12n),
.form-custom-design #ninos .group-field:nth-child(13n),
.form-custom-design #ninos .group-field:nth-child(14n),
.form-custom-design #ninos .group-field:nth-child(15n),
.form-custom-design #ninos .group-field:nth-child(4n),
.form-custom-design #ninos .group-field:nth-child(3n),
.form-custom-design #ninos .group-field:nth-child(2n),
.form-custom-design #ninos .group-field:nth-child(1n) {
  display: none; }

.form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(9n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(8n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(7n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(6n), .form-custom-design .product-cat-ninos .selectsize .group-field:nth-child(5n),
.form-custom-design #ninos .group-field:nth-child(9n),
.form-custom-design #ninos .group-field:nth-child(8n),
.form-custom-design #ninos .group-field:nth-child(7n),
.form-custom-design #ninos .group-field:nth-child(6n),
.form-custom-design #ninos .group-field:nth-child(5n) {
  display: inline-flex; }

.form-custom-design #bebes .group-field:nth-child(1n), .form-custom-design #bebes .group-field:nth-child(2n), .form-custom-design #bebes .group-field:nth-child(3n), .form-custom-design #bebes .group-field:nth-child(4n), .form-custom-design #bebes .group-field:nth-child(5n), .form-custom-design #bebes .group-field:nth-child(6n), .form-custom-design #bebes .group-field:nth-child(7n), .form-custom-design #bebes .group-field:nth-child(8n), .form-custom-design #bebes .group-field:nth-child(9n), .form-custom-design #bebes .group-field:nth-child(10n) {
  display: none; }

.form-custom-design #bebes .group-field:nth-child(11n), .form-custom-design #bebes .group-field:nth-child(12n), .form-custom-design #bebes .group-field:nth-child(13n), .form-custom-design #bebes .group-field:nth-child(14n), .form-custom-design #bebes .group-field:nth-child(15n) {
  display: inline-flex; }

.sec-design-custom-bottom .container-fluid {
  background: #fff;
  padding-top: 4rem; }

.pg-custom-design .buttons {
  margin-top: 22px;
  width: 100%; }
  .pg-custom-design .buttons .button  {
    margin: 19px auto;
    width: 435px; }
    @media all and (max-width: 575px) {
      .pg-custom-design .buttons .button  {
        width: 100%; } }

.pg-custom-design .content-top {
  padding: 14rem 8px 5rem; }
  .pg-custom-design .content-top .specupload {
    display: none; }

.pg-custom-design h1 {
  margin: 0 0 3rem 0;
  text-align: center; }
  @media all and (min-width: 769px) {
    .pg-custom-design h1 {
      text-align: center; } }

.pg-custom-design .content-top p {
  color: #484e5a;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0; }

.pg-custom-design .content-top .container-fluid {
  padding-top: 14rem;
  background: white; }
  @media all and (max-width: 767px) {
    .pg-custom-design .content-top .container-fluid {
      padding-left: 15px;
      padding-right: 15px; } }
  @media all and (max-width: 575px) {
    .pg-custom-design .content-top .container-fluid {
      padding-top: 8rem; } }

.pg-custom-design .form-field.textarea.txt {
  margin-top: 4.4rem; }

.pg-custom-design .wrapper-sendcustomdesign {
  margin-top: 2rem;
  padding-bottom: 10.7rem;
  text-align: center; }

.pg-custom-design .sendcustomdesign {
  margin: auto;
  width: 100%; }
  @media all and (min-width: 577px) {
    .pg-custom-design .sendcustomdesign {
      width: 435px; } }

@media all and (min-width: 993px) {
  .pg-custom-design .content-form {
    padding-left: 0; } }

.pg-custom-design textarea:placeholder-shown:not(:focus) + label  {
  font-size: 16px;
  text-transform: none; }

.pg-custom-design .dz-message {
  color: #252233;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px; }
  .pg-custom-design .dz-message::before {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
    animation-iteration-count: infinite;
    background: url(../images/bkg/upload-file.png) no-repeat center;
    content: "stamp";
    display: block;
    font-size: 0;
    height: 48px;
    margin: auto;
    width: 48px; }

.pg-custom-design .dropzone .button-outline {
  pointer-events: none; }

.pg-custom-design .dropzone .dz-preview {
  margin: auto; }

.pg-custom-design .dz-started .button-outline {
  display: none; }

.pg-custom-design .selecttipocuello,
.pg-custom-design .selectsize {
  margin-bottom: 3px; }
  .pg-custom-design .selecttipocuello .group-field,
  .pg-custom-design .selectsize .group-field {
    margin-right: 8px; }
    .pg-custom-design .selecttipocuello .group-field label,
    .pg-custom-design .selectsize .group-field label {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 24px;
      background: #b4b4b4;
      border-radius: 12px;
      cursor: pointer;
      height: 24px;
      padding-top: 0.7px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      width: auto; }
    .pg-custom-design .selecttipocuello .group-field input,
    .pg-custom-design .selectsize .group-field input {
      display: none; }
    .pg-custom-design .selecttipocuello .group-field.active label,
    .pg-custom-design .selectsize .group-field.active label {
      background: #000;
      color: #ebf0f2; }

.pg-custom-design .in-il-check {
  padding-left: 8px; }
  .pg-custom-design .in-il-check .form-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .pg-custom-design .in-il-check input[type="checkbox"],
  .pg-custom-design .in-il-check input[type="radio"] {
    display: none; }

.pg-custom-design .clothescolor {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  margin-bottom: 5px; }
  @media all and (max-width: 767px) {
    .pg-custom-design .clothescolor {
      margin: 20px auto; } }
  .pg-custom-design .clothescolor .form-content {
    min-height: 48px; }
  .pg-custom-design .clothescolor .group-field {
    position: relative; }
    .pg-custom-design .clothescolor .group-field input {
      display: none; }
    .pg-custom-design .clothescolor .group-field label {
      left: 0;
      border: 1px solid #979797;
      border-radius: 50%;
      cursor: pointer;
      font-size: 0;
      height: 21px;
      margin-right: 8px;
      width: 21px; }
    .pg-custom-design .clothescolor .group-field:first-of-type.active label {
      background: white; }
    .pg-custom-design .clothescolor .group-field:last-of-type label {
      background: #000; }
    .pg-custom-design .clothescolor .group-field:last-of-type.active label {
      background: #000; }
    .pg-custom-design .clothescolor .group-field.active {
      margin-left: 5px; }
      .pg-custom-design .clothescolor .group-field.active label {
        border: 0;
        border-radius: 50%;
        cursor: pointer;
        font-size: 0;
        height: 21px;
        margin-right: 8px;
        position: relative;
        width: 21px; }
        .pg-custom-design .clothescolor .group-field.active label::before {
          border-radius: 50%;
          border: solid 2px black;
          content: ".";
          display: block;
          display: block;
          height: 29px;
          left: -4px;
          position: relative;
          top: -4px;
          width: 29px; }

.pg-custom-design .clothescolor .content-radiobutton {
  margin-top: 10px; }

.oncustomdesignct .group-field {
  display: none; }

.pg-design-send main p {
  margin: 0; }

.pg-design-send main .row {
  text-align: center; }

.pg-design-send main h3 {
  color: #252233;
  font-size: 30px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 30px;
  margin: 6.5rem auto 2rem; }
  .pg-design-send main h3::before {
    background: rgba(216, 216, 216, 0.4) url(../images/bkg/icons-check.png) no-repeat center;
    background-size: contain;
    content: "stamp";
    display: block;
    font-size: 0;
    height: 64px;
    margin: 1rem auto;
    text-align: center;
    width: 64px; }

.pg-design-send main h4 {
  color: #252233;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0 auto 4.5rem;
  width: 65%; }
  @media all and (max-width: 575px) {
    .pg-design-send main h4 {
      width: 90%; } }

.pg-design-send main .link-default {
  color: #252233;
  font-size: 13px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 18px;
  display: block;
  text-decoration: underline; }

.pg-design-send main .button-outline {
  margin-bottom: 1.2rem;
  min-width: 245px; }

.pg-cart .h1cart {
  color: #252233;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  margin: 0 0 4rem; }

.pg-cart .item-add {
  background: #ebf0f2;
  display: grid;
  grid-template-columns: 200px auto;
  margin-bottom: 1rem;
  padding: 3.2rem 1.6rem; }
  @media all and (max-width: 767px) {
    .pg-cart .item-add {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  .pg-cart .item-add .wrapper-img {
    overflow: hidden;
    width: 200px; }
    .pg-cart .item-add .wrapper-img img {
      display: block;
      height: 200px;
      object-fit: cover;
      width: 100%; }
  .pg-cart .item-add h4 {
    color: #252233;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 28px;
    margin: 15px 0 1rem 0.5rem; }
  .pg-cart .item-add p {
    color: #252233;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 18px;
    margin: 0 0 10px 0; }

.pg-cart .i-er {
  background: url(../images/bkg/edit-remove.png) no-repeat;
  background-size: 32px auto;
  font-size: 0;
  width: 32px;
  height: 32px; }

.pg-cart .edit-item {
  background-position: 0px 0px; }

.pg-cart .remove-item {
  background-position: 0 -34px;
  margin-left: auto; }

.pg-cart .butttom-added {
  display: flex;
  margin-top: 2.7rem; }
  @media all and (max-width: 575px) {
    .pg-cart .butttom-added {
      flex-wrap: wrap; } }

@media all and (min-width: 769px) {
  .pg-cart .wrapper-right {
    padding-left: 1.2rem; } }

@media all and (min-width: 769px) {
  .pg-cart .quantity {
    margin-left: 2.6rem; } }

@media all and (max-width: 767px) {
  .pg-cart .quantity {
    margin: 0 1rem; } }

.pg-cart .quantity input {
  color: #252233;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 48px;
  -moz-appearance: textfield;
  background: #fff;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  height: 48px;
  padding: 0 5px;
  text-align: center;
  width: 80px; }
  @media all and (max-width: 767px) {
    .pg-cart .quantity input {
      width: 70px; } }
  @media all and (max-width: 575px) {
    .pg-cart .quantity input {
      width: 50px; } }
  .pg-cart .quantity input:focus, .pg-cart .quantity input:active {
    border-bottom-width: 1px; }

.pg-cart .quantity .quantity-button {
  color: #252233;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  background: #fff;
  border: 1px solid #e1e1e1;
  cursor: pointer;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  width: 48px; }
  .pg-cart .quantity .quantity-button:hover, .pg-cart .quantity .quantity-button:active, .pg-cart .quantity .quantity-button:focus {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    background: #000; }

.pg-cart .wrapper-total-mb {
  background: #d2d2d2;
  bottom: 0;
  display: none;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 10; }
  @media all and (max-width: 767px) {
    .pg-cart .wrapper-total-mb {
      display: block; } }
  .pg-cart .wrapper-total-mb .button {
    margin-bottom: 0;
    min-height: 50px;
    width: 100%; }

.pg-cart .hidden-txt {
  display: none; }

.pg-cart .lb-username .inline::before {
  content: "Para los siguientes campos asegurate de usar la misma información que manejas en tu cuenta de Paypal.";
  display: block;
  height: auto;
  width: 100%;
  font-size: 14px;
  text-transform: none;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 15px;
  letter-spacing: 0; }

.pg-cart .promos-text {
  color: #252233;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: uppercase; }

.wrapper-buy {
  background: #ebf0f2;
  width: 100%;
  padding: 3.9rem 1.5rem; }
  @media all and (max-width: 575px) {
    .wrapper-buy {
      padding-bottom: 2rem; } }
  .wrapper-buy .valtotal {
    color: #252233;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 30px;
    order: 2; }
    @media all and (max-width: 575px) {
      .wrapper-buy .valtotal {
        color: #252233;
        font-size: 17px;
        font-weight: 100;
        letter-spacing: 2px;
        line-height: 14px; } }
  .wrapper-buy .form-content {
    align-items: center;
    justify-content: space-between;
    padding: 0; }
  .wrapper-buy .buttons {
    display: none;
    padding: 0;
    width: 100%; }
    @media all and (max-width: 767px) {
      .wrapper-buy .buttons {
        display: none; } }
  .wrapper-buy .button {
    margin: 0;
    width: 100%; }
  .wrapper-buy .lb-jsonproducts {
    display: none; }
  .wrapper-buy .lb-costshipping {
    display: none !important;
    margin-bottom: 3rem; }
    .wrapper-buy .lb-costshipping input  {
      display: none !important; }
    .wrapper-buy .lb-costshipping div {
      display: flex; }
    .wrapper-buy .lb-costshipping .inline {
      order: 1; }
    .wrapper-buy .lb-costshipping span {
      color: #666f80;
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 30px;
      order: 2; }
      @media all and (max-width: 575px) {
        .wrapper-buy .lb-costshipping span {
          color: #666f80;
          font-size: 17px;
          font-weight: 100;
          letter-spacing: 2px;
          line-height: 14px; } }
  .wrapper-buy .form-content input {
    border: 0;
    margin-left: auto;
    order: 2;
    width: 50%; }
    .wrapper-buy .form-content input + label {
      color: #252233;
      font-size: 17px;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 18px;
      order: 1;
      position: static; }
  .wrapper-buy .lb-estimate {
    margin: 0 0 3rem auto;
    text-align: right;
    width: 193px; }
    @media all and (max-width: 575px) {
      .wrapper-buy .lb-estimate {
        margin-bottom: 0; } }
    .wrapper-buy .lb-estimate .inline {
      color: #848f9d;
      font-size: 17px;
      font-weight: 100;
      letter-spacing: 2px;
      line-height: 18px;
      order: 1;
      position: static; }
    .wrapper-buy .lb-estimate input  {
      display: none; }
  .wrapper-buy .lb-buy-gral .form-content  {
    display: flex; }
  .wrapper-buy .lb-buy-gral input {
    color: #252233;
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 30px;
    border: 0; }
  .wrapper-buy .lb-subtotal {
    margin: 5.4rem 0 4.6rem; }
    @media all and (max-width: 575px) {
      .wrapper-buy .lb-subtotal {
        margin: 4.4rem 0 3rem; } }
    .wrapper-buy .lb-subtotal input {
      display: none; }
    .wrapper-buy .lb-subtotal .valtotal {
      color: #666f80; }
  .wrapper-buy .lb-shippingstandar {
    margin-bottom: 0.5rem; }
    .wrapper-buy .lb-shippingstandar .shippingval {
      color: #666f80;
      font-size: 30px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 30px;
      order: 2; }
      @media all and (min-width: 993px) {
        .wrapper-buy .lb-shippingstandar .shippingval {
          min-width: 230px; } }
    .wrapper-buy .lb-shippingstandar input {
      display: none; }
    .wrapper-buy .lb-shippingstandar .valtotal {
      text-align: right; }
  .wrapper-buy .lb-totalbuy {
    margin-bottom: 15px; }
    .wrapper-buy .lb-totalbuy .form-content {
      display: flex; }
      .wrapper-buy .lb-totalbuy .form-content input {
        display: none; }
        .wrapper-buy .lb-totalbuy .form-content input + label {
          color: #252233;
          font-size: 22px;
          font-weight: 700;
          letter-spacing: 0;
          line-height: 28px; }
  .wrapper-buy .gralfield {
    margin-bottom: 2.4rem; }
    .wrapper-buy .gralfield label {
      color: #6d6d6d;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 24px;
      left: 2.4rem;
      text-transform: uppercase; }
    .wrapper-buy .gralfield input:not([type="text"]),
    .wrapper-buy .gralfield input:not([type="radio"]):not([type="checkbox"]) {
      color: #6d6d6d;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 2px;
      line-height: 24px;
      background: #fff;
      border: 1px solid #e1e1e1;
      min-height: 48px;
      padding: 1.5rem;
      width: 100%;
      border: 1px solid #e1e1e1 !important; }

.wrapper-cartbuy {
  margin-top: 7rem;
  padding-top: 4rem; }
  .wrapper-cartbuy .lb-addcuppon {
    margin-bottom: 2.4rem; }
    .wrapper-cartbuy .lb-addcuppon input {
      color: #252233;
      font-size: 12px;
      font-weight: 100;
      letter-spacing: 2px;
      line-height: 48px;
      opacity: 1;
      text-transform: uppercase;
      background: #fff;
      border: solid 1px #e1e1e1;
      min-height: 48px;
      width: 100%; }
      .wrapper-cartbuy .lb-addcuppon input:not([type="radio"]):not([type="checkbox"]):placeholder-shown:not(:focus)::placeholder {
        color: #252233;
        font-size: 12px;
        font-weight: 100;
        letter-spacing: 2px;
        line-height: 48px;
        opacity: 1;
        text-transform: uppercase; }
      .wrapper-cartbuy .lb-addcuppon input:focus {
        border: 1px solid #e1e1e1; }
    .wrapper-cartbuy .lb-addcuppon label {
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      background: #3f3f3f;
      cursor: pointer;
      line-height: 48px;
      min-width: 112px;
      position: absolute;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      top: 0; }

@media all and (max-width: 991px) {
  .buy-right .h1cart {
    display: none; } }

.pgcart0 {
  min-height: 70vh; }

.pg-paystamp {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .pg-paystamp .sec-steps {
    text-align: center; }
  .pg-paystamp .footer {
    margin-top: auto; }
  .pg-paystamp main img {
    display: block;
    margin: auto; }

.sec-steps-in .item {
  position: relative; }
  .sec-steps-in .item::before {
    background: #000;
    content: "stamp";
    font-size: 0;
    height: 100%;
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 5;
    -webkit-transition: background-color 0s ease-out;
    -moz-transition: background-color 0s ease-out;
    -o-transition: background-color 0s ease-out;
    transition: background-color 0s ease-out; }
  .sec-steps-in .item.active::before {
    background: rgba(255, 255, 255, 0.6);
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out; }
  .sec-steps-in .item:nth-child(2n)::before {
    -webkit-transition: background-color 1s ease-out;
    -moz-transition: background-color 1s ease-out;
    -o-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out; }
  .sec-steps-in .item:nth-child(3n)::before {
    -webkit-transition: background-color 1.5s ease-out;
    -moz-transition: background-color 1.5s ease-out;
    -o-transition: background-color 1.5s ease-out;
    transition: background-color 1.5s ease-out; }
  .sec-steps-in .item:nth-child(4n)::before {
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out; }

.sec-steps-in img {
  display: block; }

#submit-buy {
  display: none; }

.lb-orderid {
  display: none; }

.pg-succes-buy .content .col-lg-8 {
  min-height: 60vh;
  flex-direction: column;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.continue-buy {
  padding-left: 10px;
  padding-right: 10px; }

.wrapper-paypal-button {
  width: 100%;
  position: relative; }
  .wrapper-paypal-button:not(.continuebuypal)::before {
    content: "tees";
    font-size: 0;
    display: block;
    width: 100%;
    height: 100px;
    position: absolute;
    z-index: 9999; }

#paypal-button {
  width: 100% !important; }

#formbuymercado .buttons,
#formbuymercado .form-field {
  display: none; }

#btnpagarmercado {
  margin-top: 20px; }
  #btnpagarmercado img {
    margin-left: 5px; }

.wrapper-mercadopago {
  width: 100%;
  position: relative; }
  .wrapper-mercadopago:not(.continuemercadopago)::before {
    content: "tees";
    font-size: 0;
    display: block;
    width: 100%;
    height: 100px;
    position: absolute;
    z-index: 9999; }

.f-typeplantilla,
.form-field.text.imgpcustomtshirt {
  display: none; }

.page-products .ctnt-info {
  grid-template-rows: repeat(2, 0fr); }
  .page-products .ctnt-info .p-title {
    font-size: 14px;
    margin: 0; }
  .page-products .ctnt-info .p-price {
    font-size: 16px;
    margin: 0; }
  .page-products .ctnt-info .p-desc {
    font-size: 16px; }
  .page-products .ctnt-info .p-oldprice {
    font-size: 14px; }

.page-products .ctnt-link {
  padding: 2.7em 0 1.8em; }
  .page-products .ctnt-link .link-ref {
    margin-right: 5em; }

.page-products .action-btn {
  padding: 2.7em 0 1.8em;
  text-align: center; }
  .page-products .action-btn .btn-spirit {
    min-width: 12em; }

.item-simple h1 {
  text-align: center;
  font-weight: 100;
  font-size: 30px; }

.item-simple .button.thered {
  padding: 0 3rem; }

.nav-item-parent.opc-color {
  margin-top: -4.5rem; }
  .nav-item-parent.opc-color a {
    border: 0;
    color: #252233;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: uppercase; }
    .nav-item-parent.opc-color a .arrow-i {
      display: none; }
  .nav-item-parent.opc-color .submenu {
    display: flex;
    border: 0;
    column-gap: 1rem; }
    .nav-item-parent.opc-color .submenu li {
      margin-bottom: 0 !important;
      width: auto; }
      .nav-item-parent.opc-color .submenu li .nav-link {
        text-indent: -9999px;
        width: 23px;
        height: 23px;
        border: 1px solid #000;
        white-space: nowrap; }
        .nav-item-parent.opc-color .submenu li .nav-link.negro {
          background-color: #000; }
        .nav-item-parent.opc-color .submenu li .nav-link.rojo {
          background-color: #B22A2C; }
        .nav-item-parent.opc-color .submenu li .nav-link.azul {
          background-color: #007DFF; }
        .nav-item-parent.opc-color .submenu li .nav-link.cafe {
          background-color: #6B2A0B; }
        .nav-item-parent.opc-color .submenu li .nav-link.amarillo {
          background-color: #FFCA00; }

.pg-privacity main {
  padding: 6em 10em 8em; }

.pg-privacity h1 {
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 1.5em; }

.pg-privacity p {
  line-height: 19px; }

.blog.blog-modular .bkg {
  height: 435px;
  background-color: #3C4352;
  margin-top: 90px; }
  .blog.blog-modular .bkg .container-fluid, .blog.blog-modular .bkg .bkg-gray {
    height: 100%; }
  .blog.blog-modular .bkg .a-title,
  .blog.blog-modular .bkg .a-txt {
    color: #fff; }
  .blog.blog-modular .bkg .a-title {
    text-transform: uppercase;
    font-weight: 100; }
  .blog.blog-modular .bkg .btn-alt {
    border: 1px solid #fff;
    min-width: 11.6em; }

.blog.blog-modular .b-preview-cat {
  text-transform: uppercase; }

.blog.blog-modular .n-title {
  font-size: 22px;
  margin: 0;
  font-weight: 800; }

.blog.blog-modular .btn-red {
  margin-left: auto;
  display: block;
  padding: 0 5rem; }

.blog.blog-modular .sec-blog-categorys {
  padding-bottom: 2em; }
  .blog.blog-modular .sec-blog-categorys .category img {
    height: 236px;
    width: 100%; }
  .blog.blog-modular .sec-blog-categorys .category .ctnt-info {
    position: relative;
    min-height: 184px; }
  .blog.blog-modular .sec-blog-categorys .category .btn-shadow {
    left: 6em;
    min-width: 11.6em; }

.blog.blog-modular .sec-blog-news {
  padding: 1em; }
  .blog.blog-modular .sec-blog-news .bkg-img.new {
    background-color: #f3f4f7;
    height: 440px;
    margin-top: 3em;
    width: 100%; }
  .blog.blog-modular .sec-blog-news .wrapper-new {
    padding-bottom: 1.5em; }
    .blog.blog-modular .sec-blog-news .wrapper-new .n-suptitle {
      margin-top: 1.5em; }
  .blog.blog-modular .sec-blog-news h6 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500; }

.post.post-modular .post.post-info .new {
  background-color: #f3f4f7;
  height: 280px;
  margin-bottom: 3em;
  width: 100%; }

.post.post-modular .post.post-info .wrapper-new .n-suptitle {
  text-transform: uppercase; }

.post.post-modular .post.post-info h1 {
  color: #2f2f2f;
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
  margin-bottom: 0.75em; }

.post.post-modular .post.post-info p {
  color: #2f2f2f;
  margin-bottom: 1.25em; }

.post.post-modular .sec-post-categorys img {
  height: 236px;
  width: 100%; }

.post.post-modular .sec-post-categorys .btn-shadow {
  left: 5em; }

.pg-proximamente h1 {
  color: #000;
  font-size: 5rem;
  font-weight: 500;
  line-height: 5rem;
  margin: .2rem auto 0;
  padding: 0 .5rem;
  text-transform: uppercase;
  width: 100%; }

.pg-proximamente * {
  font-family: "Oswald"; }

.pg-proximamente .box-header {
  background: #000;
  text-align: center; }

.pg-proximamente .bg-footer {
  background: #000;
  text-align: center; }
  .pg-proximamente .bg-footer p {
    border-top: 1px solid #000;
    color: #fff;
    display: inline-flex;
    font-family: "Oswald";
    font-size: 1.4rem;
    font-weight: 400;
    justify-content: center;
    letter-spacing: .38px;
    line-height: 1.4rem;
    margin: .7rem auto 1rem;
    padding: 1rem 2rem; }

.pg-proximamente .wrapper-main {
  background: url(../images/bkg/hero-back-preview.jpg) no-repeat center no-repeat;
  background-size: cover; }

.pg-proximamente .content-slogan  {
  padding: 1.2rem 15px 0;
  text-align: center; }
  .pg-proximamente .content-slogan  h2 {
    border-bottom: 2px solid #000;
    border-top: 2px solid #000;
    color: #000;
    display: inline-flex;
    font-family: "Oswald";
    font-size: 4rem;
    font-weight: 100;
    letter-spacing: 1.12px;
    line-height: 4rem;
    margin: 7rem auto 3.8rem;
    padding: 1.7rem 5rem;
    text-transform: uppercase; }
    @media all and (max-width: 575px) {
      .pg-proximamente .content-slogan  h2 {
        padding-left: 10px;
        padding-right: 10px; } }

.pg-proximamente .wrapper-form {
  background: #fff;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.5);
  font-weight: 400;
  margin: 6.8rem auto 45px auto;
  padding: 1.5rem 2rem;
  width: 374px; }
  @media all and (max-width: 575px) {
    .pg-proximamente .wrapper-form {
      width: 100%; } }
  .pg-proximamente .wrapper-form .buttons {
    padding: 0 0 12px 0; }
  .pg-proximamente .wrapper-form .button {
    font-weight: 400;
    letter-spacing: 1.9px;
    padding-top: 8px; }
  .pg-proximamente .wrapper-form ul {
    margin-left: 3rem; }
  .pg-proximamente .wrapper-form h4 {
    border-bottom: 3px solid #000;
    color: #000;
    font-size: 2.3rem;
    font-weight: 400;
    letter-spacing: .08px;
    line-height: 3rem;
    margin-top: 0;
    padding-bottom: .5rem;
    text-transform: uppercase; }
  .pg-proximamente .wrapper-form li {
    color: #000;
    font-size: 1.5rem;
    font-weight: 500;
    left: 0;
    line-height: 2.4rem;
    padding-left: 0px; }
  .pg-proximamente .wrapper-form input:not([type="radio"]):not([type="checkbox"]),
  .pg-proximamente .wrapper-form select {
    border-bottom: 1px solid #4a4a4a;
    height: auto;
    padding: 5px 0 10px 0; }
    .pg-proximamente .wrapper-form input:not([type="radio"]):not([type="checkbox"]) + label,
    .pg-proximamente .wrapper-form select + label {
      color: #4a4a4a;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: .22px;
      line-height: 14px; }
  .pg-proximamente .wrapper-form textarea {
    border: 1px solid #4a4a4a;
    height: 6.75rem;
    padding: 5px 5px 10px; }
    .pg-proximamente .wrapper-form textarea + label {
      color: #4a4a4a;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: .22px;
      line-height: 14px; }
  .pg-proximamente .wrapper-form label {
    color: #4a4a4a;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .22px;
    line-height: 14px; }
  .pg-proximamente .wrapper-form .form-content {
    padding-top: 2.5rem; }

.pg-proximamente .box-header img {
  display: block;
  margin: auto; }

.pg-proximamente .msg-success {
  color: #000;
  display: none;
  font-size: 2.3rem;
  font-weight: 400;
  letter-spacing: .08px;
  line-height: 3rem; }
  .pg-proximamente .msg-success.sendemail {
    display: block; }

.pg-custom-design .clothescolor .group-field {
  display: flex;
  line-height: 0;
  min-height: 40px;
  position: relative;
  top: 0 !important; }

.faddcart .clothescolor .content-radiobutton .group-field {
  display: flex;
  line-height: 0;
  min-height: 40px; }
