.pg-store {

	.opc-tipopersona { display: none; }

	.content {
		margin-top: 70px;

		@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) { margin-top: 48px; }

		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { margin-top: 46px; }

	}

	.wraper-img {
		background: $bg-items-store; height: 380px; margin-bottom: 10px;

		img {
			height: 380px;
			object-fit: cover;
			width: 100%;
		}
	}

	.filter-product {
		@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) { display: none; }
	}

	.filter-product {
		border: solid 1px $gray-lightest; min-height: 48px;

		option { color: $gray2; }
	}

	.sec-page-banner.banner-store .imgfull img {

        @media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			height: auto;
		}

		// @media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		// 	height: 365px;
		// }
	}

}

.wrapper-filter-product { padding: 0 12px; }

.store-nav {
	display: flex; flex-wrap: wrap; margin: 0; padding: 0 0 0 28px;

	li {
		list-style: none; min-width: 110px;

		&::before,
		&::before { content: none; }
	}
}

.wrapper-store-nav {
	background: $black;

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		display: none;
	}

	a {
		@include font-custom-gral($white, 14px, 300, 0px, 24px); align-items: center; display: flex; min-height: 44px; text-align: center; text-decoration: none; text-transform: uppercase;

		&:hover,
		&.active { font-weight: bold; }
	}
}

.store-list {
	padding-bottom: 9.6rem;

	@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
		display: block;
	}

	@include media-breakpoint-between(md, lg) { grid-template-columns: 3fr 8.5fr 0.5fr; }

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
		flex-direction: column;
	}

}

.store-left {
	background: $white;
	padding: 2rem 0;
	.grid-filters{
		display: flex;
    	column-gap: 2rem;
		.wrapper-cat-product{
			display: flex;
			justify-content: flex-start;
			column-gap: 2rem;
			li{
				width: 208px;
			}
		}
		ul{
			margin: 0;
		}
	}

	h3 { @include font-custom-gral($gray1, 16px, bold, 0px, 16px); text-transform: uppercase;}

	li {
		list-style: none; overflow: hidden;

		&::after,
		&::before { display: none; }
	}

	.nav-item { margin-bottom: 1rem; text-align: center; }

	.nav-link {
		@include font-custom-gral($color-nav-parent, 13px, bold, 0, 16px); text-transform: uppercase;
	}

	.nav {
		display: none;
		@media all and (min-width: map-get($grid-breakpoints, 'md') - 1) { display: block; margin-right: 4rem; }
	}

	.arrow-i { -webkit-transition: -webkit-transform .1s ease-in-out; -ms-transition: -ms-transform .1s ease-in-out; transform: rotate(90deg); transition: transform .1s ease-in-out; background: url(#{$_image-path}/bkg/arrow-mnu.png) no-repeat center; background-size: auto 15px; background-position: -18px -3px; display: block; height: 12px; width: 12px; margin-right: 1rem;}

	.submenu { display: none; margin: 0.3rem 0 0 0 !important; overflow: hidden; transition: height 0.15s ease-out; padding: 0;
		border: 1px solid #ddd;
		.nav-item {
			text-align: left;
			.nav-link {
				font-size: 12px;
				text-transform: inherit;
			}
		}
	 }

	// mnenu
	.nav-link-parent {
		@include font-custom-gral($color-nav-parent, 12px, normal, 0, 16px);
		align-items: center; background: $white; border: 1px solid $black; display: flex; justify-content: space-between; min-height: 41px; text-decoration: none; transition: .2s ease-in-out;
	}

	.nav-item-parent {

		.stext {
			margin: auto 1rem;; transition: color 0.25s ease-out;
		}

			&.active {

				.stext { margin: 0 1rem; }

				.submenu { display: block; }

				.arrow-i {
					transform:rotate(0deg); -ms-transform:rotate(0deg); -webkit-transform:rotate(0deg);
					background-position: -5px center;
				}

				.nav-link-parent { font-weight: 400; position: relative; z-index: 5; }
			}
	}
}

.wrapper-items {
	display: grid; padding-top: 2.8rem;

	@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
		grid-gap: 4.6rem 2rem; grid-template-columns: repeat(4, 1fr); padding-top: 9.8rem;
	}

	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) {
		grid-gap: 4.6rem 2rem; grid-template-columns: repeat(3, 2fr);
	}

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		grid-gap: 16px 0; grid-template-columns: repeat(2, 50%);
	}

	.product-desc{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.add-tocart-btn{
		position: absolute;
		text-indent: -9999px;
		right: 0;
		background: $red url(#{$_image-path}/bkg/icon-cart-square.svg) center no-repeat;
		white-space: nowrap; 
		text-indent: -9999px;
		width: 53px;
		height: 53px;
	}

	.item {
		position: relative;

		&.guitarras, &.pedales, &.accesorios, &.cuerdas  {
			display: none;
		}

		h3 { @include font-custom-gral($red, 14px, normal, 2px, auto); margin: 0; text-transform: uppercase; }

		.product-description{
			p{
				margin: 0;
				font-weight: 100;
				font-size: 16px
			}
		}

		.mindescription {
			@include font-custom-gral($gray1, 14px, 300, 2px, 24px); padding: 0 9px;

			* {
				@include font-custom-gral($gray1, 14px, 300, 2px, 24px);
			}
		}

		p {
			@include font-custom-gral($gray1, 16px, 700, 0px, auto); margin: 1.5rem 0 2.4rem 9px;

			&.rate { margin: 0; padding-top: 0; }

		}
		// animation hover

		&:hover {
			&::before,
			&::after {
				 width: 100%;
			}

			.link-item {
				&::before,
				&::after {
				 height: 100%;
			 }
			}
	 }
	 //test

	 &::before { left: 50%; top: 0; transition-duration: 0.4s; }

	 &::after {
		 bottom: 0; left: 50%; transition-duration: 0.4s;
	 }

		.link-item::before {
			left: 0; top: 50%; transition-duration: 0.4s;
		}

	 .link-item::after {
		 right: 0; top: 50%; transition-duration: 0.4s;
	 }

	 &:hover::before,
	 &:hover::after{
		 left: 0;
	 }

	 &:hover .link-item::before,
	 &:hover .link-item::after{
		 top: 0;
	 }
	 //test

	} // end item

	.link-item {
		height: 100%; display: block;

		&:hover { text-decoration: none; }
	}
}


.wrapper-store-nav-mb {
	align-items: center; border: 1px solid $gray-lightest; display: flex; justify-content: flex-start; min-height: 48px; padding-left: 10px;

	@media all and (min-width: map-get($grid-breakpoints, 'md') + 1) {
		display: none;
	}

	.store-nav-mb {
		@include font-custom-gral($gray1, 16px, 400, 2px, 24px); border: 0; height: auto;  outline: none;

		option {
			@include font-custom-gral($gray1, 16px, 400, 2px, 24px);
		}
	}
}

h3.style-nav-select-mb {
	@include font-custom-gral($gray2, 16px, 400, 2px, 24px); margin: 0; padding: 0; text-transform: uppercase;
}


.store-cat-style-bebes {
	.submenu {
		li:first-of-type {
			display: none;
		}
	}
}

// banner  para tienda
.sec-page-banner {
	&.banner-store {
        top: 0;
		// min-height: 36.5rem;
        position: static;
        top: 0;
        min-height: auto;

        // @media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
        //     min-height: auto;
        // }

        .content-banner {
              padding-top: 0;
        }

		.imgfull {
            position: relative;
            img {
                height: auto;
                opacity: 1;
    		// height: 36.5rem;
    		}
        }

		.row {
			// min-height: 36.5rem;
            min-height: auto;
            height: auto;

            // @media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
            //     min-height: auto;
            // }
		}

		.content-banner {
			justify-content: center;
			margin-bottom: 0;

			h1 {
	    font-size: 40px;
	    line-height: 40px;
			}

			h2 {
	    font-size: 25px;
	    line-height: 25px;
			}
		}
		//
	}
}

.video-tienda {
	width: 100%;
	height: 340px;
	text-align: center;
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}


#store-dk-video {
	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		display: none;
	}

}

#store-mb-video {
	display: none;

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		display: block;
	}

	.embed-responsive-item {
		width: 100%;
	}
}
