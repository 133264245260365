.post.post-modular {

	.post.post-info {

		.new {
			background-color: $white4;
			height: 280px;
			margin-bottom: 3em;
			width: 100%;

			// &.bkg-info {}
			// &.bkg-info-0 {}
		}

		.wrapper-new {
			.n-suptitle {
				text-transform: uppercase;
			}
		}

		h1 {
			color: $gray6;
			font-size: 22px;
			font-weight: bold;
			line-height: 27px;
			margin-bottom: 0.75em;
		}

		p {
			color: $gray6;
			margin-bottom: 1.25em;
		}
	}

	.sec-post-categorys {
		img {
			height: 236px;
			width: 100%;
		}

		.btn-shadow  {
			left: 5em;
		}
	}

}

