.page-products {

	.ctnt-info {
		grid-template-rows: repeat(2, 0fr);

		.p-title {
			font-size: 14px;
			margin: 0;
		}

		.p-price {
			font-size: 16px;
			margin: 0;
		}

		.p-desc {
			font-size: 16px;
		}

		.p-oldprice {
			font-size: 14px;
		}
	}

	.ctnt-link {
		padding: 2.7em 0 1.8em;

		.link-ref {
			margin-right: 5em;
		}
	}

	.action-btn {
		padding: 2.7em 0 1.8em;
		text-align: center;

		.btn-spirit {
			min-width: 12em;
		}
	}

}

.item-simple{
	h1{
		text-align: center;
		font-weight: 100;
		font-size: 30px;
	}
	.button.thered {
		padding: 0 3rem;
	}
}

.nav-item-parent.opc-color{
	margin-top: -4.5rem;
	a{
		border: 0;
		color: #252233;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0;
		line-height: 16px;
		text-transform: uppercase;
		.arrow-i{
			display: none;
		}
	}
	.submenu{
		display: flex;
		border: 0;
		column-gap: 1rem;
		li{
			margin-bottom: 0 !important;
			width: auto;
			.nav-link{
				text-indent: -9999px;
				width: 23px;
				height: 23px;
				border: 1px solid $black;
				white-space: nowrap;  
				&.negro{
					background-color: $black;
				}
				&.rojo{
					background-color: #B22A2C;
				}
				&.azul{
					background-color: #007DFF;
				}
				&.cafe{
					background-color: #6B2A0B;
				}
				&.amarillo{
					background-color: #FFCA00;
				}
			}
		}
	}
}

