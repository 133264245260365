.pg-privacity {

	main {
		padding: 6em 10em 8em;
	}

	h1 {
		font-size: 22px;
		line-height: 27px;
		margin-bottom: 1.5em;
	}

	p {
		line-height: 19px;
	}

}
