/*! by SalsaLab */
// Helpers
@import 'bourbon';// transform: translate(-50%, -50%);
// Framework
@import 'functions';
@import 'base/vars';
@import 'variables';
@import 'theme-vars';

@import 'mixins/clearfix';
@import 'mixins/breakpoints';
@import 'mixins/grid-framework';
@import 'mixins/grid';
@import 'grid';
@import 'utilities/display';
@import 'utilities/flex';
@import 'base/base';

// Layout
@import 'base/layout/header';
@import 'base/layout/menu';
@import 'base/layout/footer';
@import 'base/patterns/swiper';
// @import 'base/patterns/navdrop';
@import 'base/patterns/lightbox';

// Theme Specific
@import 'layout/mixin-opt';
@import 'layout/fonts';
@import 'layout/animate';

@import 'theme-basic';
@import 'layout/header-layout';
@import 'layout/footer-layout';

// COMPONENTS
// **************************************************

// SECTIONS
// **************************************************
@import 'layout/sections';

// ** home **
@import 'layout/home';
@import 'layout/pg-contact';
@import 'layout/pg-we';
@import 'layout/pg-store';
@import 'layout/pg-product';
@import 'layout/pg-design-custom';
@import 'layout/pg-design-send';
@import 'layout/pg-cart';

// Page Products
@import 'layout/products';

// Pages Legals
@import 'layout/privacity';

// Page Blog
@import 'layout/blog';

// Page Blog Post
@import 'layout/blogpost';

// pg-proximamente
@import 'layout/proximamente';

//
.pg-custom-design .clothescolor .group-field {
	display: flex;
	line-height: 0;
	min-height: 40px;
	position: relative;
	top: 0 !important;
}

.faddcart .clothescolor .content-radiobutton .group-field {
	display: flex;
	line-height: 0;
	min-height: 40px;
}
