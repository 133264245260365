.header {
	background: $black;
	height: auto;
	position: fixed;

	.container-fluid {
		position: relative;

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			padding: 0;
		}
	}

	&.oncartheader {
		.container-fluid {
			&::after {
				content: none;
			}
		}
	}

	.site-logo {
		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			order: 2;
		}
	}
}

.top-bar {
	background: $gray6;
	.container-fluid {
		justify-content: flex-end !important;
		a {
			font-size: 14px;
			color: $white;
		}
	}
}

.main-menu {
	margin-left: auto;
	order: 1;
	height: 70px;

	@media all and (min-width: map-get($grid-breakpoints, "md") + 1) {
		margin-right: 3rem;
	}

	@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
		margin: 0;
	}

	.mnu {
		@media all and (min-width: map-get($grid-breakpoints, "md") + 1) {
			margin-right: 30px;
		}

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			margin-bottom: 1.9rem;
		}
	}

	a {
		@include font-custom-gral($white, 16px, 300, 2.65px, 24px);
		text-align: center;

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			padding-left: 15px;
			text-align: left;
		}

		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover,
		&.active {
			border-bottom: 4px solid $red;

			@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
				color: $black;
			}
		}
	}

	.menu-content {
		display: flex;
		justify-content: space-around;
		height: 70px;

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background: $blue-1;
			flex-direction: column;
			padding-top: 2rem;
			top: 37px;
		}
	}
}

.header {
	.cart-top {
		.icon-cart {
			background-size: 35px auto;
			display: block;
			height: 35px;
			width: 35px;
			border-radius: 35px;
		}
		&:active,
		&:focus,
		&:hover {

			.icon-cart {
				background-size: 35px auto;
			}
		}
	}
}

.cart-top {
	@include button-black-white(transparent, $white, $black);
	align-items: center;
	display: flex;
	height: 7rem;
	justify-content: space-around;
	order: 3;
	position: relative;
	width: auto;

	.total-cart {
		background: $white;
		border-radius: 50%;
		color: $black;
		height: 20px;
		line-height: 20px;
		position: absolute;
		right: 10px;
		text-align: center;
		top: 15px;
		width: 20px;

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			right: 5px;
			top: 5px;
		}
	}

	&:active,
	&:focus,
	&:hover {
		border: 0;
		text-decoration: none;

		.icon-cart {
			background: url(#{$_image-path}/bkg/icon-cart-square.svg) no-repeat center;
			background-size: 53px auto;
		}

		.txt {
			color: $black;
		}
	}

	@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
		height: 4.8rem;
		width: 4.8rem;
	}

	div {
		align-items: center;
		display: flex;
	}

	.txt {
		@include font-custom-gral($white, 15px, 300, 2.65px, 24px);
		text-transform: uppercase;

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			display: none;
		}
	}

	.icon-cart {
		background: url(#{$_image-path}/bkg/icon-cart-square.svg) no-repeat center;
		background-size: 53px auto;
		display: block;
		height: 53px;
		width: 53px;

		&:active,
		&:focus,
		&:hover {
			border: 0;
			text-decoration: none;

			.icon-cart {
				background: url(#{$_image-path}/bkg/icon-cart-square.svg) no-repeat center;
				background-size: 53px auto;
			}

			.txt {
				color: $black;
			}
		}

		@media all and (max-width: map-get($grid-breakpoints, "lg") - 1) {
			background-position: right center;
		}
	}

	.box {
		-webkit-transition: 0.5s;
		border: 3px solid $black;
		color: $black;
		cursor: pointer;
		height: 100%;
		justify-content: space-around;
		overflow: hidden;
		position: relative;
		text-align: center;
		transition: 0.5s;
		vertical-align: middle;
		width: 100%;

		&:hover {
			border: 3px solid rgba(0, 160, 80, 0);
			color: $black;

			&::after,
			&::before {
				-webkit-transform: scale(1.1);
			}
		}

		&::before,
		&::after {
			-webkit-transform: scale(0);
			box-sizing: border-box;
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: 0.5s;
			width: 100%;
			z-index: 3;
		}
	}

	.foo::before {
		-webkit-transform-origin: 0 100%;
		border-bottom: 3px solid $black;
		border-left: 3px solid $black;
	}

	.foo::after {
		-webkit-transform-origin: 100% 0%;
		border-right: 3px solid $black;
		border-top: 3px solid $black;
	}
}

.mnu-tienda,
.mnu-home {
	min-width: 84px;
}

.mnu-contacto {
	min-width: 122px;
}

.mnu-nosotros {
	min-width: 137px;
}

.site-logo-link {
	height: 55px;
	width: 189px;
}

.menu-mobile {
	color: $black;

	&::before {
		left: 12px;
		font-size: 26px;
		padding: 0;
		position: relative;
	}
}

.sticky {
	background: $black;
	position: fixed;
	z-index: 10;
}

.mnu {
	&.mnu-product:hover {
		.children {
			opacity: 1;
			pointer-events: all;
			&:hover {
				opacity: 1;
			}
		}
	}
	.children {
		display: grid;
		grid-template-columns: 1fr 2.5fr;
		background: #fff;
		padding: 2rem;
		width: 992px;
		min-height: 310px;
		column-gap: 2rem;
		pointer-events: none;
		opacity: 0;
		position: absolute;
		right: 0;
		-webkit-transition: opacity 0.15s linear;
		transition: opacity 0.15s linear;

		.prior-product {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			background-size: cover;
			position: relative;
			&:before {
				content: "";
				width: 40px;
				height: 40px;
				background: url(#{$_image-path}/bkg/badget.svg) no-repeat left;
				position: absolute;
				top: 5px;
				right: 5px;
			}
			.prior-data {
				background: rgba(255, 255, 255, 0.5);
				p {
					margin: 0;
					&.p-title {
						color: $red;
						font-size: 16px;
					}
					&.p-description {
						color: #3c4352;
						font-size: 20px;
						line-height: 1;
					}
					&.p-price {
						color: #252233;
						font-size: 22px;
					}
				}
			}
		}
		.mnu-childrens {
			display: grid;
			grid-template-columns: 1fr 1fr;
			.sub-mnu {
				a {
					color: #3c4352;
					font-size: 14px;
					text-transform: uppercase;
					border-bottom: 0;
					text-align: left;
					display: flex;
					justify-content: flex-start;
					height: auto;
					margin-bottom: 1rem;
					&:before {
						content: "";
						width: 20px;
						height: 11px;
						background: url(#{$_image-path}/bkg/mnu-arrow.svg) no-repeat left;
					}
				}
			}
		}
	}
}

.wrapper-icon {
	// background-color: $gray1;
	height: 7rem;
	position: relative;
	order: 2;
	width: 3em;
	display: flex;

	.i-search {
		bottom: 0;
		left: 0;
		margin: auto;
		position: absolute;
		right: 0;
		top: 0;

		div {
			fill: $black;

			&:hover {
				fill: $gray4;
			}
		}
	}
}

.modal-search {
	margin: 0;
	max-width: 1100%;

	.wrapper-form{
		display: flex;
		background: url(#{$_image-path}/bkg/marco.svg) center top no-repeat;
		background-size: 100%;
		padding: 13rem 0;
	}

	.modal-content {
		background: rgb(0, 0, 0);
		background: linear-gradient(0deg, #6d6d6d 10%, #eaebf0 50%, #eaebf0 80%, #6d6d6d 100%);

		.modal-header {
			display: none;
		}

		.modal-body {
			align-items: center;
			// cursor: pointer;
			display: flex;
			justify-content: center;
			height: 99vh;
			overflow: hidden;
			width: 98vw;

			.icon-x {
				color: $red;
				cursor: pointer;
				margin: 0 1.5em;
				position: relative;
				right: 0;
				top: 0;
				z-index: 12;
				display: flex;
				align-items: center;
			}

			.btn-spirit {
				min-width: 11.5em;
			}
		}
	}

	.bx-x:before {
		font-size: 64px;
	}

	.search-box {
		display: flex;
		align-items: center;
		position: relative;
		border-radius: 50px;
		margin-right: 3em;

		

		input[type="text"] {
			border: none;
			box-sizing: border-box;
			border-radius: 50px;
			font-family: $font-family-sans-serif;
			font-size: 20px;
			height: 50px;
			min-width: 31em;
			padding: 5px 40px 5px 10px;
			transition: width $search-time cubic-bezier(0.68, -0.55, 0.27, 1.55) $reset-time;
			text-align: center;

			&::placeholder {
				color: #4e4e4e !important;
				opacity: 100 !important;
				font-size: 30px;
			}

			&:focus {
				outline: none;
			}
		}
	}
}
