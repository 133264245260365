
.blog.blog-modular {
	.bkg {
		height: 435px;
		background-color: #3C4352;
		margin-top: 90px;

		.container-fluid, .bkg-gray{
			height: 100%;
		}

		.a-title,
		.a-txt {
			color: $white;
		}
		.a-title {
			text-transform: uppercase;
			font-weight: 100;
		}

		.btn-alt {
			border: 1px solid $white;
			min-width: 11.6em;
		}
	}

	.b-preview-cat{
		text-transform: uppercase;
	}

	.n-title{
		font-size: 22px;
		margin: 0;
		font-weight: 800;
	}

	.btn-red {
		margin-left: auto;
		display: block;
		padding: 0 5rem;
	}

	.sec-blog-categorys {
		padding-bottom: 2em;

		.category {
			img {
				height: 236px;
				width: 100%;
			}

			.ctnt-info {
				position: relative;
				min-height: 184px;
			}

			.btn-shadow {
				left: 6em;
				min-width: 11.6em;
			}
		}
	}

	.sec-blog-news {
		padding: 1em;

		.bkg-img.new {
			background-color: $white4;
			height: 440px;
			margin-top: 3em;
			width: 100%;
		}

		.wrapper-new {
			padding-bottom: 1.5em;

			.n-suptitle {	margin-top: 1.5em; }
		}

		h6 {
			font-size: 16px;
			line-height: 19px;
			font-weight: 500;
		}

	}
}

