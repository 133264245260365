.footer {
	background: $black;
	min-height: 16.1rem;
	padding-top: 4rem;

	@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
		text-align: center;
	}

	.row {
		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			flex-direction: column;
		}
	}

	.icosocial {
		display: inline-flex;
		font-size: 0;
		width: 24px;
		height: 24px;

		&:hover {
			@include hear-beat();
		}
	}

	.socials-media{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.ifacebook {
		background: url(#{$_image-path}/bkg/Facebook.svg) no-repeat center;
		background-size: 10px auto;
	}

	.itwitter {
		background: url(#{$_image-path}/bkg/Twitter.svg) no-repeat center;
		background-size: 24px auto;
	}

	.itinstagram {
		background: url(#{$_image-path}/bkg/Instagram.svg) no-repeat center;
		background-size: 22px auto;
	}

	.ityoutube {
		background: url(#{$_image-path}/bkg/Youtube.svg) no-repeat center;
		background-size: 24px auto;
	}

	.itvimeo {
		background: url(#{$_image-path}/bkg/Vimeo.svg) no-repeat center;
		background-size: 24px auto;
	}

	a,
	p,
	h1 {
		color: $white;
	}

	a,
	p {
		font-size: 14px;
		text-decoration: none;
	}

	h1 {
		font-size: 16px;
		text-transform: uppercase;
	}

	ul {
		padding-left: 0;
		ul {
			margin-left: 2rem;
		}
		&.footer-nav {
			li {
				padding: 1rem 0;
				text-decoration: none;
			}
		}
		&.nav-categories {
			padding-left: 3rem;
			li.parent::before {
				content: "";
				background: url(#{$_image-path}/bkg/small-red-arrow.svg) no-repeat center;
				width: 10px;
				height: 9px;
				margin-top: 7px;
			}
		}
		li {
			list-style: none;
		}
		li::before {
			content: "";
		}
	}
}

.footer-last-fblend{
	display: flex;
	column-gap: 4rem;
	align-items: center;
}
.mosquito{
	background: url(#{$_image-path}/bkg/mosquito.png) no-repeat center;
	width: 38.52px;
	height: 30px;
	cursor: pointer;
	&:hover{
		background: url(#{$_image-path}/bkg/mosquito.png) no-repeat center;
	}
}

.footer {
	.form-group {
		border: 1px solid #4e4e4e;
		padding: 1rem;
		.form-control {
			border: 0 !important;
			padding: 0 0 1rem !important;
			height: auto !important;
			color: #4e4e4e !important;
			font-size: 16px;
			&::placeholder {
				color: #4e4e4e !important;
				opacity: 100 !important;
				font-size: 16px;
			}
		}
	}
	.button {
		background: 0 0;
		border: 1px solid #c51625;
		text-decoration: none;
		text-transform: capitalize;
		font-size: 16px;
		font-family: Montserrat,sans-serif;
		font-weight: 100;
		padding: 1rem 3rem;
		margin-top: 2rem !important;
		margin-bottom: 8rem !important;
	}
}

.footer-bottom {
	.container-fluid {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 2rem 0;
		p {
			margin: 0;
		}
	}
	margin-top: 4rem;
	background: $gray2;
}

.footer-logo {
	// margin-left: auto;
	order: 3;

	@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
		margin-right: auto;
	}
}

.footer-mnu  {
	margin-top: 0.8rem;
	order: 2;

	a {
		@include font-custom-gral($white, 12px, 300, 0, 18px);
	}
}

.accordion-button {
	width: 80%;
	border: 1px solid $white;
	background: transparent;
	color: $white;
	font-size: 14px;
	text-transform: capitalize;
	padding: 2rem 1.25rem;

	&:hover,
	&:focus {
		background: transparent;
	}
}
.accordion-body {
	width: 80%;
	color: $white;
	font-size: 14px;
}

.accordion-button::after {
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
}

.accordion-button:not(.collapsed) {
	color: $white;
}
.accordion-button:focus {
	border-color: $white;
}

.copyright {
	@include font-custom-gral($white, 12px, 300, 0, 18px);
	margin-top: 3.4rem;

	&.last {
		display: none;

		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			display: inline-flex;
		}
	}

	&.first {
		@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
			display: none;
		}
	}
}

.link-terms {
	margin-right: 13px;
}

.footer-logo-link {
	height: 40px;
	margin-right: auto;
	width: 80%;
	margin-top: 4rem;

	@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
		margin-right: auto;
	}
}

.accordion-item {
	background-color: transparent;
	border: none;
}

.btn-whatsapp {
	outline: none !important;
	background: url(#{$_image-path}/bkg/whats.svg) no-repeat;
	width: 60px;
	height: 60px;
	min-width: 60px;
	min-height: 60px;
	position: fixed;
	bottom: 30px;
	right: 40px;
	z-index: 999;
}

#dk-whatsapp {
	@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
		display: none;
	}
}

#mb-whatsapp {
	display: none;

	@media all and (max-width: map-get($grid-breakpoints, "md") - 1) {
		display: block !important;
	}
}
